import {
  createStore,
  createEvent,
  createEffect,
  sample,
  combine,
} from 'effector';

const onSubmit = createEvent();
const setField = createEvent();

const handleChange = setField.prepend(e => ({
  key: e.target.name,
  value: e.target.value,
}));

const $state = createStore({ order: '' }).on(setField, (s, { key, value }) => ({
  ...s,
  [key]: value,
}));

sample({
  clock: onSubmit,
  source: $state,
  target: createEffect().use(({ order }) => {
    window.location.href = `${window.location.origin}/track/${order}`;
  }),
});

export const store = combine({ $state });

export const actions = {
  handleChange,
  onSubmit,
};
