import { createEvent, createStore, combine } from 'effector';

import concat from 'lodash/concat';
import mapValues from 'lodash/mapValues';
import groupBy from 'lodash/groupBy';
import filter from 'lodash/filter';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';

const initCheckedFiltersArray = createEvent();
const clearFilters = createEvent();
const addFilter = createEvent();
const removeFilter = createEvent();
const setFromQueryParam = createEvent();

const $filtersToRender = createStore([]);
const $productsCategory = createStore('tires');
const $sortedFiltersToRender = combine(
  {
    productsCategory: $productsCategory,
    filtersToRender: $filtersToRender,
  },
  ({ productsCategory, filtersToRender }) => {
    if (productsCategory === 'wheels') return filtersToRender;

    const order = [
      'Brand',
      'RoadCondition',
      'TreadType',
      'SpeedRating',
      'LoadIndex',
      'Sidewall',
      'Ply',
      'MileageWarranty',
      'Trailer',
      'RoadHazard',
      'RoadsideAssistance',
      'FuelEfficient',
      'WinterCertified',
      'RunFlat',
    ];
    const sortedItems = sortBy(filtersToRender, f => order.indexOf(f.label));
    return sortedItems;
  },
);

const $checkedFiltersArray = createStore([])
  .reset(clearFilters)
  .on(addFilter, (filters, filterToAdd) => concat(filters, filterToAdd))
  .on(removeFilter, (filters, filterToRemove) =>
    filter(filters, f => !isEqual(f, filterToRemove)),
  )
  .on(setFromQueryParam, (_, v) => v);

const $checkedFilters = $checkedFiltersArray.map(checkedFiltersArray =>
  mapValues(
    groupBy(checkedFiltersArray, filterGroup => filterGroup.label),
    filters =>
      orderBy(
        filters.map(f => f.value),
        [],
        ['asc'],
      ),
  ),
);

const $emptyCheckedFilters = $checkedFilters.map(isEmpty);

export const stores = {
  $filtersToRender,
  $checkedFilters,
  $checkedFiltersArray,
  $emptyCheckedFilters,
  $sortedFiltersToRender,
  $productsCategory,
};

export const store = combine(stores);

export const actions = {
  initCheckedFiltersArray,
  addFilter,
  removeFilter,
  clearFilters,
  setFromQueryParam,
};
