import { combine, sample, createEvent, forward } from 'effector';
import { useStore } from 'effector-react';
import isEmpty from 'lodash/isEmpty';
import difference from 'lodash/difference';
import {
  store as $drawerStore,
  actions as drawerActions,
} from 'src/effector/drawer';

import {
  actions as orderActions,
  stores as orderStores,
} from 'src/effector/order';
import {
  actions as addonsActions,
  stores as addonsStores,
} from 'src/effector/cart/addons';
import {
  actions as promoCodeActions,
  stores as promoCodeStores,
  createPromoCodeFx,
} from 'src/effector/promoCode';

const $isLoading = combine(
  [
    orderStores.$isLoadingOrder,
    addonsStores.$isLoadingAddons,
    promoCodeStores.$isLoadingPromoCode,
  ],
  loadings => loadings.some(Boolean),
);

const $allowedAddons = combine(
  addonsStores.$addons,
  orderStores.$lineItems,
  (addons, lineItems) => {
    const addonsInCart = lineItems
      .filter(({ product_type }) =>
        ['inflater', 'tire_bags'].includes(product_type),
      )
      .map(({ product_type }) => product_type);

    const bothTiresAndWheelsExist = isEmpty(
      difference(
        ['tire', 'wheel'],
        lineItems.map(({ product_type }) => product_type),
      ),
    );
    if (!isEmpty(addonsInCart) || bothTiresAndWheelsExist) {
      return addons.filter(({ type }) => !addonsInCart.includes(type));
    }
    return addons;
  },
);

const $isEmptyAllowedAddons = $allowedAddons.map(state => isEmpty(state));

const $isOpenedCartDrawer = $drawerStore.map(
  ({ type, isOpened }) => type === 'shopping-cart' && isOpened,
);

const openCartDrawer = createEvent();
const closeCartDrawer = createEvent();

forward({
  from: openCartDrawer,
  to: drawerActions.open.prepend(() => ({
    type: 'shopping-cart',
    origin: 'right',
  })),
});

forward({
  from: closeCartDrawer,
  to: drawerActions.close.prepend(() => ({})),
});

sample({
  clock: createPromoCodeFx.doneData,
  fn: order => order,
  target: orderStores.$order,
});

export const stores = {
  ...orderStores,
  ...addonsStores,
  ...promoCodeStores,
  $isLoading,
  $allowedAddons,
  $isEmptyAllowedAddons,
  $isOpenedCartDrawer,
};

export const store = combine(stores);

export const actions = {
  ...orderActions,
  ...addonsActions,
  ...promoCodeActions,
  openCartDrawer,
  closeCartDrawer,
};

export const cartHook = () => {
  const orderState = useStore(orderStores.$order);
  const isEmptyOrderState = useStore(orderStores.$isEmptyOrder);
  return { orderState, isEmptyOrderState };
};
