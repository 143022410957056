import { createStore, createEvent, sample } from 'effector';

const startNewProductSearch = createEvent();
const changeVehicle = createEvent();
const changeSize = createEvent();
const openWidget = createEvent();
const closeWidget = createEvent();

const start = createEvent();
const showFilters = createEvent();

const $widgetState = createStore('filters')
  .on(start, () => 'data')
  .on([closeWidget, showFilters], () => 'filters');

const $isOpenedWidget = createStore(false)
  .on(openWidget, () => true)
  .on(closeWidget, () => false);

sample({
  clock: [startNewProductSearch, changeVehicle, changeSize],
  target: openWidget,
});

export const actions = {
  start,
  showFilters,
  openWidget,
  startNewProductSearch,
  changeSize,
  changeVehicle,
  closeWidget,
};

export const stores = {
  $widgetState,
  $isOpenedWidget,
};
