import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import {
  createStore,
  createEvent,
  createEffect,
  combine,
  sample,
  split,
  forward,
} from 'effector';
import {
  stores as addressStores,
  actions as addressActions,
} from 'src/effector/checkout/address';
import {
  stores as deliveryStores,
  actions as deliveryActions,
} from 'src/effector/checkout/delivery';
import {
  stores as orderStores,
  actions as orderActions,
} from 'src/effector/order';
import { stores as addonsStores } from 'src/effector/addons';
import { actions as paymentActions } from 'src/effector/checkout/payment';
import { actions as protectionPlansActions } from 'src/effector/checkout/protectionPlans';
import { actions as lugNutsActions } from 'src/effector/checkout/lugNuts';
import { actions as addressVerificationActions } from 'src/effector/checkout/addressVerification';
import * as api from 'src/logic/api';
import { persist } from 'effector-storage/local';

const {
  $shipAddressFormValues,
  $billAddressFormValues,
  $addressParamsFormValues,
  $isValidAddressForm,
  $addressDataToSend,
  $isAddressAPoBox,
} = addressStores;

const {
  $fedexForm,
  preferredLocationForm,
  $deliveryMethod,
  $isFedexPicked,
  $isPreferredLocationPicked,
  $isMobileInstallerPicked,
  $isPhysicalInstallerPicked,
  $isValidDeliveryForm,
  $deliveryDataToSend,
  $isOpenedInstallersOverlay,
  $preferredLocationInstaller,
} = deliveryStores;

export const actions = {
  updatedCheckout: createEvent(),
  validateAddressForms: createEvent(),
  validateDeliveryForms: createEvent(),
  afterAddressValidation: createEvent(),
  afterDeliveryValidation: createEvent(),
  completeAddress: createEvent(),
  changeStep: createEvent(),
  presetAddress: createEvent(),
  scroll: createEvent(),
  next: createEvent(),
  back: createEvent(),
  clearErrorMessage: createEvent(),
  setErrorMessage: createEvent(),
  completeCheckoutInit: createEvent(),
  completeAccessoriesStep: createEvent(),
  confirmSummary: createEvent(),
};

const completeAccessoriesStepFx = createEffect().use(api.updateCheckoutState);

const $isInstallerServicingCustomersZip = combine(
  {
    installerLocation: $preferredLocationInstaller,
    shipAddressFormValues: $shipAddressFormValues,
  },
  ({ installerLocation, shipAddressFormValues }) =>
    installerLocation.servicing_zip_codes?.includes(
      shipAddressFormValues.zipcode,
    ),
);

sample({
  clock: actions.completeAccessoriesStep,
  source: orderStores.$order,
  fn: order => ({
    order,
    state: 'accessories',
  }),
  target: completeAccessoriesStepFx,
});

const updateAddressFx = createEffect().use(api.updateAddress);

sample({
  clock: [
    deliveryActions.updateFedexFx.doneData,
    deliveryActions.updatePreferredLocationFx.doneData,
    addressActions.completeAddressFx.doneData,
    protectionPlansActions.submitProtectionPlansFx.doneData,
    lugNutsActions.updateWheelsVehicleInfoFx.doneData,
    completeAccessoriesStepFx.doneData,
  ],
  target: actions.updatedCheckout,
});

sample({
  clock: deliveryActions.updateFedex,
  source: $deliveryDataToSend,
  target: deliveryActions.updateFedexFx,
});

sample({
  clock: deliveryActions.updatePreferredLocation,
  source: $deliveryDataToSend,
  target: deliveryActions.updatePreferredLocationFx,
});

sample({
  clock: actions.presetAddress,
  fn: ({ ship_address, bill_address, email }) => ({
    ship_address,
    bill_address,
    email,
  }),
  target: [
    addressActions.shipAddressFormActions.setForm.prepend(
      ({ ship_address, email }) => ({
        ...ship_address,
        email,
      }),
    ),
    addressActions.billAddressFormActions.setForm.prepend(
      ({ bill_address }) => bill_address,
    ),
  ],
});

sample({
  clock: actions.validateAddressForms,
  source: {
    addressParamsForm: $addressParamsFormValues,
    addressForm: $shipAddressFormValues,
  },
  filter: ({ addressParamsForm: { billing_address_is_same } }) =>
    billing_address_is_same,
  fn: ({ addressForm }) => addressForm,
  target: addressActions.billAddressFormActions.setForm,
});

sample({
  clock: actions.validateAddressForms,
  target: [
    addressActions.shipAddressFormActions.validate,
    addressActions.billAddressFormActions.validate,
    actions.afterAddressValidation,
  ],
});

sample({
  clock: actions.validateDeliveryForms,
  target: [$fedexForm.validate, preferredLocationForm.validate],
});

sample({
  clock: [preferredLocationForm.formValidated, $fedexForm.formValidated],
  target: actions.afterDeliveryValidation,
});

const $currentStep = createStore('delivery');

forward({
  from: actions.changeStep,
  to: $currentStep,
});

sample({
  clock: orderActions.destroyLineItemFx.done,
  filter: ({ params: { type } }) =>
    !['tpms', 'inflater', 'tire_bags'].includes(type),
  target: actions.changeStep.prepend(() => 'delivery'),
});

split({
  clock: actions.next,
  source: $currentStep,
  match: $currentStep,
  cases: {
    delivery: actions.validateDeliveryForms,
    address: actions.validateAddressForms,
    lugnuts: lugNutsActions.updateWheelsVehicleInfo,
    addons: protectionPlansActions.submitProtectionPlans,
    accessories: actions.completeAccessoriesStep,
  },
});

split({
  clock: actions.afterDeliveryValidation,
  source: [$deliveryMethod, $isValidDeliveryForm, $isOpenedInstallersOverlay],
  match: ([deliveryMethod, isValidDeliveryForm, isOpenedInstallersOverlay]) => {
    if (deliveryMethod === 'preferredLocation' && !isValidDeliveryForm) {
      deliveryActions.openInstallersOverlay();
      return 'stayDelivery';
    }

    return isValidDeliveryForm && !isOpenedInstallersOverlay
      ? deliveryMethod
      : 'stayDelivery';
  },
  cases: {
    fedex: deliveryActions.updateFedex,
    preferredLocation: deliveryActions.updatePreferredLocation,
  },
});

const $shouldVerifyAddress = combine([$isFedexPicked, $isValidAddressForm], s =>
  s.every(Boolean),
);

const $shouldCheckDistance = combine(
  [
    $isPreferredLocationPicked,
    $isPhysicalInstallerPicked,
    $isValidDeliveryForm,
    $isValidAddressForm,
  ],
  s => s.every(Boolean),
);

const $shouldCheckIfZipServicedByInstaller = combine(
  [
    $isPreferredLocationPicked,
    $isMobileInstallerPicked,
    $isValidDeliveryForm,
    $isValidAddressForm,
  ],
  s => s.every(Boolean),
);

$shouldCheckIfZipServicedByInstaller.watch(console.log);
split({
  clock: actions.afterAddressValidation,
  source: $currentStep,
  match: {
    verifyAddress: $shouldVerifyAddress,
    checkDistance: $shouldCheckDistance,
    checkZipServicedByInstaller: $shouldCheckIfZipServicedByInstaller,
  },
  cases: {
    verifyAddress: addressVerificationActions.verifyAddress,
    checkDistance: addressActions.checkDistance,
    checkZipServicedByInstaller: addressActions.checkZipServicedByInstaller,
  },
});

sample({
  clock: addressVerificationActions.verifyAddress,
  source: $shipAddressFormValues,
  fn: ({ address1, address2, zipcode, city, state_abbr }) => ({
    primary_line: `${address1} ${address2}`,
    zip_code: zipcode,
    city,
    state: state_abbr,
  }),
  target: addressVerificationActions.verifyAddressFx,
});

sample({
  clock: addressVerificationActions.verifyAddressFx.doneData,
  source: $isAddressAPoBox,
  filter: isAddressAPoBox => !isAddressAPoBox,
  target: addressVerificationActions.showModal,
});

split({
  clock: addressActions.checkZipServicedByInstaller,
  source: $isInstallerServicingCustomersZip,
  match: {
    servicing: servicing => servicing,
    notServicing: servicing => !servicing,
  },
  cases: {
    servicing: addressActions.zipIsServicedByInstaller,
    notServicing: addressActions.zipIsNotServicedByInstaller,
  },
});

sample({
  clock: addressActions.checkDistance,
  source: $shipAddressFormValues,
  fn: ({ zipcode }) => ({ zipcode }),
  target: addressActions.checkDistanceFx,
});

split({
  source: addressActions.checkDistanceFx.doneData,
  match: {
    allowed: ({ allowed }) => allowed,
    notAllowed: ({ allowed }) => !allowed,
  },
  cases: {
    allowed: addressActions.distanceAllowed,
    notAllowed: addressActions.distanceNotAllowed,
  },
});

forward({
  from: [
    addressActions.distanceNotAllowed,
    addressActions.zipIsNotServicedByInstaller,
  ],
  to: [
    deliveryActions.pickFedex,
    actions.changeStep.prepend(() => 'delivery'),
    actions.setErrorMessage.prepend(
      () =>
        'Sorry, your location is too far away from the installer. Your order has been updated to home delivery',
    ),
  ],
});

sample({
  clock: addressActions.completeAddress,
  fn: addressDataToSend => ({
    ...addressDataToSend,
    state: 'address',
  }),
  target: addressActions.completeAddressFx,
});

sample({
  clock: [
    addressVerificationActions.confirmAddress,
    addressActions.distanceAllowed,
    addressActions.zipIsServicedByInstaller,
    addressActions.checkDistanceFx.fail,
    addressVerificationActions.verifyAddressFx.failData,
  ],
  source: $addressDataToSend,
  target: addressActions.completeAddress,
});

const $isLoading = combine(
  [
    addressVerificationActions.verifyAddressFx.pending,
    updateAddressFx.pending,
    deliveryActions.updateFedexFx.pending,
    deliveryActions.updatePreferredLocationFx.pending,
    addressActions.completeAddressFx.pending,
    addressActions.checkDistanceFx.pending,
    protectionPlansActions.submitProtectionPlansFx.pending,
    lugNutsActions.updateWheelsVehicleInfoFx.pending,
    orderStores.$isLoadingOrder,
    completeAccessoriesStepFx.pending,
  ],
  loadingStates => loadingStates.some(Boolean),
);

const $errorNotificationMessage = createStore(null)
  .on(actions.clearErrorMessage, () => null)
  .on(actions.setErrorMessage, (_, m) => m);

sample({
  clock: [
    paymentActions.getSyfCheckoutTokenFx.fail,
    paymentActions.completeSyfPaymentFx.fail,
  ],
  fn: ({ error }) => error?.response?.data?.message,
  target: $errorNotificationMessage,
});

sample({
  clock: [
    // addressVerificationActions.verifyAddressFx.failData,
    deliveryActions.updateFedexFx.failData,
    deliveryActions.updatePreferredLocationFx.failData,
    addressActions.completeAddressFx.failData,
    protectionPlansActions.submitProtectionPlansFx.failData,
    lugNutsActions.updateWheelsVehicleInfoFx.failData,
    completeAccessoriesStepFx.failData,
    orderActions.ensureOrderConsistencyFx.failData,
  ],
  fn: error => {
    const errorMessage = error?.response?.data?.error;
    return errorMessage || error;
  },
  target: $errorNotificationMessage,
});

sample({
  clock: actions.updatedCheckout,
  target: [
    orderActions.setState.prepend(({ order }) => order),
    actions.changeStep.prepend(({ order }) => order.state),
  ],
});

sample({
  clock: actions.updatedCheckout,
  fn: ({ allstate_plans, extend_plans }) => ({ allstate_plans, extend_plans }),
  target: protectionPlansActions.setPlans,
});

sample({
  clock: actions.updatedCheckout,
  fn: ({ addons }) => addons,
  target: addonsStores.$addons,
});

const $addonsForRender = addonsStores.$addons.map(addons =>
  filter(addons, ['type', 'tpms_sensor']),
);
const $isEmptyAddonsForRender = $addonsForRender.map(state => isEmpty(state));

const $tmpsItems = orderStores.$lineItems.map(lineItems =>
  lineItems.filter(i => i.product_type === 'tpms'),
);

const $isEmptyTmpsItems = $tmpsItems.map(item => isEmpty(item));

const $showFreeLugNutsMessage = orderStores.$wheels.map(items =>
  items.every(({ quantity }) => quantity >= 4),
);

const $confirmedSummary = createStore(false).on(
  actions.confirmSummary,
  () => true,
);

forward({
  from: paymentActions.initPaypairFx.finally,
  to: orderActions.ensureOrderConsistencyFx,
});

forward({
  from: orderActions.ensureOrderConsistencyFx.fail,
  to: createEffect().use(() => location.reload()),
});

export const stores = {
  $shipAddressFormValues,
  $billAddressFormValues,
  $addressParamsFormValues,
  $isLoading,
  $currentStep,
  $addonsForRender,
  $isEmptyAddonsForRender,
  $tmpsItems,
  $isEmptyTmpsItems,
  $errorNotificationMessage,
  $showFreeLugNutsMessage,
  $confirmedSummary,
};

export const store = combine(stores);

persist({
  clock: actions.updatedCheckout,
  store: $deliveryMethod,
  key: 'delivery-method',
});
