import { forward, createDomain, combine, sample } from 'effector';
import http from 'src/logic/http';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import keyBy from 'lodash/keyBy';

import { actions as drawerActions } from 'src/effector/drawer';
import { stores as searchStores } from 'src/effector/search';
import * as api from 'src/logic/api';

const taxLabels = {
  'State Fees': 'State Tire Tax',
  Tax: 'Sales Tax',
};

const getOrderApiCall = async () => {
  const { data } = await http.get(`${window.location.origin}/api/v1/orders`);
  return data;
};

const orderDomain = createDomain('order');

const checkInconsistensy = orderDomain.createEvent();
const notifyOfInconsistensy = orderDomain.createEvent();
const checkInconsistensyFx = orderDomain.createEffect().use(getOrderApiCall);

const setState = orderDomain.createEvent();

const getOrder = orderDomain.createEvent();
const getOrderFx = orderDomain.createEffect().use(getOrderApiCall);

const createLineItem = orderDomain.createEvent();
const createLineItemFx = orderDomain.createEffect().use(async params => {
  const { data } = await http.post(
    `${window.location.origin}/api/v1/orders/line_items`,
    {
      items: params,
    },
  );
  return data;
});

const destroyLineItem = orderDomain.createEvent();
const destroyLineItemFx = orderDomain.createEffect().use(async params => {
  const { data } = await http.delete(
    `${window.location.origin}/api/v1/orders/line_items/${params.id}`,
    params,
  );
  return data;
});

const updateLineItem = orderDomain.createEvent();
const updateLineItemFx = orderDomain.createEffect().use(async params => {
  const { data } = await http.patch(
    `${window.location.origin}/api/v1/orders/line_items/${params.id}`,
    params,
  );
  return data;
});

const ensureOrderConsistencyFx = orderDomain
  .createEffect()
  .use(api.ensureOrderConsistency);

forward({
  from: getOrder,
  to: getOrderFx,
});

forward({
  from: createLineItem,
  to: createLineItemFx,
});

forward({
  from: destroyLineItem,
  to: destroyLineItemFx,
});

forward({
  from: updateLineItem,
  to: updateLineItemFx,
});

const $order = orderDomain
  .createStore({})
  .on(createLineItemFx.doneData, (_, order) => order)
  .on(destroyLineItemFx.doneData, (_, order) => order)
  .on(updateLineItemFx.doneData, (_, order) => order)
  .on(getOrderFx.doneData, (_, order) => order)
  .on(setState, (_, order) => order);

sample({
  clock: checkInconsistensy,
  source: $order.map(o => o.state),
  filter: s => s === 'payment',
  target: checkInconsistensyFx,
});

sample({
  clock: checkInconsistensyFx.doneData,
  source: $order.map(o => o.total_number),
  filter: (currentNumber, o) => o.total_number !== currentNumber,
  target: notifyOfInconsistensy,
});

const $allTaxes = $order.map(({ all_taxes = [] }) =>
  all_taxes.map(item => ({
    ...item,
    label: taxLabels[item.label] || item.label,
  })),
);

const $lineItems = $order.map(
  state =>
    orderBy(state?.line_items, [item => new Date(item.created_at)], ['desc']) ||
    [],
);

const $wheels = $lineItems.map(items =>
  items.filter(({ product_type }) => product_type === 'wheel'),
);

const $tires = $lineItems.map(items =>
  items.filter(({ product_type }) => product_type === 'tire'),
);

const $tiresById = $tires.map(items => keyBy(items, 'id'));
const $uncommercialTires = $tires.map(items =>
  items.filter(({ is_tbr }) => !is_tbr),
);

const $tiresWithoutWarranty = $uncommercialTires.map(items =>
  orderBy(
    items.filter(i => isEmpty(i.warranty_info)),
    [item => new Date(item.created_at)],
    ['desc'],
  ),
);

const $latestWheelWithoutWarranty = $wheels.map(
  items =>
    orderBy(
      items.filter(i => isEmpty(i.warranty_info)),
      [item => new Date(item.created_at)],
      ['desc'],
    )[0],
);

const $stateIsAllowedForAllstate = $order.map(
  order => order.state_is_allowed_for_allstate,
);

const $existTiresWithoutWarranty = $tiresWithoutWarranty.map(
  tires => !isEmpty(tires),
);

const $shouldShowAllstateFlayout = combine(
  [
    $existTiresWithoutWarranty,
    searchStores.$isAllowedToAddAllstate,
    $stateIsAllowedForAllstate,
  ],
  conditions => conditions.every(Boolean),
);

const $tiresAndWheels = $lineItems.map(items =>
  items.filter(({ product_type }) => ['wheel', 'tire'].includes(product_type)),
);

const $wheelInOrderPresent = $wheels.map(items => !isEmpty(items));
const $tiresInOrderPresent = $tires.map(items => !isEmpty(items));

const $isOnlyTiresInOrder = combine(
  $wheelInOrderPresent,
  $tiresInOrderPresent,
  (wheelInOrderPresent, tiresInOrderPresent) =>
    !wheelInOrderPresent && tiresInOrderPresent,
);
const $isAllTiresWithWarranty = $tires.map(items =>
  isEmpty(items) ? false : items.every(item => !isEmpty(item.warranty_info)),
);

const $isEmptyOrder = $order.map(
  state => isEmpty(state) || isEmpty(state?.line_items),
);

const $lineItemsToConfirmQuantity = $tiresAndWheels.map(items =>
  items.filter(({ confirmed_quantity }) => !confirmed_quantity),
);

const $activeState = $order.map(state =>
  !isEmpty(state) ? state.state : 'delivery',
);

const $orderLoaded = $order.map(order => !isEmpty(order));

const $isLoadingOrder = combine(
  [
    createLineItemFx.pending,
    destroyLineItemFx.pending,
    updateLineItemFx.pending,
    getOrderFx.pending,
  ],
  pendings => pendings.some(Boolean),
);

forward({
  from: createLineItemFx,
  to: orderDomain.createEffect().use(() => {
    window.Alpine.store('add_to_cart').disable();
  }),
});

sample({
  clock: createLineItemFx.done,
  filter: ({ params }) =>
    !params.find(({ type }) =>
      ['tpms', 'inflater', 'tire_bags', 'tire', 'wheel'].includes(type),
    ),
  target: orderDomain.createEffect().use(() => {
    window.location = '/checkout';
  }),
});

sample({
  clock: createLineItemFx.done,
  source: $shouldShowAllstateFlayout,
  filter: (shouldShowAllstateFlayout, { params }) =>
    params.find(({ type }) => ['tire'].includes(type)) &&
    shouldShowAllstateFlayout,
  target: drawerActions.open.prepend(() => ({
    type: 'allstate-flayout',
    origin: 'right',
    config: { wrapper: { class: 'hidden' }, outsideClick: () => ({}) },
  })),
});

sample({
  clock: createLineItemFx.done,
  source: $shouldShowAllstateFlayout,
  filter: (shouldShowAllstateFlayout, { params }) =>
    params.find(({ type }) => ['tire'].includes(type)) &&
    !shouldShowAllstateFlayout,
  target: orderDomain.createEffect().use(() => {
    window.location = '/checkout';
  }),
});

export const actions = {
  getOrder,
  getOrderFx,
  createLineItem,
  destroyLineItem,
  updateLineItem,
  createLineItemFx,
  destroyLineItemFx,
  updateLineItemFx,
  setState,
  notifyOfInconsistensy,
  ensureOrderConsistencyFx,
};

export const stores = {
  $order,
  $isLoadingOrder,
  $isEmptyOrder,
  $lineItems,
  $wheels,
  $wheelInOrderPresent,
  $activeState,
  $orderLoaded,
  $allTaxes,
  $lineItemsToConfirmQuantity,
  $tiresAndWheels,
  $tires,
  $tiresById,
  $tiresWithoutWarranty,
  $isOnlyTiresInOrder,
  $isAllTiresWithWarranty,
  $tiresInOrderPresent,
  $latestWheelWithoutWarranty,
};

const listenInconsistencyFx = orderDomain.createEffect().use(() => {
  document.addEventListener(
    'visibilitychange',
    () => document.visibilityState === 'visible' && checkInconsistensy(),
  );
});

listenInconsistencyFx();

export const store = combine(stores);
