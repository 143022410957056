import axios from 'axios';

let csrf;
try {
  csrf = document
    .querySelector("meta[name='csrf-token']")
    .getAttribute('content');
} catch (e) {
  console.error(e);
}

export default axios.create({
  baseURL: '/',
  timeout: 10000,
  headers: {
    'X-CSRF-TOKEN': csrf,
    'x-requested-with': 'XMLHttpRequest',
  },
});
