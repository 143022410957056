export default {
  size: {
    tires: {
      single: {
        fitment: {
          header: 'Great! Will all of your tires be the same size?',
          confirm: 'Confirm Fitment',
        },
        tireWidths: {
          header: 'What’s your tire width?',
        },
        aspectRatios: {
          header: 'What’s your tire aspect ratio?',
        },
        wheelDiameters: {
          header: '<span>What’s your tire diameter?</span>',
          confirm: 'Confirm and Continue',
        },
        zip: {
          header: 'Last one! Please enter your zip code below.',
          confirm: 'Confirm and View Results',
        },
        LTQuestion: {
          header: 'Do you need an LT tire?',
        },
        trailerQuestion: {
          header: 'Is this for a trailer / hitch?',
        },
      },
      staggered: {
        fitment: {
          header: 'Great! Will all of your tires be the same size?',
          confirm: 'Confirm Fitment',
        },
        tireWidths: {
          header: 'What’s your front tire width?',
        },
        aspectRatios: {
          header: 'What’s your front tire aspect ratio?',
        },
        wheelDiameters: {
          header: '<span>What’s your front tire diameter?</span>',
          confirm: 'Confirm Front Tire Size',
        },
        tireWidthsRear: {
          header: "Ok, now let's get your rear tire width?",
        },
        aspectRatiosRear: {
          header: 'What’s your rear tire aspect ratio?',
        },
        wheelDiametersRear: {
          header: '<span>What’s your rear tire diameter?</span>',
          confirm: 'Confirm and Continue',
        },
        zip: {
          header: 'Last one! Please enter your zip code below.',
          confirm: 'Confirm and View Results',
        },
      },
    },
    wheels: {
      single: {
        diameters: {
          header: 'What’s your wheel diameter?',
          label: 'Diameter',
        },
        widths: {
          header: 'What’s your wheel width?',
          label: 'Width',
        },
        lugs: {
          header: 'How many lugs does your wheel have?',
          label: 'Lugs',
        },
        boltPatterns: {
          header: 'What’s your wheel bolt circle?',
          label: 'Bolt Circle',
        },
        offsets: {
          header: 'What’s your wheel offset?',
          label: 'Offsets',
          confirm: 'Confirm and Continue',
        },
        zip: {
          header: 'Last one! Please enter your zip code below.',
          confirm: 'Confirm and View Results',
        },
      },
    },
  },
  vehicle: {
    tires: {
      makes: {
        header: 'Great! What brand of vehicle do you drive?',
        searchBarPlaceholder: 'Search Brand',
      },
      years: {
        header: 'What year is it?',
        searchBarPlaceholder: 'Search Year',
      },
      models: {
        header: 'Which model do you have?',
        searchBarPlaceholder: 'Search Model',
      },
      trims: {
        header: 'Almost there. What’s the trim level?',
      },
      verify: {
        header: `
          <span>
            What tire size is currently on
            <br />
            your vehicle?
          </span>
        `,
        confirm: 'Confirm and Continue',
      },
      zip: {
        header: 'Last one! Please enter your zip code below.',
        confirm: 'Confirm and View Results',
      },
    },
    wheels: {
      makes: {
        header: 'Great! What brand of vehicle do you drive?',
        searchBarPlaceholder: 'Search Brand',
      },
      years: {
        header: 'What year is it?',
        searchBarPlaceholder: 'Search Year',
      },
      models: {
        header: 'Which model do you have?',
        searchBarPlaceholder: 'Search Model',
      },
      trims: {
        header: 'Almost there. What’s the trim level?',
      },
      verify: {
        header: `
          <span>
            Can you help us
            <br />
            confirm your tire size?
          </span>
        `,
        confirm: 'Confirm Tire Size',
      },
      fitment: {
        header: 'Would you like staggered fitment?',
        confirm: 'Confirm Fitment',
      },
      rim: {
        header: '<span>What is your desired rim size?</span>',
        confirm: 'Confirm and Continue',
      },
      zip: {
        header: 'Last one! Please enter your zip code below.',
        confirm: 'Confirm and View Results',
      },
    },
  },
};
