import {
  combine,
  createStore,
  createEvent,
  createEffect,
  forward,
  sample,
  split,
} from 'effector';
import { createForm } from 'effector-forms';
import rules from 'src/effector/forms/rules';
import { getErrors } from 'src/effector/forms/helpers';
import * as api from 'src/logic/api';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import get from 'lodash/get';

const setFlashMessage = createEvent();

const clearFlashMessage = createEvent();
const clearFlashMessageIn5sec = createEffect().use(() =>
  setTimeout(() => clearFlashMessage(), 5000),
);

const $flashMessage = createStore(null)
  .on(setFlashMessage, (_, m) => m)
  .reset(clearFlashMessage);

forward({
  from: setFlashMessage,
  to: clearFlashMessageIn5sec,
});

const setPromoCodeError = createEvent();
const clearPromoCodeError = createEvent();
const createPromoCode = createEvent();
const submitPromoForm = createEvent();
const createPromoCodeFx = createEffect().use(api.addPromoCode);

const togglePromoCodeForm = createEvent();
const closePromoCodeForm = createEvent();
const $promoCodeError = createStore(null)
  .on(setPromoCodeError, (_, s) => s)
  .reset([clearPromoCodeError, togglePromoCodeForm]);
const $isOpenPromoCodeForm = createStore(false)
  .on(togglePromoCodeForm, store => !store)
  .on(closePromoCodeForm, () => false);

const promoCodeForm = createForm({
  fields: {
    code: {
      init: '',
      rules: [rules.required()],
    },
  },
});

const $promoCodeFormErrors = getErrors(
  Object.keys(promoCodeForm.fields),
  promoCodeForm,
);

const $isLoadingPromoCode = createPromoCodeFx.pending;

const moveForward = createEvent();
const init = createEvent();
const moveToCompleted = createEvent();

const firstStepContinue = createEvent();
const secondStepContinue = createEvent();
const thirdStepContinue = createEvent();
const fourthStepContinue = createEvent();
const submit = createEvent();
const submitFx = createEffect().use(api.submitPayment);

const back = createEvent();

const setStates = createEvent();
const $states = createStore([]).on(setStates, (_, data) => data);

const setOrderData = createEvent();
const $orderData = createStore({}).on(setOrderData, (_, d) => d);

const setCheckoutInfo = createEvent();
const $checkoutInfo = createStore({})
  .on(setCheckoutInfo, (_, s) => s)
  .on(createPromoCodeFx.doneData, (_, { checkout_info }) => checkout_info);
const $checkoutInfoTaxes = $checkoutInfo.map(({ taxes }) => taxes);
const $checkoutInfoTotal = $checkoutInfo.map(({ total }) => total);

const getOrder = createEvent();
const getOrderFx = createEffect().use(api.fetchOrderByNumberAndEmail);

const $order = $orderData.map(({ order }) => order || {});
const $returnData = $orderData.map(({ return_data }) => return_data);
const $lineItems = $order.map(({ line_items }) => line_items);

const createOrderReturn = createEvent();
const createOrderReturnFx = createEffect().use(api.createOrderReturn);

const $orderReturnId = createStore(null)
  .on($orderData, (_, { order_return_id }) => order_return_id)
  .on(
    createOrderReturnFx.doneData,
    (_, { order_return_id }) => order_return_id,
  );

const setCurrentStep = createEvent();
const $currentStep = createStore('first')
  .on(setCurrentStep, (_, o) => o)
  .on(moveToCompleted, () => 'completed');

const setFirstStepContinueError = createEvent();
const clearFirstStepContinueError = createEvent();
const $firstStepContinueError = createStore(null)
  .on(setFirstStepContinueError, (_, m) => m)
  .reset(clearFirstStepContinueError);

const firstStepForm = createForm({
  fields: {
    orderNumber: {
      init: '',
      rules: [rules.required()],
    },
    email: {
      init: '',
      rules: [rules.email()],
    },
  },
});

const pickupAddressForm = createForm({
  fields: {
    fullname: {
      init: '',
    },
    address2: {
      init: '',
    },
    company: {
      init: '',
    },
    address1: {
      init: '',
      rules: [rules.required()],
    },
    city: {
      init: '',
      rules: [rules.required()],
    },
    zipcode: {
      init: '',
      rules: [rules.required(), rules.minLength(5)],
    },
    state: {
      init: '',
      rules: [rules.required()],
    },
  },
});

const agreementForm = createForm({
  fields: {
    agreeReturn: {
      init: false,
      rules: [rules.required()],
    },
    confirmNewerUsed: {
      init: false,
      rules: [rules.required()],
    },
  },
});

const $agreementFormErrors = getErrors(
  Object.keys(agreementForm.fields),
  agreementForm,
);

const showPickupAddressModal = createEvent();
const closePickupAddressModal = createEvent();
const $isShownPickupAddressModal = createStore(false)
  .on(showPickupAddressModal, () => true)
  .on(closePickupAddressModal, () => false);

const $pickupAddressFormErrors = getErrors(
  Object.keys(pickupAddressForm.fields),
  pickupAddressForm,
);

const $returnReasons = createStore(null);
const setReturnReasons = createEvent();

const validateSecondStepForm = createEvent();

const $firstStepFormErrors = getErrors(
  Object.keys(firstStepForm.fields),
  firstStepForm,
);

const setSecondStepFormValues = createEvent();
const setSecondStepFormErrors = createEvent();
const setIsValidFormsById = createEvent();

const $secondStepFormValues = createStore({}).on(
  setSecondStepFormValues,
  (s, d) => ({ ...s, ...d }),
);

const $secondStepFormErrors = createStore({}).on(
  setSecondStepFormErrors,
  (s, d) => ({ ...s, ...d }),
);

const $isValidFormsById = createStore({}).on(setIsValidFormsById, (s, d) => ({
  ...s,
  ...d,
}));

const secondStepFormsById = {};

const initStripe = createEvent();
const setStripe = createEvent();
const $stripe = createStore({}).on(setStripe, (_, s) => s);

const setPaymentMethodId = createEvent();
const $paymentMethodId = createStore('').on(setPaymentMethodId, (_, pi) => pi);

const setStripeElements = createEvent();
const $stripeElements = createStore({}).on(setStripeElements, (_, e) => e);

const initStripeFx = createEffect().use(({ stripe_pk, paymentMethodId }) => {
  const stripe = window.Stripe(stripe_pk);
  setStripe(stripe);
  setPaymentMethodId(paymentMethodId);
});

const initStripeElementsFx = createEffect().use(stripe => {
  const elements = stripe.elements();
  const style = {
    base: {
      fontSize: '16px',
      fontWeight: 300,

      '::placeholder': {
        color: '#CFD7E0',
      },
    },
  };

  const setOutcome = (result, fieldId) => {
    const errorElement = document.getElementById(`${fieldId}-error`);
    const containerElement = document.getElementById(`${fieldId}-element`);

    errorElement.classList.add('hidden');
    containerElement.classList.remove('!border-red');

    if (result.error) {
      console.log(result);
      errorElement.textContent = result.error.message;
      errorElement.classList.remove('hidden');
      containerElement.classList.add('!border-red');
    }
  };

  const inputNames = ['cardNumber', 'cardExpiry', 'cardCvc'];
  const placeholders = {
    cardNumber: '* Card Number',
    cardExpiry: '* Exp. (MM/YY)',
    cardCvc: '* CVC',
  };
  const stripeElements = inputNames.reduce((acc, name) => {
    const elem = elements.create(name, {
      style,
      placeholder: placeholders[name],
    });
    elem.mount(`#${name}-element`);
    elem.on('change', e => setOutcome(e, name));
    return { ...acc, [name]: elem };
  }, {});
  setStripeElements(stripeElements);
});

const purchaseWithStripe = createEvent();

const createTokenFx = createEffect().use(
  ({ cardNumberElement, options, stripe }) =>
    stripe.createToken(cardNumberElement, options),
);

const showCardNumberErrorFx = createEffect().use(message => {
  const errorElement = document.getElementById('cardNumber-error');
  errorElement.textContent = message;
  errorElement.classList.add('visible');
});

const $lineItemsToRender = combine(
  {
    errorsById: $secondStepFormErrors,
    valuesById: $secondStepFormValues,
  },
  ({ errorsById, valuesById }) =>
    Object.values(valuesById).map(values => ({
      values,
      errors: errorsById[values.itemData.id],
      actions: secondStepFormsById[values.itemData.id],
      totalFee: (values.itemData.tooltip_info.sum * values.quantity).toFixed(2),
      ...values.itemData,
    })),
);

const $selectedItemsToReturn = $lineItemsToRender.map(items =>
  items.filter(i => i.values.isSelected),
);

const $noItemsPickedToReturn = $selectedItemsToReturn.map(items =>
  isEmpty(items),
);

const $isValidSecondStepForm = combine(
  {
    isValidFormsById: $isValidFormsById,
    selectedItemsToReturn: $selectedItemsToReturn,
  },
  ({ isValidFormsById, selectedItemsToReturn }) =>
    !isEmpty(selectedItemsToReturn) &&
    selectedItemsToReturn.every(i => isValidFormsById[i.values.itemData.id]),
);

const secondStepFormValidated = createEvent();

sample({
  clock: validateSecondStepForm,
  source: $selectedItemsToReturn,
  fn: items => items.map(i => i.values.itemData.id),
  target: createEffect().use(ids => {
    ids.forEach(id => secondStepFormsById[id].validate());
    setTimeout(() => {
      secondStepFormValidated();
    }, 100);
  }),
});

sample({
  source: { lineItems: $lineItems, returnData: $returnData },
  fn: ({ lineItems, returnData = {} }) =>
    lineItems.map(item => ({
      form: createForm({
        fields: {
          isSelected: {
            init: get(returnData, [item.id, 'isSelected'], false),
          },
          quantity: {
            init: get(returnData, [item.id, 'quantity'], item.quantity),
          },
          reason: {
            init: {
              name: '',
              id: get(returnData, [item.id, 'returnReason'], ''),
            },
            rules: [rules.required()],
          },
          comment: {
            init: get(returnData, [item.id, 'comment'], ''),
            rules: [rules.required()],
          },
          wholeImage: {
            init: get(returnData, [item.id, 'wholeImage'], ''),
            rules: [rules.required()],
          },
          upcloseImage: {
            init: get(returnData, [item.id, 'upcloseImage'], ''),
            rules: [rules.required()],
          },
          itemData: {
            init: item,
          },
        },
      }),
      id: item.id,
    })),
  target: [
    createEffect().use(forms =>
      forms.forEach(({ form, id }) => {
        secondStepFormsById[id] = form;

        getErrors(Object.keys(form.fields), form).watch(v =>
          setSecondStepFormErrors({ [id]: v }),
        );

        form.$values.watch(v => setSecondStepFormValues({ [id]: v }));
        form.$isValid.watch(v => setIsValidFormsById({ [id]: v }));
      }),
    ),
  ],
});

const $dataToCreateOrderReturn = combine(
  {
    pickupAddressFormValues: pickupAddressForm.$values,
    selectedItemsToReturn: $selectedItemsToReturn,
    order: $order,
    orderReturnId: $orderReturnId,
  },
  ({
    pickupAddressFormValues,
    selectedItemsToReturn,
    order,
    orderReturnId,
  }) => {
    const pick_up_address_attributes = {
      ...order.ship_address,
      state_id: pickupAddressFormValues.state.id,
      company: pickupAddressFormValues.company,
      address1: pickupAddressFormValues.address1,
      zipcode: pickupAddressFormValues.zipcode,
      city: pickupAddressFormValues.city,
    };

    const return_data = keyBy(
      selectedItemsToReturn.map(item => ({
        id: item.id,
        comment: item.values.comment,
        isSelected: item.values.isSelected,
        quantity: item.values.quantity,
        returnReason: item.values.reason.id,
        upcloseImage: item.values.upcloseImage,
        wholeImage: item.values.wholeImage,
      })),
      'id',
    );

    return {
      order_id: order.id,
      order_return: {
        pick_up_address_attributes,
        return_data,
      },
      order_return_id: orderReturnId,
    };
  },
);

const $stripeTokenData = createStore({}).on(createTokenFx.doneData, (_, data) =>
  get(data, 'token', {}),
);

const $dataToSubmit = combine(
  {
    order: $order,
    orderReturnId: $orderReturnId,
    paymentMethodId: $paymentMethodId,
    stripeTokenData: $stripeTokenData,
  },
  ({ order, orderReturnId, paymentMethodId, stripeTokenData }) => {
    return {
      payment_source: {
        [paymentMethodId]: {
          gateway_payment_profile_id: stripeTokenData.id,
          last_digits: stripeTokenData.card?.last4,
          month: stripeTokenData.card?.exp_month,
          year: stripeTokenData.card?.exp_year,
        },
      },
      order: { payments_attributes: [{ payment_method_id: paymentMethodId }] },
      order_id: order.id,
      order_return_id: orderReturnId,
    };
  },
);

const $isLoading = combine(
  [
    getOrderFx.pending,
    createOrderReturnFx.pending,
    createTokenFx.pending,
    createOrderReturnFx.pending,
    submitFx.pending,
  ],
  s => s.some(Boolean),
);

forward({
  from: initStripe,
  to: initStripeFx,
});

sample({
  clock: initStripeFx.done,
  source: $stripe,
  target: initStripeElementsFx,
});

sample({
  clock: createTokenFx.doneData,
  filter: ({ error }) => error,
  fn: ({ error: { message } }) => message,
  target: showCardNumberErrorFx,
});

sample({
  clock: createTokenFx.doneData,
  filter: ({ error }) => !error,
  target: submit,
});

sample({
  clock: purchaseWithStripe,
  source: {
    pickupAddressFormValues: pickupAddressForm.$values,
    stripeElements: $stripeElements,
    stripe: $stripe,
  },
  fn: ({ pickupAddressFormValues, stripeElements, stripe }) => ({
    cardNumberElement: stripeElements.cardNumber,
    options: {
      name: pickupAddressFormValues.fullname,
      address_line1: pickupAddressFormValues.address1,
      address_line2: pickupAddressFormValues.address2 || '',
      address_city: pickupAddressFormValues.city,
      address_state: pickupAddressFormValues.state.name,
      address_country: 'US',
      address_zip: pickupAddressFormValues.zipcode,
    },
    stripe,
  }),
  target: createTokenFx,
});

forward({
  from: [setCurrentStep, moveToCompleted],
  to: createEffect().use(() => window.scrollTo(0, 0)),
});

sample({
  clock: closePickupAddressModal,
  source: $currentStep,
  filter: s => s === 'fourth',
  target: createOrderReturn,
});

sample({
  clock: createOrderReturn,
  source: $dataToCreateOrderReturn,
  target: createOrderReturnFx,
});

sample({
  clock: createOrderReturnFx.doneData,
  target: [
    setCurrentStep.prepend(() => 'fourth'),
    setCheckoutInfo.prepend(({ checkout_info }) => checkout_info),
  ],
});

forward({
  from: setReturnReasons,
  to: $returnReasons,
});

sample({
  clock: sample({
    clock: firstStepContinue,
    target: firstStepForm.validate,
  }),
  source: firstStepForm.$isValid,
  filter: isValid => isValid,
  target: getOrder,
});

sample({
  clock: getOrder,
  source: firstStepForm.$values,
  fn: ({ orderNumber, email }) => ({
    number: orderNumber,
    email,
  }),
  target: getOrderFx,
});

sample({
  clock: getOrderFx.failData,
  fn: e => e.response.data.message,
  target: setFirstStepContinueError,
});

forward({
  from: getOrderFx.doneData,
  to: [
    setOrderData,
    clearFirstStepContinueError,
    setCurrentStep.prepend(() => 'second'),
    pickupAddressForm.setForm.prepend(({ pickup_address_data }) => ({
      fullanme: pickup_address_data.fullname,
      company: pickup_address_data.company,
      address2: pickup_address_data.address2,
      address1: pickup_address_data.address1,
      city: pickup_address_data.city,
      zipcode: pickup_address_data.zipcode,
      state: {
        id: pickup_address_data.state_id,
        name: pickup_address_data.state_fullname,
      },
    })),
  ],
});
sample({
  clock: secondStepContinue,
  target: validateSecondStepForm,
});

sample({
  clock: secondStepFormValidated,
  source: $isValidSecondStepForm,
  filter: isValid => isValid,
  target: setCurrentStep.prepend(() => 'third'),
});

forward({
  from: thirdStepContinue,
  to: createOrderReturn,
});

sample({
  clock: sample({
    clock: fourthStepContinue,
    target: agreementForm.validate,
  }),
  source: agreementForm.$isValid,
  filter: isValid => isValid,
  target: purchaseWithStripe,
});

split({
  source: back,
  match: $currentStep,
  cases: {
    second: setCurrentStep.prepend(() => 'first'),
    third: setCurrentStep.prepend(() => 'second'),
    fourth: setCurrentStep.prepend(() => 'third'),
  },
});

split({
  clock: moveForward,
  source: $currentStep,
  match: $currentStep,
  cases: {
    first: firstStepContinue,
    second: secondStepContinue,
    third: thirdStepContinue,
    fourth: fourthStepContinue,
  },
});

sample({
  clock: sample({
    clock: submitPromoForm,
    target: promoCodeForm.validate,
  }),
  source: promoCodeForm.$isValid,
  filter: isValid => isValid,
  target: createPromoCode,
});

sample({
  clock: createPromoCode,
  source: { values: promoCodeForm.$values, orderReturnId: $orderReturnId },
  fn: ({ values, orderReturnId }) => ({
    coupon_code: values.code,
    order_return_id: orderReturnId,
  }),
  target: createPromoCodeFx,
});

sample({
  clock: createPromoCodeFx.doneData,
  filter: ({ status }) => status,
  target: closePromoCodeForm,
});

sample({
  clock: createPromoCodeFx.doneData,
  filter: ({ status }) => !status,
  target: setPromoCodeError.prepend(({ message }) => message),
});

sample({
  clock: submit,
  source: $dataToSubmit,
  target: submitFx,
});

forward({
  from: submitFx.done,
  to: moveToCompleted,
});

forward({
  from: submitFx.fail,
  to: [
    setFlashMessage.prepend(
      ({
        error: {
          response: {
            data: { message },
          },
        },
      }) => message,
    ),
    setCurrentStep.prepend(() => 'first'),
  ],
});

export const stores = {
  $firstStepContinueError,
  $firstStepFormValues: firstStepForm.$values,
  $isValidFirstStepForm: firstStepForm.$isValid,
  $pickupAddressFormErrors,
  $pickupAddressFormValues: pickupAddressForm.$values,
  $promoCodeFormValues: promoCodeForm.$values,
  $agreementFormValues: agreementForm.$values,
  $agreementFormErrors,
  $promoCodeFormErrors,
  $isShownPickupAddressModal,
  $firstStepFormErrors,
  $secondStepFormValues,
  $isValidSecondStepForm,
  $lineItemsToRender,
  $secondStepFormErrors,
  $currentStep,
  $order,
  $noItemsPickedToReturn,
  $returnReasons,
  $states,
  $dataToCreateOrderReturn,
  $isLoading,
  $isLoadingPromoCode,
  $isOpenPromoCodeForm,
  $promoCodeError,
  $checkoutInfoTaxes,
  $checkoutInfoTotal,
  $dataToSubmit,
  $flashMessage,
};

window.secondStepFormsById = secondStepFormsById;

export const actions = {
  firstStepFormActions: firstStepForm,
  pickupAddressFormActions: pickupAddressForm,
  promoCodeFormActions: promoCodeForm,
  agreementFormActions: agreementForm,
  secondStepFormsById,
  showPickupAddressModal,
  closePickupAddressModal,
  back,
  moveForward,
  init,
  submit,
  setReturnReasons,
  setStates,
  createPromoCode,
  togglePromoCodeForm,
  submitPromoForm,
  initStripe,
  purchaseWithStripe,
};

export const store = combine(stores);
