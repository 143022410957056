import { createStore, createEvent, combine } from 'effector';
import { splitEvery } from 'rambda';
import take from 'lodash/take';

const data = [
  {
    name: 'Devon',
    review:
      'Fast shipping and delivery! Made it easier to get all 4 tires by paying when I get paid! Would highly recommend.',
  },
  {
    name: 'Satisfiedvet',
    review:
      'I found the company on Google. I used the affirm to finance my new rims. The approval was extremely fast. I bought 4 of the vision off road rims in 18in, man are they sharp looking for a lower cost rim construction seems solid. Cannot wait to put them on the BFGs I got',
  },
  {
    name: 'Mark S.',
    review:
      'This is my 2nd set of tires overall price and quick delivery has been awesome I see lots more sets in the future',
  },
  {
    name: 'Joe',
    review:
      'I was a bit unsure only because like the old saying goes "if it seems to good to be true it probably is" but tire agent put a big dent in that saying. They had my size tires, cheaper than my local store, they kept me updated and today the tires arrived as promised and the correct size.',
  },
  {
    name: 'Johnnie',
    review:
      'The experience was great the tires look amazing. I was impressed with the quick delivery. Website was very user friendly. Great job Tier Agent I will make more purchases with this company',
  },
  {
    name: 'Jeff',
    review: 'Fast delivery on my orders.',
  },
  {
    name: 'D.A.',
    review: 'Ordering was easy. And I loved the super fast shipping. Love it',
  },
  {
    name: 'Brian F',
    review:
      'Yall had the wheels I wanted, financing was easy. And I received them extremely quickly.',
  },
  {
    name: 'Robert L',
    review:
      'I received my tires on Monday and was highly impressed by the quality of the tires this being my first time ordering tires online I definitely will be ordering my tires online for now on and the prices are pretty affordable got a better deal than the local tire shops',
  },
  {
    name: 'Judy',
    review:
      'This purchase of tires was the easiest through Tire Agent. Had the tires delivered right to my door within 3 days here in Maine.',
  },
  {
    name: 'Michalynn',
    review:
      'The process was easy and the financing options are great. Tires arrived in a timely fashion.',
  },
  {
    name: 'Mike',
    review:
      'Quick easy and delivery tires and wheels fast. Easy financing process too.',
  },
  {
    name: 'Maurice B.',
    review:
      'This was very easy and accommodating for me at the time , I have been telling all my friends!',
  },
  {
    name: 'GN',
    review:
      'Quick, did not take long to order or receive. Will be putting them on soon. Tires are really nice..',
  },
  {
    name: 'Brian J.',
    review: 'Great deals super fast shipping.',
  },
  {
    name: 'Jose',
    review:
      'The service was excellent and I received my tires in two days of the order definitely recommend the service.',
  },
  {
    name: 'Robert',
    review:
      'Huge selection of tires. Easy financing and the tires came quickly!!',
  },
  {
    name: 'Nate L',
    review:
      'Extremely happy and u guys resolved all my tire issues and would absolutely recommend to friends and family',
  },
  {
    name: 'Nathan M.',
    review:
      'Very easy shopping experience. Shipping was quicker than expected. Tires are as described and in great condition. I will definitely shop here again',
  },
  {
    name: 'Jennifer W',
    review:
      'Tire agent is quick and their website to order is fast and user friendly! The tires are name brand and fairly priced',
  },
  {
    name: 'Myron B',
    review: 'Fast shipping reasonable prices',
  },
  {
    name: 'Jim W',
    review:
      'The service has been great. My tires came fast. Got them in 2 days! Mmore importantly, one tire was the wrong size? I called customer service and Sedfrey Flores stepped up and put in a call tag at FedEx. My FedEx man was here today and that tire is on its way back. WAY TO GO Tire Agent support!! YOU GUYS ROCK!!',
  },
  {
    name: 'Keith',
    review:
      'Easy peazy. So darn easy. Says it all. Process so easy. Cheaper and better than big box stores',
  },
  {
    name: 'James M',
    review:
      'Service online was fast and easy to understand...shipping was great',
  },
  {
    name: 'Ron',
    review:
      'I love your quick service. Got my tires in less 48hrs. Your inventory is awesome!! Your prices are very affordable . Love doing business with you. Very professional! Thank You..',
  },
  {
    name: 'Mr. R',
    review:
      'Great selection, super fast shipping, customer service was very responsive, and the prices were better than anything I could find locally. I will be a repeat customer.',
  },
  {
    name: 'Lisa',
    review:
      'The shipping was fast and the customer service is fantastic. Any issues I had were addressed and solved quickly.',
  },
  {
    name: 'Alexander A.',
    review:
      'Nice atención and good costumer service fast and clear its no issues I like this company.',
  },
  {
    name: 'Austin A',
    review:
      'My overall experience with Tire Agent has been unbelievable. All transactions without hassle were all done from my home desk. Went through all the cost/tire options given on the website and that gives you a lot of options to settle for. Delivery was a breeze',
  },
  {
    name: 'Sara',
    review:
      'TireAgent was easy, quick and simple to use. I would recommend it to anyone needing new tires and especially if you need help with financing. Shipping was amazingly fast as well!',
  },
];

const show = createEvent();
const hide = createEvent();
const loadMore = createEvent();

const $isOpen = createStore(false)
  .on(show, () => true)
  .on(hide, () => false);

const dataByChunks = splitEvery(3, data);
const chuncksAmount = createStore(1).on(loadMore, amount => amount + 1);
const maxChunksAmount = dataByChunks.length;
const $allShown = chuncksAmount.map(amount => amount >= maxChunksAmount);
const $reviews = chuncksAmount.map(amount => take(dataByChunks, amount).flat());

export const store = combine({
  $isOpen,
  $reviews,
  $allShown,
});

export const actions = {
  show,
  hide,
  loadMore,
};
