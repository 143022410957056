import {
  merge,
  sample,
  split,
  combine,
  createEffect,
  createStore,
  forward,
} from 'effector';
import isEmpty from 'lodash/isEmpty';
import qs from 'qs';

import * as api from 'src/logic/api';
import { actions as modalActions } from 'src/effector/searchWidget/modal';
import { generateData } from 'src/effector/searchWidget/models/utils';
import staticData from 'src/effector/searchWidget/static';

const defaultFitmentData = [
  { id: 'non-staggered', value: 'Yes' },
  { id: 'staggered', value: 'No, my front and rear tires are different sizes' },
];

const states = {
  fitment: {
    actions: ['reset', 'setValue', 'setOptions', 'confirm', 'back'],
    effects: ['loadOptions'],
  },
  tireWidths: {
    actions: ['setValue', 'setOptions', 'reset', 'back'],
    effects: ['loadOptions'],
  },
  aspectRatios: {
    actions: ['setValue', 'setOptions', 'back', 'reset'],
    effects: ['loadOptions'],
    resetOn: ['tireWidths'],
  },
  wheelDiameters: {
    actions: [
      'setValue',
      'setOptions',
      'confirm',
      'moveToRear',
      'moveToZip',
      'moveToLTQuestion',
      'moveToTrailerQuestion',
      'viewResult',
      'getPlyTypes',
      'back',
      'reset',
    ],
    effects: ['loadOptions', 'getPlyTypesFx'],
    resetOn: ['aspectRatios'],
    preset: true,
  },
  tireWidthsRear: {
    actions: ['setValue', 'setOptions', 'back', 'reset'],
    effects: ['loadOptions'],
    resetOn: ['wheelDiameters'],
  },
  aspectRatiosRear: {
    actions: ['setValue', 'setOptions', 'back', 'reset'],
    effects: ['loadOptions'],
    resetOn: ['tireWidthsRear'],
  },
  wheelDiametersRear: {
    actions: [
      'setValue',
      'setOptions',
      'confirm',
      'viewResult',
      'back',
      'reset',
    ],
    effects: ['loadOptions'],
    resetOn: ['aspectRatiosRear'],
    preset: true,
  },
  LTQuestion: {
    actions: ['setValue', 'setOptions', 'confirm', 'back', 'reset'],
    effects: ['loadOptions'],
    resetOn: ['wheelDiameters', 'wheelDiametersRear'],
  },
  trailerQuestion: {
    actions: ['setValue', 'setOptions', 'confirm', 'back', 'reset'],
    effects: ['loadOptions'],
    resetOn: ['LTQuestion'],
  },
  zip: {
    actions: ['setValue', 'setOptions', 'confirm', 'back', 'reset'],
    effects: ['loadOptions', 'setZip'],
    resetOn: ['wheelDiameters'],
  },
};

const {
  stores: dataStores,
  actions,
  effects,
  helpers: {
    allData,
    changeStep,
    currentStep,
    currentData,
    currentActions,
    isLoading,
    statesByOrder,
  },
} = generateData(states);

const $plyTypes = createStore({ LT: [], passenger: [], trailer: [] });

const $LTNames = $plyTypes.map(({ LT }) => LT);
const $shouldAskLTQuestion = $plyTypes.map(({ LT }) => !isEmpty(LT));

const $shouldAskTrailerQuestion = $plyTypes.map(
  ({ trailer }) => !isEmpty(trailer),
);

const $shouldNotAskLTQuestion = $shouldAskLTQuestion.map(r => !r);

const stepsOnActionTrigger = [
  { from: actions.fitment.confirm, fn: () => 'tireWidths' },
  { from: effects.aspectRatios.loadOptions.doneData, fn: () => 'aspectRatios' },
  {
    from: effects.wheelDiameters.loadOptions.doneData,
    fn: () => 'wheelDiameters',
  },
  {
    from: [
      actions.wheelDiameters.moveToRear,
      effects.tireWidthsRear.loadOptions.doneData,
    ],
    fn: () => 'tireWidthsRear',
  },
  {
    from: [
      actions.wheelDiameters.moveToZip,
      actions.LTQuestion.setValue,
      actions.trailerQuestion.setValue,
      actions.wheelDiametersRear.confirm,
    ],
    fn: () => 'zip',
  },
  {
    from: actions.wheelDiameters.moveToLTQuestion,
    fn: () => 'LTQuestion',
  },
  {
    from: actions.wheelDiameters.moveToTrailerQuestion,
    fn: () => 'trailerQuestion',
  },
  {
    from: effects.aspectRatiosRear.loadOptions.doneData,
    fn: () => 'aspectRatiosRear',
  },
  {
    from: effects.wheelDiametersRear.loadOptions.doneData,
    fn: () => 'wheelDiametersRear',
  },
  { from: modalActions.closeWidget, fn: () => 'fitment' },
];

stepsOnActionTrigger.forEach(({ from, fn }) => {
  forward({
    from,
    to: changeStep.prepend(fn),
  });
});

const progress = combine(currentStep, dataStores.fitment, (cs, fitment) => {
  const statesToWorkWith =
    {
      'non-staggered': statesByOrder.filter(i =>
        [
          'fitment',
          'tireWidths',
          'aspectRatios',
          'wheelDiameters',
          'LTQuestion',
          'trailerQuestion',
          'zip',
        ].includes(i),
      ),
    }[fitment.current.id] || statesByOrder;
  const stepsAmount = statesToWorkWith.length;
  const doneAmount = statesToWorkWith.indexOf(cs);
  return (doneAmount / stepsAmount) * 100;
});

actions.fitment.setOptions(defaultFitmentData);

const $shouldFilterLTTires = dataStores.LTQuestion.map(
  ({ current }) => current?.id === 'yes',
);

const $shouldFilterTrailerTires = dataStores.trailerQuestion.map(
  ({ current }) => current?.id === 'yes',
);

sample({
  source: currentStep,
  filter: step => step === 'fitment',
  target: actions.fitment.setOptions.prepend(() => defaultFitmentData),
});

split({
  source: actions.wheelDiameters.confirm,
  match: dataStores.fitment.map(({ current }) => current.id),
  cases: {
    staggered: actions.wheelDiameters.moveToRear,
    __: actions.wheelDiameters.getPlyTypes,
  },
});

forward({
  from: [actions.LTQuestion.back, actions.trailerQuestion.back],
  to: changeStep.prepend(() => 'wheelDiameters'),
});

split({
  source: actions.zip.back,
  match: {
    moveToDiametersRear: dataStores.fitment.map(
      ({ current }) => current.id === 'staggered',
    ),
    moveToLTQuestion: $shouldAskLTQuestion,
    moveToTrailerQuestion: $shouldAskTrailerQuestion,
  },
  cases: {
    moveToLTQuestion: actions.wheelDiameters.moveToLTQuestion,
    moveToTrailerQuestion: actions.wheelDiameters.moveToTrailerQuestion,
    moveToDiametersRear: changeStep.prepend(() => 'wheelDiametersRear'),
    __: changeStep.prepend(() => 'wheelDiameters'),
  },
});

sample({
  clock: merge([
    actions.tireWidths.back,
    actions.aspectRatios.back,
    actions.wheelDiameters.back,
    actions.tireWidthsRear.back,
    actions.aspectRatiosRear.back,
    actions.wheelDiametersRear.back,
  ]),
  source: { currentStep },
  fn: data =>
    ({
      tireWidths: 'fitment',
      aspectRatios: 'tireWidths',
      wheelDiameters: 'aspectRatios',
      tireWidthsRear: 'wheelDiameters',
      aspectRatiosRear: 'tireWidthsRear',
      wheelDiametersRear: 'aspectRatiosRear',
    }[data.currentStep]),
  target: changeStep,
});

forward({
  from: actions.fitment.back,
  to: modalActions.showFilters,
});

forward({
  from: [modalActions.showFilters, modalActions.closeWidget],
  to: actions.tireWidths.reset,
});

sample({
  clock: actions.wheelDiameters.getPlyTypes,
  source: [
    dataStores.tireWidths,
    dataStores.aspectRatios,
    dataStores.wheelDiameters,
  ],
  fn: ([tireWidths, aspectRatios, wheelDiameters]) => ({
    tire_width: tireWidths.current.value,
    aspect_ratio: aspectRatios.current.value,
    wheel_diameter: wheelDiameters.current.value,
  }),
  target: effects.wheelDiameters.getPlyTypesFx.use(api.getPlyTypes),
});

forward({
  from: effects.wheelDiameters.getPlyTypesFx.doneData,
  to: $plyTypes,
});

forward({
  from: effects.wheelDiameters.getPlyTypesFx.fail,
  to: actions.wheelDiameters.moveToZip,
});

split({
  source: effects.wheelDiameters.getPlyTypesFx.doneData,
  match: {
    moveToLTQuestion: $shouldAskLTQuestion,
    moveToTrailerQuestion: $shouldAskTrailerQuestion,
    moveToZip: $shouldNotAskLTQuestion,
  },
  cases: {
    moveToLTQuestion: actions.wheelDiameters.moveToLTQuestion,
    moveToTrailerQuestion: actions.wheelDiameters.moveToTrailerQuestion,
    moveToZip: actions.wheelDiameters.moveToZip,
  },
});

sample({
  clock: actions.tireWidths.setValue,
  source: dataStores.tireWidths,
  fn: ({ current: { id } }) => ({
    url: '/size/tires/aspect_ratios',
    params: { tire_width_id: id },
  }),
  target: effects.aspectRatios.loadOptions,
});

sample({
  clock: actions.aspectRatios.setValue,
  source: [dataStores.tireWidths, dataStores.aspectRatios],
  fn: ([tireWidths, aspectRatios]) => ({
    url: '/size/tires/wheel_diameters',
    params: {
      tire_width_id: tireWidths.current.id,
      aspect_ratio_id: aspectRatios.current.id,
    },
  }),
  target: effects.wheelDiameters.loadOptions,
});

sample({
  clock: actions.zip.confirm,
  source: dataStores.zip,
  fn: ({ current: { value } }) => ({
    zip: value,
  }),
  target: effects.zip.setZip.use(api.setZip),
});

split({
  source: effects.zip.setZip.finally,
  match: dataStores.fitment.map(({ current }) => current.id),
  cases: {
    staggered: actions.wheelDiametersRear.viewResult,
    __: actions.wheelDiameters.viewResult,
  },
});

sample({
  clock: actions.wheelDiameters.viewResult,
  source: {
    tireWidths: dataStores.tireWidths,
    aspectRatios: dataStores.aspectRatios,
    wheelDiameters: dataStores.wheelDiameters,
    LTQuestion: dataStores.LTQuestion,
    shouldFilterLTTires: $shouldFilterLTTires,
    shouldFilterTrailerTires: $shouldFilterTrailerTires,
    LTNames: $LTNames,
  },
  fn: ({
    tireWidths,
    aspectRatios,
    wheelDiameters,
    shouldFilterLTTires,
    shouldFilterTrailerTires,
    LTNames,
  }) => {
    const params = {
      tire_width: tireWidths.current.value,
      aspect_ratio: aspectRatios.current.value,
      wheel_diameter: wheelDiameters.current.value,
    };

    if (shouldFilterLTTires) {
      params.search = {
        taxon_filter: {
          Ply: LTNames,
        },
      };
    }
    if (shouldFilterTrailerTires) {
      params.search = {
        taxon_filter: {
          Trailer: ['Yes'],
        },
      };
    }

    const query = qs.stringify(params, {
      arrayFormat: 'brackets',
      addQueryPrefix: true,
    });
    return query;
  },
  target: createEffect().use(
    query => (window.location.href = `${window.location.origin}/size${query}`),
  ),
});

sample({
  clock: actions.tireWidthsRear.setValue,
  source: dataStores.tireWidthsRear,
  fn: ({ current: { id } }) => ({
    url: '/size/tires/aspect_ratios',
    params: { tire_width_id: id },
  }),
  target: effects.aspectRatiosRear.loadOptions,
});

sample({
  clock: actions.aspectRatiosRear.setValue,
  source: [dataStores.tireWidthsRear, dataStores.aspectRatiosRear],
  fn: ([tireWidthsRear, aspectRatiosRear]) => ({
    url: '/size/tires/wheel_diameters',
    params: {
      tire_width_id: tireWidthsRear.current.id,
      aspect_ratio_id: aspectRatiosRear.current.id,
    },
  }),
  target: effects.wheelDiametersRear.loadOptions,
});

sample({
  clock: actions.wheelDiametersRear.viewResult,
  source: dataStores,
  fn: ({
    tireWidths,
    aspectRatios,
    wheelDiameters,
    tireWidthsRear,
    aspectRatiosRear,
    wheelDiametersRear,
  }) => {
    const query = {
      tire_width: tireWidths.current.value,
      aspect_ratio: aspectRatios.current.value,
      wheel_diameter: wheelDiameters.current.value,
      rear_width: tireWidthsRear.current.value,
      rear_aspect: aspectRatiosRear.current.value,
      rear_diameter: wheelDiametersRear.current.value,
    };
    const searchParams = new URLSearchParams(query);
    return `staggered-size?${searchParams}`;
  },
  target: createEffect().use(
    url => (window.location.href = `${window.location.origin}/${url}`),
  ),
});

sample({
  clock: [
    actions.wheelDiameters.viewResult,
    actions.wheelDiametersRear.viewResult,
    actions.wheelDiameters.getPlyTypes,
  ],
  fn: () => true,
  target: isLoading,
});

sample({
  clock: effects.aspectRatios.loadOptions.doneData,
  filter: params => params.length === 1,
  target: actions.aspectRatios.setValue.prepend(options => options[0]),
});

sample({
  clock: effects.wheelDiameters.getPlyTypesFx.finally,
  fn: () => false,
  target: isLoading,
});

const currentStaticData = combine(
  currentStep,
  dataStores.fitment,
  (step, fitment) =>
    (fitment.current.id === 'staggered'
      ? staticData.size.tires.staggered
      : staticData.size.tires.single)[step],
);

actions.LTQuestion.setOptions([
  { id: 'yes', value: 'Yes' },
  { id: 'no', value: 'No' },
]);

actions.trailerQuestion.setOptions([
  { id: 'no', value: 'No' },
  { id: 'yes', value: "Yes, I'm shopping for trailer tires" },
]);

const sizesDataToRender = allData.map(data =>
  [
    'tireWidths',
    'aspectRatios',
    'wheelDiameters',
    'tireWidthsRear',
    'aspectRatiosRear',
    'wheelDiametersRear',
  ]
    .map(name => ({
      name,
      onClick: () => changeStep(name),
      value: data[name].current.value,
    }))
    .filter(({ value }) => Boolean(value)),
);

const frontSizeDataToRender = sizesDataToRender.map(items =>
  items.filter(({ name }) =>
    ['tireWidths', 'aspectRatios', 'wheelDiameters'].includes(name),
  ),
);

const showSeparatorIcon = frontSizeDataToRender.map(
  items => items.length === 3,
);

const rearSizeDataToRender = sizesDataToRender.map(items =>
  items.filter(({ name }) =>
    ['tireWidthsRear', 'aspectRatiosRear', 'wheelDiametersRear'].includes(name),
  ),
);

export { actions };

const stores = {
  data: allData,
  currentStep,
  isLoading,
  progress,
  dataByCurrentStep: currentData,
  actionsByCurrentStep: currentActions,
  staticDataByCurrentStep: currentStaticData,
  frontSizeDataToRender,
  rearSizeDataToRender,
};

export const store = combine(stores);
