import {
  createStore,
  createEvent,
  combine,
  createEffect,
  forward,
  sample,
  split,
} from 'effector';
import { createForm } from 'effector-forms';
import { getErrors } from 'src/effector/forms/helpers';
import * as api from 'src/logic/api';
import isEmpty from 'lodash/isEmpty';

import rules from 'src/effector/forms/rules';

const shipmentIssueState = [
  {
    value: 'Product Damaged on Delivery',
    form: 'damagedProduct',
  },
  {
    value: 'Wrong Item Shipped',
    form: 'wrongItem',
  },
  {
    value: 'Item is Missing from Order',
    form: 'missingItem',
  },
  {
    value: 'Received Extra Item(s)',
    form: 'extraItems',
  },
];

const orderVerificationForm = createForm({
  fields: {
    orderNumber: {
      init: '',
      rules: [rules.required()],
    },
    email: {
      init: '',
      rules: [rules.email()],
    },
  },
});

const extraItemsForm = createForm({
  fields: {
    originallyItemsQuantity: {
      init: 0,
    },
    receivedItemsQuantity: {
      init: 0,
    },
    requiredImages: {
      init: {},
      rules: [rules.required()],
    },
    additionalImages: {
      init: {},
    },
  },
});

const damagedProductForm = createForm({
  fields: {
    damagedProduct: {
      init: '',
      rules: [rules.required()],
    },
    describeDamage: {
      init: '',
      rules: [
        {
          name: 'required',
          validator: (value, { damagedProduct }) => {
            const needToValidate = !isEmpty(damagedProduct);
            return {
              isValid: !needToValidate ? true : !isEmpty(value),
              errorText: 'This field is required.',
            };
          },
        },
      ],
    },
    requiredImages: {
      init: {},
      rules: [
        {
          name: 'required',
          validator: (value, { damagedProduct }) => {
            const needToValidate = !isEmpty(damagedProduct);
            const hasTwoImages = Object.keys(value).length === 2;
            const hasOneImage = !isEmpty(value);
            const rule = damagedProduct === 'TPMS' ? hasOneImage : hasTwoImages;
            return {
              isValid: !needToValidate ? true : rule,
              errorText: 'This field is required.',
            };
          },
        },
      ],
    },
    additionalImages: {
      init: {},
    },
    damagedQuantity: {
      init: 0,
    },
  },
});

const missingItemForm = createForm({
  fields: {
    pickedById: {
      init: {},
      rules: [
        {
          name: 'required',
          validator: value => ({
            isValid: Object.values(value).some(Boolean),
            errorText: 'Please pick at least one line item',
          }),
        },
      ],
    },
    quantityById: {
      init: {},
    },
  },
});

const wrongItemForm = createForm({
  fields: {
    incorrectItem: {
      init: '',
      rules: [rules.required()],
    },
    requiredImages: {
      init: {},
      rules: [
        {
          name: 'required',
          validator: (value, { incorrectItem }) => {
            const needToValidate = !isEmpty(incorrectItem);
            return {
              isValid: !needToValidate ? true : !isEmpty(value),
              errorText: 'This field is required.',
            };
          },
        },
      ],
    },
    additionalImages: {
      init: {},
    },
    incorrectType: {
      init: {},
      rules: [
        {
          name: 'lineItemsToRender',
          validator: (value, { incorrectItem }) => {
            const needToValidate = !isEmpty(incorrectItem);
            return {
              isValid: !needToValidate
                ? true
                : Object.values(value).some(Boolean),
              errorText: 'Please pick at least one item',
            };
          },
        },
      ],
    },
    incorrectQuantity: {
      init: 0,
    },
  },
});

const createShipmentIssue = createEvent();
const createShipmentIssueFx = createEffect().use(api.createIssue);
const $isLoadingCreateShipmentIssue = createShipmentIssueFx.pending;

const $showSuccessMessage = createStore(false).on(
  createShipmentIssueFx.doneData,
  () => true,
);
const $showShipmentIssuesForm = createStore(true).on(
  createShipmentIssueFx.doneData,
  () => false,
);

const verifyOrder = createEvent();
const verifyOrderFx = createEffect().use(api.orderVerification);
const $isLoadingVerifyOrder = verifyOrderFx.pending;
const submitOrderVerificationForm = createEvent();
const $orderVerificationFormServerError = createStore(null).on(
  verifyOrderFx.failData,
  (_, { response }) => response.data.error_message,
);
const $isLoading = combine(
  [$isLoadingCreateShipmentIssue, $isLoadingVerifyOrder],
  loadings => loadings.some(Boolean),
);

const $orderVerificationFormErrors = getErrors(
  Object.keys(orderVerificationForm.fields),
  orderVerificationForm,
);
const $damagedProductFormErrors = getErrors(
  Object.keys(damagedProductForm.fields),
  damagedProductForm,
);
const $extraItemsFormErrors = getErrors(
  Object.keys(extraItemsForm.fields),
  extraItemsForm,
);
const $wrongItemFormErrors = getErrors(
  Object.keys(wrongItemForm.fields),
  wrongItemForm,
);
const $missingItemFormErrors = getErrors(
  Object.keys(missingItemForm.fields),
  missingItemForm,
);

forward({
  from: submitOrderVerificationForm,
  to: orderVerificationForm.validate,
});

forward({
  from: orderVerificationForm.formValidated,
  to: verifyOrder,
});

sample({
  clock: verifyOrder,
  source: orderVerificationForm.$values,
  fn: ({ orderNumber, email }) => ({
    order_number: orderNumber,
    email,
  }),
  target: verifyOrderFx,
});

const setSelectedShipmentIssue = createEvent();
const $selectedShipmentIssue = createStore(null).on(
  setSelectedShipmentIssue,
  (_, data) => data,
);
const $showShipmentIssueSelect = createStore(false);
const $shipmentIssueOptions = createStore(shipmentIssueState);

sample({
  clock: verifyOrderFx.doneData,
  fn: () => true,
  target: $showShipmentIssueSelect,
});

const $order = createStore({ line_items: [] }).on(
  verifyOrderFx.doneData,
  (_, data) => data,
);
const $lineItems = $order.map(({ line_items }) =>
  line_items.filter(({ product_type }) =>
    ['wheel', 'tire'].includes(product_type),
  ),
);
const $lineItemsToRender = combine(
  {
    lineItems: $lineItems,
    missingItemForm: missingItemForm.$values,
  },
  ({ lineItems, missingItemForm: { pickedById, quantityById } }) =>
    lineItems.map(item => ({
      ...item,
      isSelected: pickedById[item.id] || false,
      quantityChoosen: quantityById[item.id] || 1,
      onChangeQuantity: (id, qty) =>
        missingItemForm.fields.quantityById.onChange({
          ...quantityById,
          [id]: qty,
        }),
      onSelect: id =>
        missingItemForm.fields.pickedById.onChange({
          ...pickedById,
          [id]: !pickedById[item.id],
        }),
    })),
);

$orderVerificationFormServerError.reset(orderVerificationForm.$values.updates);
$selectedShipmentIssue.reset(orderVerificationForm.$values.updates);
$showShipmentIssueSelect.reset(orderVerificationForm.$values.updates);
forward({
  from: [orderVerificationForm.$values.updates, setSelectedShipmentIssue],
  to: [
    extraItemsForm.reset,
    missingItemForm.reset,
    damagedProductForm.reset,
    wrongItemForm.reset,
  ],
});

forward({
  from: wrongItemForm.fields.incorrectItem.onChange,
  to: [
    wrongItemForm.fields.incorrectType.reset,
    wrongItemForm.fields.incorrectType.resetErrors,
    wrongItemForm.fields.requiredImages.reset,
    wrongItemForm.fields.requiredImages.resetErrors,
    wrongItemForm.fields.additionalImages.reset,
  ],
});

forward({
  from: damagedProductForm.fields.damagedProduct.onChange,
  to: [
    damagedProductForm.fields.describeDamage.reset,
    damagedProductForm.fields.describeDamage.resetErrors,
    damagedProductForm.fields.requiredImages.reset,
    damagedProductForm.fields.requiredImages.resetErrors,
    damagedProductForm.fields.additionalImages.reset,
    damagedProductForm.fields.damagedQuantity.reset,
  ],
});

const addAdditionalImage = createEvent();
const $counter = createStore(0)
  .on(addAdditionalImage, value => value + 1)
  .reset([
    damagedProductForm.fields.damagedProduct.onChange,
    wrongItemForm.fields.incorrectItem.onChange,
    setSelectedShipmentIssue,
  ]);
const $additionalImagesForRender = createStore({}).reset([
  damagedProductForm.fields.damagedProduct.onChange,
  wrongItemForm.fields.incorrectItem.onChange,
  setSelectedShipmentIssue,
]);

sample({
  clock: addAdditionalImage,
  source: [$counter, $additionalImagesForRender],
  fn: ([counter, additionalImagesForRender]) => ({
    ...additionalImagesForRender,
    [`additional_${counter}`]: '',
  }),
  target: $additionalImagesForRender,
});

const prepareExtraItemsParams = createEvent();
const prepareDamagedProductParams = createEvent();
const prepareMissingItemParams = createEvent();
const prepareWrongItemParams = createEvent();

const submitForm = createEvent();

split({
  source: $selectedShipmentIssue,
  clock: submitForm,
  match: selectedShipmentIssue => selectedShipmentIssue?.form || null,
  cases: {
    extraItems: extraItemsForm.validate,
    damagedProduct: damagedProductForm.validate,
    missingItem: missingItemForm.validate,
    wrongItem: wrongItemForm.validate,
  },
});

forward({
  from: [
    extraItemsForm.formValidated,
    damagedProductForm.formValidated,
    missingItemForm.formValidated,
    wrongItemForm.formValidated,
  ],
  to: createShipmentIssue,
});

split({
  source: $selectedShipmentIssue,
  clock: createShipmentIssue,
  match: selectedShipmentIssue => selectedShipmentIssue?.form || null,
  cases: {
    extraItems: prepareExtraItemsParams,
    damagedProduct: prepareDamagedProductParams,
    missingItem: prepareMissingItemParams,
    wrongItem: prepareWrongItemParams,
  },
});

sample({
  clock: prepareExtraItemsParams,
  source: [$selectedShipmentIssue, extraItemsForm.$values, $order],
  fn: ([selectedShipmentIssue, extraItemsFormValues, order]) => ({
    files: {
      ...extraItemsFormValues.requiredImages,
      ...extraItemsFormValues.additionalImages,
    },
    originally_items_quantity: extraItemsFormValues.originallyItemsQuantity,
    received_items_quantity: extraItemsFormValues.receivedItemsQuantity,
    shipment_issue: selectedShipmentIssue.value,
    order,
  }),
  target: createShipmentIssueFx,
});

sample({
  clock: prepareDamagedProductParams,
  source: [$selectedShipmentIssue, damagedProductForm.$values, $order],
  fn: ([selectedShipmentIssue, damagedProductFormValues, order]) => ({
    files: {
      ...damagedProductFormValues.requiredImages,
      ...damagedProductFormValues.additionalImages,
    },
    damaged_product: damagedProductFormValues.damagedProduct,
    describe_damage: damagedProductFormValues.describeDamage,
    damaged_quantity: damagedProductFormValues.damagedQuantity,
    shipment_issue: selectedShipmentIssue.value,
    order,
  }),
  target: createShipmentIssueFx,
});

sample({
  clock: prepareMissingItemParams,
  source: [$selectedShipmentIssue, $lineItemsToRender, $order],
  fn: ([selectedShipmentIssue, lineItemsToRender, order]) => ({
    not_delivered_items: lineItemsToRender
      .filter(({ isSelected }) => isSelected)
      .map(i => ({
        id: i.id,
        name: i.name,
        quantity: i.quantityChoosen,
      })),
    shipment_issue: selectedShipmentIssue.value,
    order,
  }),
  target: createShipmentIssueFx,
});

sample({
  clock: prepareWrongItemParams,
  source: [$selectedShipmentIssue, wrongItemForm.$values, $order],
  fn: ([selectedShipmentIssue, wrongItemFormValues, order]) => ({
    files: {
      ...wrongItemFormValues.requiredImages,
      ...wrongItemFormValues.additionalImages,
    },
    incorrect_item: wrongItemFormValues.incorrectItem,
    incorrect_type: Object.keys(wrongItemFormValues.incorrectType).filter(
      k => wrongItemFormValues.incorrectType[k] === true,
    ),
    incorrect_quantity: wrongItemFormValues.incorrectQuantity,
    shipment_issue: selectedShipmentIssue.value,
    order,
  }),
  target: createShipmentIssueFx,
});

export const stores = {
  $orderVerificationFormValues: orderVerificationForm.$values,
  $orderVerificationFormErrors,
  $orderVerificationFormServerError,
  $extraItemsFormValues: extraItemsForm.$values,
  $extraItemsFormErrors,
  $damagedProductFormValues: damagedProductForm.$values,
  $damagedProductFormErrors,
  $missingItemFormValues: missingItemForm.$values,
  $missingItemFormErrors,
  $wrongItemFormValues: wrongItemForm.$values,
  $wrongItemFormErrors,
  $isValidOrderVerificationForm: orderVerificationForm.$isValid,
  $showShipmentIssueSelect,
  $shipmentIssueOptions,
  $selectedShipmentIssue,
  $order,
  $showSuccessMessage,
  $showShipmentIssuesForm,
  $lineItemsToRender,
  $isLoading,
  $counter,
  $additionalImagesForRender,
};

export const actions = {
  orderVerificationFormActions: orderVerificationForm,
  extraItemsFormActions: extraItemsForm,
  damagedProductFormActions: damagedProductForm,
  wrongItemFormActions: wrongItemForm,
  submitOrderVerificationForm,
  setSelectedShipmentIssue,
  createShipmentIssue,
  submitForm,
  addAdditionalImage,
};

export const store = combine(stores);
