import { createDomain, forward, combine, sample } from 'effector';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import mapValues from 'lodash/mapValues';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import flatten from 'lodash/flatten';
import values from 'lodash/values';
import { generateData } from 'src/effector/searchWidget/models/utils';
import http from 'src/logic/http';

const modelsDomain = createDomain('models');

const states = {
  finishes: {
    actions: ['reset', 'setValue', 'setOptions', 'back'],
    effects: ['loadOptions'],
  },
  diameters: {
    actions: ['setValue', 'setOptions', 'reset', 'back'],
    effects: ['loadOptions'],
    resetOn: ['finishes'],
  },
  widths: {
    actions: ['setValue', 'setOptions', 'reset', 'back'],
    effects: ['loadOptions'],
    resetOn: ['diameters'],
  },
  boltPatterns: {
    actions: ['setValue', 'setOptions', 'reset', 'back'],
    effects: ['loadOptions'],
    resetOn: ['widths'],
  },
  offsets: {
    actions: ['setValue', 'setOptions', 'reset', 'back'],
    effects: ['loadOptions'],
    resetOn: ['boltPatterns'],
  },
  sizes: {
    actions: ['setValue', 'setOptions', 'back', 'reset', 'confirm'],
    effects: ['loadOptions'],
  },
};

const { stores: modelsStores, actions: modelsActions, effects } = generateData(
  states,
);

const viewAll = modelsDomain.createEvent();
const paginateFinishes = modelsDomain.createEvent();
const setBrand = modelsDomain.createEvent();
const setModel = modelsDomain.createEvent();
const getProduct = modelsDomain.createEvent();
const showErrors = modelsDomain.createEvent();
const setVisibleFinishes = modelsDomain.createEvent();

const $visibleFinishes = modelsDomain
  .createStore([])
  .on(setVisibleFinishes, (_, data) => data)
  .on(paginateFinishes, (finishes, number) => finishes.slice(0, number));

const $showButtonViewAll = combine(
  $visibleFinishes,
  modelsStores.finishes,
  (visibleFinishes, finishes) =>
    !isEqual(visibleFinishes.length, finishes.options.length),
);

const $model = modelsDomain.createStore({}).on(setModel, (_, model) => model);
const $brand = modelsDomain.createStore({}).on(setBrand, (_, brand) => brand);

const setFinishOptions = modelsActions.finishes.setOptions.prepend(options => {
  const groupedOptionsById = groupBy(options, item => item.id);
  const preparedText = mapValues(groupedOptionsById, val => {
    if (val.length === 1) {
      return val.map(item => ({ ...item, text: item.name }));
    }
    return val.map((item, index) => ({
      ...item,
      text: `${item.name} (${index + 1})`,
    }));
  });
  return flatten(values(preparedText));
});

sample({
  clock: viewAll,
  source: modelsStores.finishes,
  fn: ({ options }) => options,
  target: setVisibleFinishes,
});

sample({
  clock: setFinishOptions,
  source: modelsStores.finishes,
  filter: ({ options }) => !isEmpty(options),
  fn: ({ options }) => options,
  target: [
    modelsActions.finishes.setValue.prepend(
      options => options.find(item => item.in_stock) || {},
    ),
    setVisibleFinishes,
  ],
});

sample({
  clock: [modelsActions.finishes.setValue, $model, $brand],
  source: { finishes: modelsStores.finishes, model: $model, brand: $brand },
  filter: ({ model, brand, finishes }) =>
    model.id && brand.id && finishes.current.id,
  fn: ({ finishes, model, brand }) => ({
    url: '/wheels/brands/models/sizes',
    params: {
      finish_id: finishes.current.id,
      brand_id: brand.id,
      model_id: model.id,
      image: finishes.current.image,
    },
  }),
  target: effects.sizes.loadOptions,
});

sample({
  clock: modelsActions.sizes.setOptions,
  source: modelsStores.sizes,
  fn: ({ options }) =>
    sortBy(
      uniqBy(
        options.map(({ diameter, id }) => ({
          value: `${diameter}”`,
          diameter,
          id,
        })),
        'diameter',
      ),
      [item => +item.diameter],
    ),
  target: modelsActions.diameters.setOptions,
});

sample({
  clock: modelsActions.diameters.setValue,
  source: { diameters: modelsStores.diameters, sizes: modelsStores.sizes },
  fn: ({ diameters, sizes }) =>
    sortBy(
      uniqBy(
        sizes.options
          .filter(({ diameter }) => diameter === diameters.current.diameter)
          .map(item => ({
            ...item,
            value: item.in_stock
              ? `${item.width}”`
              : `${item.width}” Out Of Stock`,
          })),
        'width',
      ),
      [item => +item.value],
    ),
  target: modelsActions.widths.setOptions,
});

sample({
  clock: modelsActions.widths.setValue,
  source: {
    diameters: modelsStores.diameters,
    widths: modelsStores.widths,
    sizes: modelsStores.sizes,
  },
  fn: ({ diameters, widths, sizes }) =>
    sizes.options.find(
      ({ diameter, width }) =>
        isEqual(diameter, diameters.current.diameter) &&
        isEqual(width, widths.current.width),
    ),
  target: modelsActions.sizes.setValue,
});

sample({
  clock: modelsActions.widths.setValue,
  source: {
    finishes: modelsStores.finishes,
    model: $model,
    brand: $brand,
    sizes: modelsStores.sizes,
  },
  fn: ({ finishes, model, brand, sizes }) => {
    return {
      url: '/wheels/brands/models/bolt_patterns',
      params: {
        finish_id: finishes.current.id,
        brand_id: brand.id,
        model_id: model.id,
        size_id: sizes.current.id,
        image: finishes.current.image,
      },
    };
  },
  target: effects.boltPatterns.loadOptions,
});

forward({
  from: effects.boltPatterns.loadOptions.doneData,
  to: modelsActions.boltPatterns.setOptions.prepend(items =>
    items.map(item => ({
      ...item,
      value: item.in_stock
        ? [item.lug_name, item.bolt_pattern_name].join('x')
        : `${[item.lug_name, item.bolt_pattern_name].join('x')} Out Of Stock`,
    })),
  ),
});

sample({
  clock: modelsActions.boltPatterns.setValue,
  source: {
    finishes: modelsStores.finishes,
    model: $model,
    brand: $brand,
    sizes: modelsStores.sizes,
    boltPatterns: modelsStores.boltPatterns,
  },
  fn: ({ finishes, model, brand, sizes, boltPatterns }) => {
    return {
      url: '/wheels/brands/models/offsets',
      params: {
        finish_id: finishes.current.id,
        brand_id: brand.id,
        model_id: model.id,
        size_id: sizes.current.id,
        bolt_pattern_id: boltPatterns.current.bolt_pattern_id,
        lug_id: boltPatterns.current.lug_id,
        image: finishes.current.image,
      },
    };
  },
  target: effects.offsets.loadOptions,
});

forward({
  from: effects.offsets.loadOptions.doneData,
  to: modelsActions.offsets.setOptions.prepend(items =>
    items.map(item => ({
      ...item,
      value: item.in_stock ? `${item.name} mm` : `${item.name} mm Out Of Stock`,
    })),
  ),
});

const getProductFx = modelsDomain.createEffect().use(async params => {
  const { data } = await http.get(
    `${window.location.origin}/api/v1/search/wheels/brands/models/product`,
    {
      params,
    },
  );
  return data;
});

forward({
  from: modelsActions.offsets.setValue,
  to: getProduct,
});

sample({
  clock: getProduct,
  source: {
    finishes: modelsStores.finishes,
    sizes: modelsStores.sizes,
    boltPatterns: modelsStores.boltPatterns,
    offsets: modelsStores.offsets,
    model: $model,
    brand: $brand,
  },
  fn: ({ finishes, sizes, boltPatterns, offsets, model, brand }) => ({
    finish_id: finishes.current.id,
    size_id: sizes.current.id,
    bolt_pattern_id: boltPatterns.current.bolt_pattern_id,
    lug_id: boltPatterns.current.lug_id,
    offset_id: offsets.current.id,
    brand_id: brand.id,
    model_id: model.id,
    image: finishes.current.image,
  }),
  target: getProductFx,
});

const $product = modelsDomain
  .createStore({})
  .reset([
    modelsStores.finishes,
    modelsStores.diameters,
    modelsStores.widths,
    modelsStores.boltPatterns,
    modelsStores.offsets,
    modelsStores.sizes,
  ])
  .on(getProductFx.doneData, (_, data) => data);

const $isPresentProduct = $product.map(product => !isEmpty(product));

const $isOutOfStockProduct = $product.map(
  product => product.stock_status === 'out_of_stock',
);
const $isInStockProduct = $product.map(
  product => product.stock_status === 'in_stock',
);
const $isSeveralInStockProduct = $product.map(
  product => product.stock_status === 'several_in_stock',
);

const $isNotAvailableProduct = combine(
  $isPresentProduct,
  $isOutOfStockProduct,
  (isPresentProduct, isOutOfStockProduct) =>
    isPresentProduct && isOutOfStockProduct,
);

const $isAvailableProduct = combine(
  $isPresentProduct,
  $isInStockProduct,
  $isSeveralInStockProduct,
  (isPresentProduct, isInStockProduct, isSeveralInStockProduct) =>
    isPresentProduct && (isInStockProduct || isSeveralInStockProduct),
);

const $isLoading = getProductFx.pending;

const $isDisabledButton = combine(
  $isLoading,
  $isNotAvailableProduct,
  (isLoading, isNotAvailableProduct) => isLoading || isNotAvailableProduct,
);

const $errors = combine(
  modelsStores,
  ({ diameters, widths, boltPatterns, offsets }) => {
    const prepareMessage = key => `Please select a ${key} option.`;
    return mapValues(
      {
        diameters: {
          store: diameters,
          value: 'diameter',
          hasError: !diameters.current.id,
        },
        widths: { store: widths, value: 'width', hasError: !widths.current.id },
        boltPatterns: {
          store: boltPatterns,
          value: 'bolt pattern',
          hasError: !boltPatterns.current.bolt_pattern_id,
        },
        offsets: {
          store: offsets,
          value: 'offset',
          hasError: !offsets.current.id,
        },
      },
      value => ({
        ...value,
        message: prepareMessage(value.value),
      }),
    );
  },
);

const $isShowErrors = modelsDomain
  .createStore(false)
  .on(showErrors, (_, value) => value);

sample({
  clock: getProduct,
  fn: () => false,
  target: $isShowErrors,
});

const $isLoadingSizesOptions = effects.sizes.loadOptions.pending;
const $isLoadingOffsetsOptions = modelsDomain
  .createStore(false)
  .on(effects.offsets.loadOptions, () => true)
  .on(effects.offsets.loadOptions.done, () => false)
  .on(effects.offsets.loadOptions.fail, () => false);

const $isLoadingBoltPatternsOptions = modelsDomain
  .createStore(false)
  .on(effects.boltPatterns.loadOptions, () => true)
  .on(effects.boltPatterns.loadOptions.done, () => false)
  .on(effects.boltPatterns.loadOptions.fail, () => false);

const $isDisabledDiameterField = combine(
  $isLoadingSizesOptions,
  modelsStores.finishes,
  (isLoadingSizesOptions, finishes) =>
    isLoadingSizesOptions || !finishes.current.id,
);

const $isDisabledBoltPatternField = combine(
  $isLoadingBoltPatternsOptions,
  modelsStores.widths,
  (isLoadingBoltPatternsOptions, widths) =>
    isLoadingBoltPatternsOptions || !widths.current.id,
);

const $isDisabledOffsetField = combine(
  $isLoadingOffsetsOptions,
  modelsStores.boltPatterns,
  (isLoadingOffsetsOptions, boltPatterns) =>
    isLoadingOffsetsOptions || !boltPatterns.current.bolt_pattern_id,
);

export const stores = {
  ...modelsStores,
  $visibleFinishes,
  $showButtonViewAll,
  $model,
  $product,
  $brand,
  $isPresentProduct,
  $errors,
  $isShowErrors,
  $isLoading,
  $isNotAvailableProduct,
  $isAvailableProduct,
  $isDisabledButton,
  $isInStockProduct,
  $isOutOfStockProduct,
  $isSeveralInStockProduct,
  $isDisabledDiameterField,
  $isDisabledBoltPatternField,
  $isDisabledOffsetField,
};

export const store = combine(stores);

export const actions = {
  modelsActions,
  viewAll,
  paginateFinishes,
  setBrand,
  setModel,
  getProduct,
  showErrors,
  setFinishOptions,
};
