import { guard, sample, combine, createEffect, merge, forward } from 'effector';

import isEmpty from 'lodash/isEmpty';

import * as api from 'src/logic/api';
import axios from 'axios';
import { actions as modalActions } from 'src/effector/searchWidget/modal';
import { stores as filtersStores } from 'src/effector/searchWidget/filters';
import { generateData } from 'src/effector/searchWidget/models/utils';
import staticData from 'src/effector/searchWidget/static';

const loadAvailableSizesFx = createEffect().use(params =>
  axios.get(`${window.location.origin}/api/v1/search/available_wheel_sizes`, {
    params,
  }),
);

const states = {
  makes: {
    actions: ['reset', 'setValue', 'setOptions', 'back', 'reset'],
    effects: ['loadOptions'],
  },
  years: {
    actions: ['setValue', 'setOptions', 'back', 'reset'],
    effects: ['loadOptions'],
    resetOn: ['makes'],
  },
  models: {
    actions: ['setValue', 'setOptions', 'back', 'reset'],
    effects: ['loadOptions'],
    resetOn: ['years'],
  },
  trims: {
    actions: ['setValue', 'setOptions', 'back', 'reset'],
    effects: ['loadOptions'],
    resetOn: ['models'],
  },
  verify: {
    actions: ['setValue', 'setOptions', 'confirm', 'back', 'reset'],
    effects: ['loadOptions'],
    resetOn: ['models'],
    preset: true,
  },
  fitment: {
    actions: ['setValue', 'setOptions', 'confirm', 'back', 'reset'],
    effects: ['loadOptions'],
    resetOn: ['verify'],
    preset: true,
  },
  rim: {
    actions: ['setValue', 'setOptions', 'confirm', 'back', 'reset'],
    effects: ['loadOptions'],
    resetOn: ['fitment', 'verify'],
    preset: true,
  },
  zip: {
    actions: ['setValue', 'setOptions', 'confirm', 'back', 'reset'],
    effects: ['loadOptions', 'setZip'],
    resetOn: ['rim'],
  },
};

const {
  stores: { verify, fitment, rim, zip },
  actions,
  effects,
  helpers: {
    currentStep,
    changeStep,
    allData,
    currentData,
    currentActions,
    progress,
    isLoading,
  },
} = generateData(states);

const stepsOnActionTrigger = [
  { from: effects.years.loadOptions.doneData, fn: () => 'years' },
  { from: effects.models.loadOptions.doneData, fn: () => 'models' },
  { from: effects.trims.loadOptions.doneData, fn: () => 'trims' },
  { from: effects.verify.loadOptions.doneData, fn: () => 'verify' },
  { from: loadAvailableSizesFx.doneData, fn: () => 'rim' },
  { from: actions.rim.confirm, fn: () => 'zip' },
  { from: modalActions.closeWidget, fn: () => 'makes' },
  { from: modalActions.showFilters, fn: () => 'makes' },
];

stepsOnActionTrigger.forEach(({ from, fn }) => {
  forward({
    from,
    to: changeStep.prepend(fn),
  });
});

guard({
  source: currentStep,
  filter: step => step === 'fitment',
  target: actions.fitment.setOptions.prepend(() => [
    { id: 'non-staggered', value: 'No' },
    { id: 'staggered', value: 'Yes, I want staggered fitment' },
  ]),
});

forward({
  from: actions.makes.back,
  to: modalActions.showFilters,
});

forward({
  from: [modalActions.showFilters, modalActions.closeWidget],
  to: actions.makes.reset,
});

sample({
  clock: merge([
    actions.years.back,
    actions.models.back,
    actions.trims.back,
    actions.verify.back,
    actions.fitment.back,
    actions.zip.back,
  ]),
  source: { currentStep },
  fn: data =>
    ({
      years: 'makes',
      models: 'years',
      trims: 'models',
      verify: 'trims',
      fitment: 'verify',
      zip: 'rim',
    }[data.currentStep]),
  target: changeStep,
});

sample({
  clock: actions.rim.back,
  source: verify,
  fn: ({ current: { staggered_sizes } }) =>
    isEmpty(staggered_sizes) ? 'verify' : 'fitment',
  target: changeStep,
});

sample({
  clock: actions.makes.setValue,
  source: allData,
  fn: data => ({
    url: '/vehicle/wheels/years',
    params: { make_id: data.makes.current.id },
  }),
  target: effects.years.loadOptions,
});

sample({
  clock: actions.years.setValue,
  source: allData,
  fn: data => ({
    url: '/vehicle/wheels/models',
    params: {
      year_id: data.years.current.id,
      make_id: data.makes.current.id,
    },
  }),
  target: effects.models.loadOptions,
});

sample({
  clock: actions.models.setValue,
  source: allData,
  fn: data => ({
    url: '/vehicle/wheels/trims',
    params: {
      year_id: data.years.current.id,
      make_id: data.makes.current.id,
      model_id: data.models.current.id,
    },
  }),
  target: effects.trims.loadOptions,
});

sample({
  clock: actions.trims.setValue,
  source: allData,
  fn: data => ({
    url: '/vehicle/wheels/verify',
    params: {
      make_id: data.makes.current.id,
      year_id: data.years.current.id,
      model_id: data.models.current.id,
      trim_id: data.trims.current.id,
    },
  }),
  target: effects.verify.loadOptions,
});

sample({
  clock: actions.verify.confirm,
  source: { verify, fitment },
  filter: ({
    verify: {
      current: { staggered_sizes },
    },
  }) => isEmpty(staggered_sizes),
  fn: ({
    verify: {
      current: { sizes, fmk },
    },
  }) => ({ sizes, fmk, staggered: false }),
  target: loadAvailableSizesFx,
});

sample({
  clock: actions.fitment.confirm,
  source: { verify, fitment },
  fn: ({
    verify: {
      current: { staggered_sizes, sizes, fmk },
    },
    fitment,
  }) => {
    const isStaggered = fitment.current?.id === 'staggered';
    const data = isStaggered ? staggered_sizes : sizes;
    const params = { sizes: data, fmk, staggered: isStaggered };
    return params;
  },
  target: loadAvailableSizesFx,
});

sample({
  clock: loadAvailableSizesFx.doneData,
  fn: ({ data: { rimSizes } }) => rimSizes.map(v => ({ id: v, value: v })),
  target: actions.rim.setOptions,
});

sample({
  clock: actions.verify.confirm,
  source: verify,
  filter: ({ current: { staggered_sizes } }) => !isEmpty(staggered_sizes),
  target: changeStep.prepend(() => 'fitment'),
});

sample({
  clock: actions.zip.confirm,
  source: zip,
  fn: ({ current: { value } }) => ({
    zip: value,
  }),
  target: effects.zip.setZip.use(api.setZip),
});

sample({
  clock: effects.zip.setZip.finally,
  source: { verify, fitment, rim, productType: filtersStores.$productType },
  fn: ({ verify, fitment, rim, productType }) => {
    const query = {
      fmk: verify.current.fmk,
      size: rim.current.id.toFixed(1),
      staggered: fitment.current.id === 'staggered' ? 'Yes' : 'No',
      package: productType === 'both',
    };
    const searchParams = new URLSearchParams(query);
    return `wheels?${searchParams}`;
  },
  target: createEffect().use(
    url => (window.location.href = `${window.location.origin}/${url}`),
  ),
});

sample({
  clock: merge([actions.zip.confirm, loadAvailableSizesFx]),
  fn: () => true,
  target: isLoading,
});

sample({
  clock: loadAvailableSizesFx.finally,
  fn: () => false,
  target: isLoading,
});

const currentStaticData = combine(
  currentStep,
  step => staticData.vehicle.wheels[step],
);

const vehicleDataToRender = allData.map(data =>
  ['makes', 'years', 'models', 'trims']
    .map(name => ({
      onClick: () => changeStep(name),
      value: data[name].current.value,
    }))
    .filter(({ value }) => Boolean(value)),
);

const tireSizeToRender = allData.map(data =>
  [
    { key: 'tireSize', type: 'key' },
    { key: ' ', type: 'token' },
    { key: 'loadIndex', type: 'key' },
    { key: 'speedRating', type: 'key' },
  ]
    .map(
      item =>
        ({ token: item.key, key: data.verify.current[item.key] }[item.type]),
    )
    .join(''),
);

export { actions };

const stores = {
  data: allData,
  currentStep,
  isLoading,
  progress,
  vehicleDataToRender,
  tireSizeToRender,
  dataByCurrentStep: currentData,
  actionsByCurrentStep: currentActions,
  staticDataByCurrentStep: currentStaticData,
};

export const store = combine(stores);
