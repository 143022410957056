import {
  createEvent,
  combine,
  sample,
  createStore,
  createEffect,
  forward,
  split,
} from 'effector';
import isEmpty from 'lodash/isEmpty';
import axios from 'axios';
import qs from 'qs';

import { actions as drawerActions } from 'src/effector/drawer';

import { actions as orderActions } from 'src/effector/order';

const localeFormatterParams = { style: 'currency', currency: 'USD' };

export const actions = {
  selectItem: createEvent(),
  changeStep: createEvent(),
  getWheels: createEvent(),
  getTires: createEvent(),
  complete: createEvent(),
  selectTire: createEvent(),
  selectWheel: createEvent(),
  setWheels: createEvent(),
  setTires: createEvent(),
};

const getPackageProductsFx = createEffect().use(
  async ({ productType, params = {} }) => {
    const origin = window.location.origin;
    const parsedParams = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    const stringParams = qs.stringify(parsedParams, {
      arrayFormat: 'repeat',
      addQueryPrefix: true,
    });

    const { data } = await axios.get(
      `${origin}/api/v1/package_discounts/${productType}${stringParams}`,
      {
        params: Object.assign(params),
      },
    );

    return { data, productType };
  },
);

const $selectedTire = createStore({}).reset(drawerActions.close);

const $selectedWheel = createStore({}).reset(drawerActions.close);

const $totalPriceData = createStore({}).reset(drawerActions.close);

const $wheelsToRender = createStore([]).reset(drawerActions.close);

const $tiresToRender = createStore([]).reset(drawerActions.close);

const $currentStep = createStore('wheels').reset(drawerActions.close);

sample({
  clock: actions.changeStep,
  target: $currentStep,
});

sample({
  clock: actions.complete,
  source: [$selectedWheel, $selectedTire],
  fn: products =>
    products.map(el => ({
      variant_id: el.id,
      quantity: el.quantity,
    })),
  target: orderActions.createLineItem,
});

sample({
  clock: actions.selectTire,
  source: [$selectedWheel, $selectedTire],
  fn: ([wheel, tire]) => {
    const total = wheel.price * wheel.quantity + tire.price * tire.quantity;

    return {
      total: total.toLocaleString('en-US', localeFormatterParams),
      rebateSavings: wheel.rebate.unformatted_price.toLocaleString(
        'en-US',
        localeFormatterParams,
      ),
      totalWithRebates: (total - wheel.rebate.unformatted_price).toLocaleString(
        'en-US',
        localeFormatterParams,
      ),
    };
  },
  target: $totalPriceData,
});

forward({
  from: actions.getWheels,
  to: getPackageProductsFx.prepend(params => ({
    productType: 'wheels',
    params,
  })),
});

forward({
  from: actions.getTires,
  to: getPackageProductsFx.prepend(params => ({
    productType: 'tires',
    params,
  })),
});

split({
  source: getPackageProductsFx.doneData,
  match: ({ productType }) => productType,
  cases: {
    wheels: actions.setWheels.prepend(({ data }) => data),
    tires: actions.setTires.prepend(({ data }) => data),
  },
});

forward({
  from: actions.setWheels,
  to: $wheelsToRender,
});

forward({
  from: actions.setTires,
  to: $tiresToRender,
});

forward({
  from: actions.selectTire,
  to: $selectedTire,
});

forward({
  from: actions.selectWheel,
  to: $selectedWheel,
});

const $isLoading = combine(
  [orderActions.createLineItemFx.pending, getPackageProductsFx.pending],
  loadingStates => loadingStates.some(Boolean),
);

const $selectedProductsToRender = combine(
  [$selectedWheel, $selectedTire],
  ([wheel, tire]) => [
    {
      ...wheel,
      isWheel: true,
      isEmpty: isEmpty(wheel),
      formattedPrice:
        !isEmpty(wheel) &&
        (wheel.price * wheel.quantity).toLocaleString(
          'en-US',
          localeFormatterParams,
        ),
    },
    {
      ...tire,
      isTire: true,
      isEmpty: isEmpty(tire),
      formattedPrice:
        !isEmpty(tire) &&
        (tire.price * tire.quantity).toLocaleString(
          'en-US',
          localeFormatterParams,
        ),
    },
  ],
);

export const stores = {
  $selectedTire,
  $selectedWheel,
  $currentStep,
  $wheelsToRender,
  $tiresToRender,
  $totalPriceData,
  $selectedProductsToRender,
  $isLoading,
};

export const store = combine(stores);
