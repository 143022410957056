import { combine } from 'effector';
import { assoc } from 'rambda';
import mapValues from 'lodash/mapValues';
import get from 'lodash/get';

export const getErrors = (names, form) => {
  const errors = names.reduce(
    (acc, name) => assoc(name, form.fields[name].$firstError, acc),
    [],
  );

  return combine(errors, a => mapValues(a, v => get(v, 'errorText')));
};
export default getErrors;
