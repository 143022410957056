import {
  createStore,
  combine,
  createEvent,
  createEffect,
  forward,
  sample,
} from 'effector';

import { assoc, mergeAll } from 'rambda';
import mapValues from 'lodash/mapValues';
import keys from 'lodash/keys';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';

import axios from 'axios';

const fetchData = async ({ url, params }) => {
  const { data } = await axios.get(url, {
    params,
    baseURL: `${window.location.origin}/api/v1/search`,
  });

  return data;
};

export const preloadFx = createEffect().use(fetchData);

export const generateData = states => {
  const statesByOrder = keys(states);

  const actions = mapValues(states, props =>
    mergeAll(
      props.actions.map(actionName => assoc(actionName, createEvent(), {})),
    ),
  );

  const effects = mapValues(states, props =>
    mergeAll(
      props.effects.map(effectName =>
        assoc(effectName, createEffect().use(fetchData), {}),
      ),
    ),
  );

  const emptyStores = mergeAll(
    keys(states).map(storeName =>
      assoc(storeName, createStore({ current: {}, options: [] }), {}),
    ),
  );

  const stores = mapValues(emptyStores, (storeData, storeName) =>
    storeData
      .on(actions[storeName].setValue, (o, v) => assoc('current', v, o))
      .on(actions[storeName].setOptions, (o, v) => assoc('options', v, o))
      .on(actions[storeName].back, o => assoc('current', {}, o))
      .on(actions[storeName].reset || createEvent(), o =>
        assoc('current', {}, o),
      ),
  );

  forEach(statesByOrder, storeName =>
    forward({
      from: states[storeName].resetOn?.map(n => stores[n]) || createEvent(),
      to: actions[storeName].reset,
    }),
  );

  forEach(statesByOrder, storeName =>
    forward({
      from: effects[storeName].loadOptions.doneData,
      to: actions[storeName].setOptions,
    }),
  );

  const changeStep = createEvent();
  const currentStep = createStore(statesByOrder[0]).on(changeStep, (_, v) => v);
  const allData = combine(stores, obj => obj);
  const currentData = combine(allData, currentStep, (data, step) => data[step]);
  const currentActions = combine(currentStep, step => actions[step]);

  forEach(
    statesByOrder.filter(name => states[name].preset),
    storeName =>
      sample({
        clock: changeStep,
        source: { currentData, currentStep },
        filter: params =>
          params.currentStep === storeName &&
          params.currentData.options.length === 1 &&
          isEmpty(params.currentData.current),
        fn: params => params.currentData.options[0],
        target: actions[storeName].setValue,
      }),
  );

  const progress = combine(currentStep, currentStep => {
    const stepsAmount = statesByOrder.length;
    const doneAmount = statesByOrder.indexOf(currentStep);
    return (doneAmount / stepsAmount) * 100;
  });

  const isLoading = combine(
    Object.values(effects).map(({ loadOptions }) => loadOptions.pending),
    args => args.some(Boolean),
  );

  return {
    stores,
    actions: { ...actions, changeStep },
    effects,
    helpers: {
      changeStep,
      currentStep,
      allData,
      currentData,
      currentActions,
      progress,
      isLoading,
      statesByOrder,
    },
  };
};
export default generateData;
