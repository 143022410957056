import { createEvent, createStore, combine, createEffect } from 'effector';
import { actions as taxonFiltersActions } from 'src/effector/products/filters/taxon';

const priceRangeMin = 0;
const priceRangeMax = 2000;
const getMinThumb = (minprice, min, max) =>
  ((minprice - min) / (max - min)) * 100;
const getMaxThumb = (maxprice, min, max) =>
  100 - ((maxprice - min) / (max - min)) * 100;

const initialPriceRange = () => ({
  min: {
    default: priceRangeMin,
    price: priceRangeMin,
    thumb: getMinThumb(priceRangeMin, priceRangeMin, priceRangeMax),
  },
  max: {
    default: priceRangeMax,
    price: priceRangeMax,
    thumb: getMaxThumb(priceRangeMax, priceRangeMin, priceRangeMax),
  },
});

const setPriceRangeFx = createEffect().use(({ minPrice, maxPrice }) => {
  const min = minPrice || priceRangeMin;
  const max = maxPrice || priceRangeMax;
  return {
    min: {
      default: priceRangeMin,
      price: min,
      thumb: getMinThumb(min, priceRangeMin, priceRangeMax),
    },
    max: {
      default: priceRangeMax,
      price: max,
      thumb: getMaxThumb(max, priceRangeMin, priceRangeMax),
    },
  };
});

const clearPriceRange = createEvent();
const minTrigger = createEvent();
const maxTrigger = createEvent();

const $priceRange = createStore(initialPriceRange())
  .on([clearPriceRange, taxonFiltersActions.clearFilters], initialPriceRange)
  .on(setPriceRangeFx.doneData, (_, v) => v)
  .on(minTrigger, priceRange => {
    const minPrice = Math.min(priceRange.min.price, priceRange.max.price - 100);
    return {
      ...priceRange,
      min: {
        ...priceRange.min,
        price: minPrice,
        thumb: getMinThumb(
          minPrice,
          priceRange.min.default,
          priceRange.max.default,
        ),
      },
    };
  })
  .on(maxTrigger, priceRange => {
    const maxPrice = Math.max(priceRange.max.price, priceRange.min.price + 100);
    return {
      ...priceRange,
      max: {
        ...priceRange.max,
        price: maxPrice,
        thumb: getMaxThumb(
          maxPrice,
          priceRange.min.default,
          priceRange.max.default,
        ),
      },
    };
  });

const $isDefaultPricerange = $priceRange.map(
  priceRange =>
    priceRange.min.price === priceRangeMin &&
    priceRange.max.price === priceRangeMax,
);

export const stores = {
  $priceRange,
  priceRangeMin,
  priceRangeMax,
  $isDefaultPricerange,
};

export const store = combine(stores);

export const actions = {
  setPriceRangeFx,
  clearPriceRange,
  minTrigger,
  maxTrigger,
};
