import {
  combine,
  createStore,
  createEvent,
  createEffect,
  sample,
  forward,
} from 'effector';
import { createForm } from 'effector-forms';
import rules from 'src/effector/forms/rules';
import axios from 'axios';
import {
  stores as orderStores,
  actions as orderActions,
} from 'src/effector/order';
import omit from 'lodash/omit';
import startCase from 'lodash/startCase';
import mapValues from 'lodash/mapValues';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { assoc } from 'rambda';
import { persist } from 'effector-storage/local';
import {
  store as $drawerStore,
  actions as drawerActions,
} from 'src/effector/drawer';
import * as api from 'src/logic/api';

const hideDeliveryToInstaller = createEvent();
const pickFedex = createEvent();
const updateFedex = createEvent();
const pickPreferredLocation = createEvent();
const updatePreferredLocation = createEvent();
const findInstallers = createEvent();
const validatePreferredLocationForm = createEvent();
const openInstallersOverlay = createEvent();
const closeInstallersOvelay = createEvent();
const clearPreferredLocationInstaller = createEvent();

const updateFedexFx = createEffect().use(api.updateCheckoutState);
const updatePreferredLocationFx = createEffect().use(api.updateCheckoutState);

const findInstallersFx = createEffect().use(async zip => {
  const { data } = await axios.get(`/api/v1/installer_locations?zip=${zip}`);
  return data;
});

const $isLoadingInstallers = findInstallersFx.pending.map(Boolean);

const $installerLocations = createStore({ local: [], portal: [] })
  .on(findInstallersFx.doneData, (_, locations) => locations)
  .reset([updateFedexFx.doneData, clearPreferredLocationInstaller]);

const $preferredLocationAvailable = orderStores.$order.map(
  ({ available_shipping_methods = [] }) =>
    available_shipping_methods.includes('preferred'),
);

const $productToInstall = combine(
  $preferredLocationAvailable,
  orderStores.$tires,
  (preferredLocationAvailable, tires) => {
    const [product] = tires;
    if (!preferredLocationAvailable || !product) return {};
    return product;
  },
);

const $deliveryToInstallerIsHidden = createStore(false).on(
  hideDeliveryToInstaller,
  (_, s) => s,
);

const $deliveryToInstallerIsAllowed = $deliveryToInstallerIsHidden.map(a => !a);

const $deliveryMethod = createStore('fedex')
  .on(pickFedex, () => 'fedex')
  .on(pickPreferredLocation, () => 'preferredLocation');

const $isFedexPicked = $deliveryMethod.map(i => i === 'fedex');
const $isPreferredLocationPicked = $deliveryMethod.map(
  i => i === 'preferredLocation',
);

const $isOpenedInstallersOverlay = $drawerStore.map(
  ({ type, isOpened }) => type === 'delivery-installers' && isOpened,
);

const $preferredLocactionId = createStore('').reset(
  clearPreferredLocationInstaller,
);
const $preferredLocationInstaller = createStore({}).reset(
  clearPreferredLocationInstaller,
);
const $isMobileInstallerPicked = $preferredLocationInstaller.map(
  i => i.location_type === 'mobile',
);

const $isPhysicalInstallerPicked = $preferredLocationInstaller.map(
  i => i.location_type === 'physical',
);

const $preferredLocationZip = createStore('');

const $fedexForm = createForm({
  fields: {
    hold_at_location: {
      init: 0,
    },
    preferred_location: {
      init: 0,
    },
  },
});

const preferredLocationForm = createForm({
  fields: {
    hold_at_location: {
      init: 0,
    },
    preferred_location: {
      init: 1,
    },
    preferred_location_id: {
      init: '',
      units: {
        $value: $preferredLocactionId,
      },
    },
    installer: {
      init: {},
      rules: [rules.required()],
      units: {
        $value: $preferredLocationInstaller,
      },
    },
    zip: {
      init: '',
      rules: [rules.required(), rules.minLength(5)],
      units: {
        $value: $preferredLocationZip,
      },
    },
  },
});

const $isValidDeliveryForm = combine(
  {
    deliveryMethod: $deliveryMethod,
    isValidFedexForm: $fedexForm.$isValid,
    isValidPreferredLocationForm: preferredLocationForm.$isValid,
  },
  data =>
    ({
      fedex: data.isValidFedexForm,
      preferredLocation: data.isValidPreferredLocationForm,
    }[data.deliveryMethod]),
);

const $deliveryDataToSend = combine(
  {
    deliveryMethod: $deliveryMethod,
    fedexFormData: $fedexForm.$values,
    preferredLocationFormData: preferredLocationForm.$values,
  },
  ({ deliveryMethod, fedexFormData, preferredLocationFormData }) => ({
    state: 'delivery',
    order: {
      fedex: fedexFormData,
      preferredLocation: {
        local: omit(preferredLocationFormData, ['installer']),
        portal: {
          installer: {
            location_id: preferredLocationFormData.installer.id,
            location_type: preferredLocationFormData.installer.location_type,
            store_id: preferredLocationFormData.installer?.installer?.id,
            name: preferredLocationFormData.installer?.installer?.name,
            price_without_service_charge:
              preferredLocationFormData.installer.price_without_service_charge,
            price: preferredLocationFormData.installer.price,
            city: preferredLocationFormData.installer.city,
            state: preferredLocationFormData.installer.state,
            zip: preferredLocationFormData.installer.zip,
            street: preferredLocationFormData.installer.street,
            store_email: preferredLocationFormData.installer.email,
            store: preferredLocationFormData.installer.store,
            phone: preferredLocationFormData.installer.phone,
          },
          ...omit(preferredLocationFormData, [
            'installer',
            'preferred_location_id',
          ]),
        },
      }[preferredLocationFormData.installer.installerType || 'local'],
    }[deliveryMethod],
  }),
);

const transformLocationToRender = location => {
  const storeHours = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ].map(day => ({
    day: startCase(day),
    hours: `${location[`open_${day}`]} - ${location[`close_${day}`]}`,
  }));
  const { productType, distance } = location;
  return {
    ...location,
    productType: startCase(productType),
    distance: `${distance} miles away`,
    price: `$55.00`,
    hours: storeHours.map(({ day, hours }) => ({
      label: day,
      value: hours,
    })),
  };
};

const $localInstallerLocations = combine(
  $installerLocations,
  $productToInstall,
  ({ local }, product) => {
    if (isEmpty(product)) return [];
    return local.map(location => ({
      ...location,
      installerType: 'local',
    }));
  },
);

const $localInstallerLocationsToRender = $localInstallerLocations.map(
  locations => locations.map(transformLocationToRender),
);

const $portalInstallerLocations = combine(
  {
    locations: $installerLocations,
    product: $productToInstall,
  },
  ({ locations: { portal }, product }) => {
    if (!product) return [];
    return portal;
  },
);

const $portalInstallerLocationsPresent = $portalInstallerLocations.map(
  p => !isEmpty(p),
);
const $installersAddressByZip = $installerLocations.map(
  ({ state, city, zip }) => [city, state, zip].join(', '),
);

sample({
  clock: orderActions.createLineItemFx.done,
  filter: ({ params }) =>
    params.find(({ type }) => ['tire', 'wheel'].includes(type)),
  target: clearPreferredLocationInstaller,
});

sample({
  clock: orderActions.destroyLineItemFx.done,
  filter: ({ params: { type } }) =>
    ['installation', 'tire', 'wheel'].includes(type),
  target: clearPreferredLocationInstaller,
});

sample({
  clock: preferredLocationForm.fields.installer.onChange,
  fn: location => location.id,
  target: preferredLocationForm.fields.preferred_location_id.onChange,
});

sample({
  clock: sample({
    clock: findInstallers,
    target: preferredLocationForm.validate,
  }),
  source: {
    formValues: preferredLocationForm.$values,
    isValidZip: preferredLocationForm.fields.zip.$isValid,
  },
  filter: ({ isValidZip }) => isValidZip,
  fn: ({ formValues: { zip } }) => zip,
  target: findInstallersFx,
});

forward({
  from: pickPreferredLocation,
  to: openInstallersOverlay,
});

forward({
  from: updateFedexFx.doneData,
  to: preferredLocationForm.reset,
});

forward({
  from: openInstallersOverlay,
  to: drawerActions.open.prepend(() => ({
    type: 'delivery-installers',
    origin: 'right',
  })),
});

forward({
  from: closeInstallersOvelay,
  to: drawerActions.close.prepend(() => ({})),
});

const fieldNames = ['zip', 'installer'];

const preferredLocationFormErrors = fieldNames.reduce(
  (acc, name) =>
    assoc(name, preferredLocationForm.fields[name].$firstError, acc),
  {},
);

sample({
  source: $productToInstall,
  fn: productToInstall => {
    if (isEmpty(productToInstall)) return 'fedex';
  },
  target: $deliveryMethod,
});

const $availableShipToInstallers = $productToInstall.map(
  value => !isEmpty(value),
);

const $canShowShipToInstaller = combine(
  [$availableShipToInstallers, $deliveryToInstallerIsAllowed],
  s => s.every(Boolean),
);

const $preferredLocationFormErrors = combine(preferredLocationFormErrors, a =>
  mapValues(a, v => get(v, 'errorText')),
);

export const stores = {
  $fedexForm,
  $deliveryMethod,
  $localInstallerLocations,
  $portalInstallerLocations,
  $portalInstallerLocationsPresent,
  $localInstallerLocationsToRender,
  preferredLocationForm,
  $preferredLocationFormValues: preferredLocationForm.$values,
  $preferredLocationFormErrors,
  $isValidDeliveryForm,
  $isOpenedInstallersOverlay,
  $deliveryDataToSend,
  $isLoadingInstallers,
  $productToInstall,
  $availableShipToInstallers,
  $installersAddressByZip,
  $isFedexPicked,
  $isPreferredLocationPicked,
  $isMobileInstallerPicked,
  $isPhysicalInstallerPicked,
  $preferredLocationInstaller,
  $deliveryToInstallerIsHidden,
  $canShowShipToInstaller,
};

export const store = combine(stores);

export const actions = {
  updateFedex,
  updatePreferredLocation,
  updateFedexFx,
  updatePreferredLocationFx,
  pickFedex,
  pickPreferredLocation,
  findInstallers,
  findInstallersFx,
  openInstallersOverlay,
  closeInstallersOvelay,
  validatePreferredLocationForm,
  preferredLocationFormActions: preferredLocationForm.fields,
  clearPreferredLocationInstaller,
  hideDeliveryToInstaller,
};

persist({ store: $installerLocations, key: 'new-installer-locations' });
persist({
  store: $preferredLocationInstaller,
  key: 'preferred-location-installer',
});
persist({ store: $preferredLocationZip, key: 'preferred-location-zip' });
persist({ store: $preferredLocactionId, key: 'preferred-location-id' });
