import { forward, createDomain, sample, combine } from 'effector';
import http from 'src/logic/http';

import { generateData } from 'src/effector/searchWidget/models/utils';
import { actions as orderActions } from 'src/effector/order';
import some from 'lodash/some';

const addonsDomain = createDomain('addons');

const states = {
  makes: {
    actions: ['reset', 'setValue', 'setOptions', 'back'],
    effects: ['loadOptions'],
  },
  years: {
    actions: ['setValue', 'setOptions', 'reset', 'back'],
    effects: ['loadOptions'],
    resetOn: ['makes'],
  },
  models: {
    actions: ['setValue', 'setOptions', 'reset', 'back'],
    effects: ['loadOptions'],
    resetOn: ['years'],
  },
  trims: {
    actions: ['setValue', 'setOptions', 'reset', 'back'],
    effects: ['loadOptions'],
    resetOn: ['models'],
  },
};

const { stores: tpmsStores, actions: tpmsActions, effects } = generateData(
  states,
);

const getAddons = addonsDomain.createEvent();
const toggleTpmsForm = addonsDomain.createEvent();
const closeTpmsForm = addonsDomain.createEvent();
const resetTpmsFormVerifyState = addonsDomain.createEvent();
const setFieldQuantityTpms = addonsDomain.createEvent();
const setTpmsState = addonsDomain.createEvent();
const preloadTpmsData = addonsDomain.createEvent();
const addToCartTpms = addonsDomain.createEvent();

const fetchData = async ({ url, params }) => {
  const { data } = await http.get(url, {
    params,
    baseURL: `${window.location.origin}/api/v1/search`,
  });

  return data;
};

export const preloadTpmsDataFx = addonsDomain.createEffect().use(fetchData);

const getAddonsFx = addonsDomain.createEffect().use(async () => {
  const { data } = await http.get(
    `${window.location.origin}/api/v1/orders/addons`,
  );
  return data;
});

const verifyTpmsFormFx = addonsDomain.createEffect().use(async params => {
  const { data } = await http.get(
    `${window.location.origin}/api/v1/tpms/check_is_allow`,
    {
      params,
    },
  );
  return data;
});

const $tpms = addonsDomain.createStore({}).on(setTpmsState, (_, tpms) => tpms);

const $tpmsFormVerifyState = addonsDomain
  .createStore({ status: '' })
  .on(verifyTpmsFormFx.doneData, (_, data) =>
    data.is_allow ? { status: 'success' } : { status: 'error' },
  )
  .on(verifyTpmsFormFx.failData, () => ({
    status: 'error',
  }))
  .on(resetTpmsFormVerifyState, () => ({ status: '' }));

const $isEmptyRequiredFields = combine(
  tpmsStores,
  ({ makes, models, years, trims }) =>
    some([makes, models, years, trims], ({ current: { id } }) => !id),
);

const $hasVerifyError = combine(
  $tpmsFormVerifyState,
  verify => verify.status === 'error',
);

const $isAllowedAddToCartTpms = combine(
  $isEmptyRequiredFields,
  $tpmsFormVerifyState,
  (isEmpty, verify) => !isEmpty && verify.status === 'success',
);

const $disabledSubmitTpmsForm = combine(
  $isAllowedAddToCartTpms,
  isAllowed => !isAllowed,
);

const $quantityTpms = addonsDomain
  .createStore(4)
  .on(setFieldQuantityTpms, (_, value) => value);

const $vehicleInfoTpms = combine(
  tpmsStores,
  ({ makes, years, models, trims }) =>
    [years, makes, models, trims]
      .map(({ current: { value } }) => value)
      .join(' '),
);

const $addons = addonsDomain
  .createStore([])
  .on(getAddonsFx.doneData, (_, addons) => addons);

const $isLoadingAddons = getAddonsFx.pending;

const $isOpenTpmsForm = addonsDomain
  .createStore(false)
  .on(toggleTpmsForm, store => !store)
  .on(closeTpmsForm, () => false);

const $isLoadingVerify = verifyTpmsFormFx.pending;

const $tpmsForm = combine({
  ...tpmsStores,
  hasVerifyError: $hasVerifyError,
  disabledButton: $disabledSubmitTpmsForm,
  quantity: $quantityTpms,
  vehicleInfo: $vehicleInfoTpms,
  tpmsItem: $tpms,
  isLoadingVerify: $isLoadingVerify,
});

forward({
  from: preloadTpmsData,
  to: preloadTpmsDataFx.prepend(() => ({ url: '/data' })),
});

forward({
  from: preloadTpmsDataFx.doneData,
  to: tpmsActions.makes.setOptions.prepend(({ makes }) => makes),
});

forward({
  from: getAddons,
  to: getAddonsFx,
});

sample({
  clock: tpmsActions.makes.setValue,
  source: tpmsStores.makes,
  fn: ({ current: { id } }) => ({
    url: '/vehicle/tires/years',
    params: { make_id: id },
  }),
  target: effects.years.loadOptions,
});

sample({
  clock: tpmsActions.years.setValue,
  source: tpmsStores.years,
  fn: ({ current: { id } }) => ({
    url: '/vehicle/tires/models',
    params: { year_id: id },
  }),
  target: effects.models.loadOptions,
});

sample({
  clock: tpmsActions.models.setValue,
  source: tpmsStores.models,
  fn: ({ current: { id } }) => ({
    url: '/vehicle/tires/trims',
    params: { model_id: id },
  }),
  target: effects.trims.loadOptions,
});

sample({
  clock: tpmsActions.trims.setValue,
  source: tpmsStores,
  fn: ({ makes, models, years }) => ({
    make: makes.current.value,
    model: models.current.value,
    year: years.current.value,
  }),
  target: verifyTpmsFormFx,
});

sample({
  clock: addToCartTpms,
  source: { tpmsForm: $tpmsForm, isAllowed: $isAllowedAddToCartTpms },
  filter: ({ isAllowed }) => isAllowed,
  fn: ({ tpmsForm }) => [
    {
      vehicle_info: tpmsForm.vehicleInfo,
      variant_id: tpmsForm.tpmsItem.id,
      quantity: tpmsForm.quantity,
      type: 'tpms',
    },
  ],
  target: [orderActions.createLineItem, closeTpmsForm],
});

forward({
  from: closeTpmsForm,
  to: [
    tpmsActions.makes.reset,
    tpmsActions.years.reset,
    tpmsActions.models.reset,
    tpmsActions.trims.reset,
    resetTpmsFormVerifyState,
  ],
});

export const stores = {
  $addons,
  $isLoadingAddons,
  $isOpenTpmsForm,
  $tpmsForm,
};

export const actions = {
  getAddons,
  toggleTpmsForm,
  closeTpmsForm,
  tpmsActions,
  preloadTpmsData,
  setFieldQuantityTpms,
  setTpmsState,
  addToCartTpms,
};
