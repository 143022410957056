import { forward, createDomain, sample, combine } from 'effector';
import http from 'src/logic/http';
import { stores as orderStores } from 'src/effector/order';
import isEmpty from 'lodash/isEmpty';

import { generateData } from 'src/effector/searchWidget/models/utils';
import * as api from 'src/logic/api';

const lugNutsDomain = createDomain('lugNuts');

const states = {
  makes: {
    actions: ['reset', 'setValue', 'setOptions', 'back'],
    effects: ['loadOptions'],
  },
  years: {
    actions: ['setValue', 'setOptions', 'reset', 'back'],
    effects: ['loadOptions'],
    resetOn: ['makes'],
  },
  models: {
    actions: ['setValue', 'setOptions', 'reset', 'back'],
    effects: ['loadOptions'],
    resetOn: ['years'],
  },
  trims: {
    actions: ['setValue', 'setOptions', 'reset', 'back'],
    effects: ['loadOptions'],
    resetOn: ['models'],
  },
};

const {
  stores: lugNutsStores,
  actions: lugNutsActions,
  effects,
} = generateData(states);

const $selectedVehicleInfo = orderStores.$wheels.map(
  wheels =>
    wheels.find(({ vehicle_info, fmk }) => vehicle_info && !fmk)?.vehicle_info,
);

const preloadLugNutsData = lugNutsDomain.createEvent();

const fetchData = async ({ url, params }) => {
  const { data } = await http.get(url, {
    params,
    baseURL: `${window.location.origin}/api/v1/search`,
  });

  return data;
};

export const preloadLugNutsDataFx = lugNutsDomain.createEffect().use(fetchData);

const $lugNutsForm = combine({
  ...lugNutsStores,
});

forward({
  from: preloadLugNutsData,
  to: preloadLugNutsDataFx.prepend(() => ({ url: '/data' })),
});

forward({
  from: preloadLugNutsDataFx.doneData,
  to: lugNutsActions.makes.setOptions.prepend(({ makes }) => makes),
});

sample({
  clock: lugNutsActions.makes.setValue,
  source: lugNutsStores.makes,
  fn: ({ current: { id } }) => ({
    url: '/vehicle/tires/years',
    params: { make_id: id },
  }),
  target: effects.years.loadOptions,
});

sample({
  clock: lugNutsActions.years.setValue,
  source: lugNutsStores.years,
  fn: ({ current: { id } }) => ({
    url: '/vehicle/tires/models',
    params: { year_id: id },
  }),
  target: effects.models.loadOptions,
});

sample({
  clock: lugNutsActions.models.setValue,
  source: lugNutsStores.models,
  fn: ({ current: { id } }) => ({
    url: '/vehicle/tires/trims',
    params: { model_id: id },
  }),
  target: effects.trims.loadOptions,
});

const $isValidLugNutsForm = $lugNutsForm.map(lugNutsForm =>
  Object.values(lugNutsForm).every(({ current }) => !isEmpty(current)),
);

const updateWheelsVehicleInfo = lugNutsDomain.createEvent();
const updateWheelsVehicleInfoFx = lugNutsDomain
  .createEffect()
  .use(api.updateCheckoutState);

sample({
  clock: updateWheelsVehicleInfo,
  source: $lugNutsForm,
  fn: ({ makes, models, years, trims }) => {
    const vehicle_info = [
      makes.current.value,
      models.current.value,
      years.current.value,
      trims.current.value,
    ].join(' ');
    return {
      order: {
        vehicle_info,
      },
      state: 'lugnuts',
    };
  },
  target: updateWheelsVehicleInfoFx,
});

export const stores = {
  $lugNutsForm,
  $selectedVehicleInfo,
  $isValidLugNutsForm,
};

export const actions = {
  lugNutsActions,
  preloadLugNutsDataFx,
  preloadLugNutsData,
  updateWheelsVehicleInfo,
  updateWheelsVehicleInfoFx,
};

export const store = combine(stores);
