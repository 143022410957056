import { merge, sample, combine, createEffect, forward } from 'effector';

import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';

import * as api from 'src/logic/api';
import { actions as modalActions } from 'src/effector/searchWidget/modal';
import { generateData } from 'src/effector/searchWidget/models/utils';
import staticData from 'src/effector/searchWidget/static';

const states = {
  makes: {
    actions: ['reset', 'setValue', 'setOptions', 'back'],
    effects: ['loadOptions'],
  },
  years: {
    actions: ['setValue', 'setOptions', 'back', 'reset'],
    effects: ['loadOptions'],
    resetOn: ['makes'],
  },
  models: {
    actions: ['setValue', 'setOptions', 'back', 'reset'],
    effects: ['loadOptions'],
    resetOn: ['years'],
  },
  trims: {
    actions: ['setValue', 'setOptions', 'back', 'reset'],
    effects: ['loadOptions'],
    resetOn: ['models'],
  },
  verify: {
    actions: ['setValue', 'setOptions', 'confirm', 'back', 'reset'],
    effects: ['loadOptions'],
    resetOn: ['trims'],
    preset: true,
  },
  zip: {
    actions: ['setValue', 'setOptions', 'confirm', 'back', 'reset'],
    effects: ['loadOptions', 'setZip'],
    resetOn: ['verify'],
  },
};

//
// const inProgressDataToRender = getDataToRender([
//   'makes',
//   'years',
//   'models',
//   'trims',
// ]);
//
const {
  stores: { makes, years, models, trims, zip },
  actions,
  effects,
  helpers: {
    currentStep,
    changeStep,
    allData,
    currentData,
    currentActions,
    progress,
    isLoading,
  },
} = generateData(states);

const stepsOnActionTrigger = [
  { from: effects.years.loadOptions.doneData, fn: () => 'years' },
  { from: effects.models.loadOptions.doneData, fn: () => 'models' },
  { from: effects.trims.loadOptions.doneData, fn: () => 'trims' },
  { from: effects.verify.loadOptions.doneData, fn: () => 'verify' },
  { from: actions.verify.confirm, fn: () => 'zip' },
  { from: modalActions.closeWidget, fn: () => 'makes' },
  { from: modalActions.showFilters, fn: () => 'makes' },
];

stepsOnActionTrigger.forEach(({ from, fn }) => {
  forward({
    from,
    to: changeStep.prepend(fn),
  });
});

forward({
  from: actions.makes.back,
  to: modalActions.showFilters,
});

forward({
  from: [modalActions.showFilters, modalActions.closeWidget],
  to: actions.makes.reset,
});

sample({
  clock: merge([
    actions.years.back,
    actions.models.back,
    actions.trims.back,
    actions.verify.back,
    actions.zip.back,
  ]),
  source: { currentStep },
  fn: data =>
    ({
      years: 'makes',
      models: 'years',
      trims: 'models',
      verify: 'trims',
      zip: 'verify',
    }[data.currentStep]),
  target: changeStep,
});

sample({
  clock: actions.makes.setValue,
  source: makes,
  fn: ({ current: { id } }) => ({
    url: '/vehicle/tires/years',
    params: { make_id: id },
  }),
  target: effects.years.loadOptions,
});

sample({
  clock: actions.years.setValue,
  source: years,
  fn: ({ current: { id } }) => ({
    url: '/vehicle/tires/models',
    params: { year_id: id },
  }),
  target: effects.models.loadOptions,
});

sample({
  clock: actions.models.setValue,
  source: models,
  fn: ({ current: { id } }) => ({
    url: '/vehicle/tires/trims',
    params: { model_id: id },
  }),
  target: effects.trims.loadOptions,
});

sample({
  clock: actions.trims.setValue,
  source: trims,
  fn: ({ current: { id } }) => ({
    url: '/vehicle/tires/verify',
    params: { trim_id: id },
  }),
  target: effects.verify.loadOptions,
});

sample({
  clock: actions.zip.confirm,
  source: zip,
  fn: ({ current: { value } }) => ({
    zip: value,
  }),
  target: effects.zip.setZip.use(api.setZip),
});

sample({
  clock: effects.zip.setZip.finally,
  source: allData,
  fn: d => {
    const { type } = d.verify.current;
    const query = omitBy(
      {
        ...d.verify.current,
        make2: d.makes.current.value,
        cyear: d.years.current.value,
        model: d.models.current.value,
        options: d.trims.current.value,
      },
      isEmpty,
    );

    const route = type === 'staggered' ? 'staggered' : 'vehicle';
    const searchParams = new URLSearchParams(query);
    return `${route}?${searchParams}`;
  },
  target: createEffect().use(
    url => (window.location.href = `${window.location.origin}/${url}`),
  ),
});

sample({
  clock: actions.zip.confirm,
  fn: () => true,
  target: isLoading,
});

const currentStaticData = combine(
  currentStep,
  step => staticData.vehicle.tires[step],
);

const vehicleDataToRender = allData.map(data =>
  ['makes', 'years', 'models', 'trims']
    .map(name => ({
      onClick: () => changeStep(name),
      value: data[name].current.value,
    }))
    .filter(({ value }) => Boolean(value)),
);

const tireSizeToRender = allData.map(data =>
  [
    { key: 'front_width', type: 'key' },
    { key: '/', type: 'token' },
    { key: 'front_aspect', type: 'key' },
    { key: 'R', type: 'token' },
    { key: 'front_diameter', type: 'key' },
    { key: ' ', type: 'token' },
    { key: 'front_load_index', type: 'key' },
    { key: 'front_speed_rating', type: 'key' },
  ]
    .map(
      item =>
        ({ token: item.key, key: data.verify.current[item.key] }[item.type]),
    )
    .join(''),
);

export { actions };

const stores = {
  data: allData,
  currentStep,
  isLoading,
  progress,
  vehicleDataToRender,
  tireSizeToRender,
  dataByCurrentStep: currentData,
  actionsByCurrentStep: currentActions,
  staticDataByCurrentStep: currentStaticData,
};

export const store = combine(stores);
