import {
  createStore,
  createEvent,
  createEffect,
  sample,
  forward,
  combine,
} from 'effector';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import axios from 'axios';
import {
  stores as addressStores,
  actions as addressActions,
} from 'src/effector/checkout/address';

const lobApi = axios.create({
  baseURL: 'https://api.lob.com/v1',
  timeout: 10000,
  auth: {
    username: process.env.LOB_PUBLIC_API_KEY,
    password: '',
  },
});

const deliverabilityStatus = {
  deliverable_incorrect_unit: 'Possible Incorrect Unit',
  deliverable_missing_unit: 'Possible Missing Unit',
};

const deliverabilityMessage = {
  deliverable_incorrect_unit:
    '<div>According to the USPS, the unit you entered may be incorrect for this multi-unit building. Please verify or edit the unit number by clicking "Edit" before confirming your address.</div>',
  deliverable_missing_unit:
    '<div>According to the USPS, the address you entered is a multi-unit building. Please verify or add the unit number by clicking "Edit" before confirming.</div>',
};

const address1Fields = [
  'primary_number',
  'street_predirection',
  'street_name',
  'street_suffix',
  'street_postdirection',
];

const address2Fields = [
  'secondary_designator',
  'secondary_number',
  'pmb_designator',
  'pmb_number',
  'extra_secondary_designator',
  'extra_secondary_number',
];

const getAddressString = (fields, data) =>
  fields
    .map(field => data[field])
    .join(' ')
    .trim();

const setEnteredForm = createEvent();

const getAddress1 = getAddressString.bind(null, address1Fields);
const getAddress2 = getAddressString.bind(null, address2Fields);

const showModal = createEvent();
const hideModal = createEvent();

const pickEnetered = createEvent();
const pickRecommended = createEvent();

const verifyAddress = createEvent();
const verifyAddressFx = createEffect().use(async params => {
  const { data } = await lobApi.post('/us_verifications', params);

  return data;
});

const confirmAddress = createEvent();

export const actions = {
  verifyAddress,
  verifyAddressFx,
  showModal,
  hideModal,
  pickRecommended,
  pickEnetered,
  setEnteredForm,
  confirmAddress,
};

export const $modalState = createStore(false)
  .on(actions.showModal, () => true)
  .on(actions.hideModal, () => false);

export const $pickedAddress = createStore('recommended')
  .on(actions.pickEnetered, () => 'entered')
  .on(actions.pickRecommended, () => 'recommended');

const $verificationInfo = createStore({}).on(
  verifyAddressFx.doneData,
  (_, data) => data,
);

const $deliverabilityStatus = $verificationInfo.map(({ deliverability }) =>
  get(deliverabilityStatus, deliverability, ''),
);

const $deliverabilityMessage = $verificationInfo.map(({ deliverability }) =>
  get(deliverabilityMessage, deliverability, ''),
);

const $shouldShowDeliverabilityInfo = $deliverabilityStatus.map(
  s => !isEmpty(s),
);

sample({
  source: $verificationInfo,
  fn: info => get(info, ['components', 'record_type'], '') == 'po_box',
  target: addressStores.$isAddressAPoBox,
});

const $enteredAddress = createStore({}).on(
  actions.setEnteredForm,
  (_, data) => data,
);

sample({
  clock: verifyAddressFx.doneData,
  source: addressStores.$shipAddressFormValues,
  target: actions.setEnteredForm,
});

const $recommendedAddress = $verificationInfo.map(
  ({ components }) =>
    components && {
      address1: getAddress1(components),
      address2: getAddress2(components),
      city: components.city,
      state: components.state,
      zipcode: components.zip_code,
    },
);

sample({
  clock: confirmAddress,
  source: {
    recommendedAddress: $recommendedAddress,
    pickedAddress: $pickedAddress,
    statesByAbbr: addressStores.$statesByAbbr,
    isAddressAPoBox: addressStores.$isAddressAPoBox,
  },
  filter: ({ pickedAddress }) => pickedAddress === 'recommended',
  fn: ({ recommendedAddress, statesByAbbr }) => {
    return {
      ...recommendedAddress,
      state_id: Number(statesByAbbr[recommendedAddress.state][0].id),
      state_abbr: statesByAbbr[recommendedAddress.state][0].abbr,
    };
  },
  target: addressActions.shipAddressFormActions.setForm,
});

forward({
  from: confirmAddress,
  to: hideModal,
});

export const stores = {
  $verificationInfo,
  $modalState,
  $deliverabilityStatus,
  $deliverabilityMessage,
  $recommendedAddress,
  $pickedAddress,
  $enteredAddress,
  $shouldShowDeliverabilityInfo,
};

export const store = combine(stores);
