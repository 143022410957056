import {
  combine,
  createStore,
  createEvent,
  createEffect,
  forward,
  sample,
} from 'effector';
import { createForm } from 'effector-forms';
import rules from 'src/effector/forms/rules';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import * as api from 'src/logic/api';
import { assoc } from 'rambda';

const completeAddress = createEvent();
const checkDistance = createEvent();
const checkZipServicedByInstaller = createEvent();
const zipIsServicedByInstaller = createEvent();
const zipIsNotServicedByInstaller = createEvent();
const distanceAllowed = createEvent();
const distanceNotAllowed = createEvent();

const completeAddressFx = createEffect().use(api.updateCheckoutState);
const checkDistanceFx = createEffect().use(api.checkDistance);
const validateEmailFx = createEffect().use(api.validateEmail);

const $isAddressAPoBox = createStore(false);
const $emailValidationMessage = createStore('');

const addressFields = {
  firstname: {
    init: '',
    rules: [rules.required()],
  },
  lastname: {
    init: '',
    rules: [rules.required()],
  },
  company: {
    init: '',
  },
  address1: {
    init: '',
    rules: [
      rules.required(),
      {
        name: 'address_not_po_box',
        source: $isAddressAPoBox,
        validator: (_, __, isAddressAPoBox) =>
          console.log(isAddressAPoBox) || !isAddressAPoBox,
        errorText: 'We are unable to ship to PO Boxes',
      },
    ],
  },
  address2: {
    init: '',
  },
  city: {
    init: '',
    rules: [rules.required()],
  },
  state_id: {
    init: '',
    rules: [rules.required()],
  },
  state_abbr: {
    init: '',
  },
  zipcode: {
    init: '',
    rules: [rules.required(), rules.minLength(5)],
  },
  phone: {
    init: '',
    rules: [rules.required(), rules.minLength(10)],
  },
};

const addressForm = createForm({
  fields: {
    ...addressFields,
    email: {
      init: '',
      rules: [rules.email()],
    },
  },
});

const addressParamsForm = createForm({
  fields: {
    attentive: {
      init: false,
    },
    billing_address_is_same: {
      init: true,
    },
  },
});

const billAddressForm = createForm({
  fields: addressFields,
});

const $isValidAddressForm = combine(
  addressForm.$isValid,
  billAddressForm.$isValid,
  (a, b) => a && b,
);

const setStates = createEvent();

const $states = createStore([]).on(setStates, (_, data) => data);

const $statesByAbbr = $states.map(states => groupBy(states, 'abbr'));

forward({
  from: $isAddressAPoBox,
  to: addressForm.fields.address1.validate,
});

sample({
  source: addressForm.$values,
  fn: () => false,
  target: $isAddressAPoBox,
});

sample({
  clock: addressForm.fields.email.onBlur,
  source: addressForm.fields.email.$value,
  filter: value => !isEmpty(value),
  fn: email => ({ email }),
  target: validateEmailFx,
});

sample({
  source: validateEmailFx.done,
  fn: data => {
    if (data.result.didYouMean)
      return `Did you mean to enter ${data.result.didYouMean}?`;
    if (data.result.status === 'invalid')
      return 'Please double check your email is correct.';
    return '';
  },
  target: $emailValidationMessage,
});

sample({
  source: { states: $states, shipAddressFormValues: addressForm.$values },
  fn: ({ states, shipAddressFormValues }) =>
    states.find(({ id }) => +shipAddressFormValues.state_id === +id)?.abbr,
  target: addressForm.fields.state_abbr.onChange.prepend(p => p),
});

const setVisitorIdFx = createEffect().use(() => {
  const visitor_id = document.cookie.replace(
    /(?:(?:^|.*;\s*)__attentive_id\s*=\s*([^;]*).*$)|^.*$/,
    '$1',
  );
  return visitor_id;
});

const $visitor_id = createStore('').on(
  setVisitorIdFx.doneData,
  (_, data) => data,
);

const $addressDataToSend = combine(
  {
    addressParams: addressParamsForm.$values,
    shipAddress: addressForm.$values,
    billAddress: billAddressForm.$values,
    visitor_id: $visitor_id,
  },
  ({ shipAddress, billAddress, addressParams, visitor_id }) => ({
    attentive: addressParams.attentive,
    order: {
      bill_address_attributes: billAddress,
      ship_address_attributes: shipAddress,
      email: shipAddress.email,
    },
    visitor_id,
  }),
);

const fieldNames = [
  'firstname',
  'lastname',
  'city',
  'zipcode',
  'phone',
  'email',
  'state_id',
  'company',
  'address1',
  'address2',
];

const errors = fieldNames.reduce(
  (acc, name) => assoc(name, addressForm.fields[name].$firstError, acc),
  [],
);

const $errors = combine(errors, a => mapValues(a, v => get(v, 'errorText')));

export const stores = {
  $states,
  $statesByAbbr,
  $shipAddressFormValues: addressForm.$values,
  $billAddressFormValues: billAddressForm.$values,
  $addressParamsFormValues: addressParamsForm.$values,
  $isValidAddressForm,
  $addressDataToSend,
  $errors,
  $isAddressAPoBox,
  $emailValidationMessage,
};

export const actions = {
  setStates,
  setVisitorIdFx,
  completeAddress,
  completeAddressFx,
  checkDistance,
  checkZipServicedByInstaller,
  checkDistanceFx,
  distanceAllowed,
  distanceNotAllowed,
  zipIsServicedByInstaller,
  zipIsNotServicedByInstaller,
  addressParamsFormActions: addressParamsForm,
  shipAddressFormActions: addressForm,
  billAddressFormActions: billAddressForm,
};

export const store = combine(stores);
