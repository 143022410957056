import { combine, forward, createEvent, sample, createStore } from 'effector';
import isEmpty from 'lodash/isEmpty';

import {
  store as tireStore,
  actions as tireActions,
} from 'src/effector/searchWidget/models/vehicle/tires';
import {
  store as wheelStore,
  actions as wheelActions,
} from 'src/effector/searchWidget/models/vehicle/wheels';
import {
  store as sizeStore,
  actions as sizeActions,
} from 'src/effector/searchWidget/models/size/tires';
import {
  store as sizeWheelStore,
  actions as sizeWheelActions,
} from 'src/effector/searchWidget/models/size/wheels';

import {
  stores as filterStores,
  actions as filterActions,
} from 'src/effector/searchWidget/filters';

import {
  stores as modalStores,
  actions as modalActions,
} from 'src/effector/searchWidget/modal';

import {
  stores as searchStores,
  actions as searchActions,
} from 'src/effector/searchWidget/search';

import { preloadFx } from 'src/effector/searchWidget/models/utils';

const init = createEvent();

const setOptionsTopTireBrands = createEvent();
const setOptionsTopWheelBrands = createEvent();

forward({
  from: init,
  to: preloadFx.prepend(() => ({ url: '/data' })),
});

forward({
  from: preloadFx.doneData,
  to: [
    tireActions.makes.setOptions.prepend(({ makes }) => makes),
    setOptionsTopTireBrands.prepend(({ top_tire_brands }) => top_tire_brands),
    setOptionsTopWheelBrands.prepend(({ top_wheel_brands }) => top_wheel_brands),
    wheelActions.makes.setOptions.prepend(({ swagger_makes }) => swagger_makes),
    sizeActions.tireWidths.setOptions.prepend(({ tire_widths }) => tire_widths),
    sizeActions.tireWidthsRear.setOptions.prepend(
      ({ tire_widths }) => tire_widths,
    ),
    sizeWheelActions.sizes.setOptions.prepend(({ wheel_sizes }) => wheel_sizes),
  ],
});

const calculateProgressPercentage = (widgetState, currentModelProgress) => {
  const filterProgressPercentage = widgetState === 'data' ? 30 : 15;
  const modelProgressPercentage =
    ((100 - filterProgressPercentage) / 100) * currentModelProgress;
  return filterProgressPercentage + modelProgressPercentage;
};

export const actions = {
  init,
  vehicle: {
    tires: tireActions,
    wheels: wheelActions,
    both: wheelActions,
  },
  size: {
    tires: sizeActions,
    wheels: sizeWheelActions,
  },
  openWidget: modalActions.openWidget,
  startNewProductSearch: modalActions.startNewProductSearch,
  changeSize: modalActions.changeSize,
  changeVehicle: modalActions.changeVehicle,
  start: modalActions.start,
  showFilters: modalActions.showFilters,
  closeWidget: modalActions.closeWidget,
  changeShopBy: filterActions.changeShopBy,
  changeProductType: filterActions.changeProductType,
  handleChangeSearch: searchActions.handleChange,
};

const $models = combine(
  {
    $tireStore: tireStore,
    $wheelStore: wheelStore,
    $sizeStore: sizeStore,
    $sizeWheelStore: sizeWheelStore,
  },
  ({ $tireStore, $wheelStore, $sizeStore, $sizeWheelStore }) => {
    const models = {
      vehicle: {
        tires: $tireStore,
        wheels: $wheelStore,
        both: $wheelStore,
      },
      size: {
        tires: $sizeStore,
        wheels: $sizeWheelStore,
      },
    };
    return models;
  },
);
const $picked = combine(
  {
    models: $models,
    $shopBy: filterStores.$shopBy,
    $productType: filterStores.$productType,
  },
  ({ $shopBy, $productType, models }) => {
    const picked = models[$shopBy][$productType];
    return picked;
  },
);

const $optionsByCurrentStep = combine(
  {
    picked: $picked,
    searchValue: searchStores.$fieldValue,
  },
  ({ picked, searchValue }) => {
    if (isEmpty(searchValue)) {
      return picked.dataByCurrentStep.options;
    }
    return searchActions.handleSearch({
      list: picked.dataByCurrentStep.options,
      keys: ['value'],
      searchValue,
    });
  },
);

sample({
  clock: $picked,
  target: searchActions.resetValue,
});

const $topTireBrands = createStore({}).on(setOptionsTopTireBrands, (_, options) => options);
const $topWheelBrands = createStore({}).on(setOptionsTopWheelBrands, (_, options) => options);
const $topBrands = combine(
  {
   $topTireBrands,
   $topWheelBrands,
   $productType: filterStores.$productType,
  },
  ({ $topTireBrands, $topWheelBrands, $productType }) => {
    const topBrands = $productType === 'tires' ? $topTireBrands : $topWheelBrands
    return topBrands
  }
)

export const store = combine(
  {
    models: $models,
    $shopBy: filterStores.$shopBy,
    $productType: filterStores.$productType,
    $shopByData: filterStores.$shopByData,
    $productTypeData: filterStores.$productTypeData,
    $isOpenedWidget: modalStores.$isOpenedWidget,
    $widgetState: modalStores.$widgetState,
    $searchValue: searchStores.$fieldValue,
    picked: $picked,
    $optionsByCurrentStep,
    $topBrands,
  },
  ({
    $shopBy,
    $isOpenedWidget,
    $productType,
    $widgetState,
    $shopByData,
    $productTypeData,
    $searchValue,
    picked,
    models,
    $optionsByCurrentStep,
    $topBrands
  }) => {
    const $progressPercentage = calculateProgressPercentage(
      $widgetState,
      picked.progress,
    );

    return {
      models,
      picked,
      $progressPercentage,
      $widgetState,
      $isOpenedWidget,
      $shopBy,
      $productType,
      $shopByData,
      $productTypeData,
      $searchValue,
      $optionsByCurrentStep,
      $topBrands,
    };
  },
);
