import { createEvent, createEffect, combine, sample, forward } from 'effector';
import { stores as orderStores } from 'src/effector/order';
import {
  stores as checkoutStore,
  actions as checkoutActions,
} from 'src/effector/checkout';

export const actions = {
  scroll: createEvent(),
};

const scrollFx = createEffect().use(stepName => {
  const getPosition = obj => {
    let currenttop = 0;
    const isDesktop = window.innerWidth >= 1200;
    const isDeliveryStep = stepName === 'delivery';
    const mobilePosition = isDeliveryStep ? 150 : 75;
    const desktopPosition = isDeliveryStep ? 150 : 12;

    if (obj.offsetParent) {
      do {
        currenttop += obj.offsetTop;
      } while ((obj = obj.offsetParent));
      return currenttop - (isDesktop ? desktopPosition : mobilePosition);
    }
  };

  setTimeout(() => {
    const element = document.getElementById(stepName);
    window.scrollTo({ top: getPosition(element), behavior: 'smooth' });
  }, 0);
});

forward({
  from: checkoutActions.confirmSummary,
  to: actions.scroll,
});

sample({
  clock: actions.scroll,
  source: checkoutStore.$currentStep,
  target: scrollFx,
});

forward({
  from: checkoutStore.$currentStep,
  to: actions.scroll,
});

const $steps = combine(
  {
    step: checkoutStore.$currentStep,
    state: orderStores.$order.map(({ state }) => state),
    shouldShowLugNuts: orderStores.$order.map(
      order => order.should_show_lug_nuts,
    ),
    isAllowedAddons: orderStores.$order.map(order => order.is_allowed_addons),
    hasUserConfirmedSummary: checkoutStore.$confirmedSummary,
  },
  ({
    step,
    state,
    shouldShowLugNuts,
    isAllowedAddons,
    hasUserConfirmedSummary,
  }) =>
    [
      {
        name: 'delivery',
        completed: [
          'address',
          'lugnuts',
          'addons',
          'accessories',
          'payment',
        ].includes(state),
        inProgress: step === 'delivery',
        idle:
          !['address', 'lugnuts', 'addons', 'accessories', 'payment'].includes(
            state,
          ) && step !== 'delivery',
      },
      {
        name: 'address',
        completed: ['lugnuts', 'addons', 'accessories', 'payment'].includes(
          state,
        ),
        inProgress: step === 'address',
        idle:
          !['lugnuts', 'addons', 'accessories', 'payment'].includes(state) &&
          step !== 'address',
      },
      {
        name: 'lugnuts',
        completed: ['addons', 'accessories', 'payment'].includes(state),
        inProgress: step === 'lugnuts',
        idle:
          !['addons', 'accessories', 'payment'].includes(state) &&
          step !== 'lugnuts',
        shouldShow: () => shouldShowLugNuts,
      },
      {
        name: 'addons',
        completed: ['accessories', 'payment'].includes(state),
        inProgress: step === 'addons',
        idle: !['accessories', 'payment'].includes(state) && step !== 'addons',
        shouldShow: () => isAllowedAddons,
      },
      {
        name: 'accessories',
        completed: ['payment'].includes(state),
        inProgress: step === 'accessories',
        idle: !['payment'].includes(state) && step !== 'accessories',
      },
      {
        name: 'payment',
        inProgress: step === 'payment',
        idle: step !== 'payment',
      },
    ]
      .map(item =>
        hasUserConfirmedSummary ? item : { name: item.name, idle: true },
      )
      .filter(({ shouldShow = () => true }) => shouldShow()),
);

export const stores = {
  $steps,
};

export const store = combine(stores);
