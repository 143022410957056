import { sample, combine, forward, merge, createEffect } from 'effector';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import qs from 'qs';

import * as api from 'src/logic/api';
import { actions as modalActions } from 'src/effector/searchWidget/modal';
import { generateData } from 'src/effector/searchWidget/models/utils';
import staticData from 'src/effector/searchWidget/static';

const states = {
  diameters: {
    actions: ['reset', 'setValue', 'setOptions', 'back'],
    effects: ['loadOptions'],
  },
  widths: {
    actions: ['setValue', 'setOptions', 'reset', 'back'],
    effects: ['loadOptions'],
    resetOn: ['diameters'],
  },
  lugs: {
    actions: ['setValue', 'setOptions', 'back', 'reset'],
    effects: ['loadOptions'],
    resetOn: ['widths'],
  },
  boltPatterns: {
    actions: ['setValue', 'setOptions', 'back', 'reset'],
    effects: ['loadOptions'],
    resetOn: ['lugs'],
  },
  offsets: {
    actions: [
      'setValue',
      'setOptions',
      'back',
      'reset',
      'confirm',
      'viewResults',
    ],
    effects: ['loadOptions'],
    resetOn: ['boltPatterns'],
  },
  allOffsets: {
    actions: ['reset', 'setValue', 'setOptions', 'back'],
    effects: ['loadOptions'],
  },
  sizes: {
    actions: ['setValue', 'setOptions', 'back', 'reset', 'confirm'],
    effects: ['loadOptions'],
  },
  zip: {
    actions: ['setValue', 'setOptions', 'back', 'reset', 'confirm'],
    effects: ['loadOptions', 'setZip'],
  },
};

const {
  stores: {
    diameters,
    widths,
    lugs,
    boltPatterns,
    offsets,
    sizes,
    zip,
    allOffsets,
  },
  actions,
  effects,
  helpers: {
    allData,
    changeStep,
    currentStep,
    currentData,
    currentActions,
    isLoading,
    progress,
  },
} = generateData(states);

const stepsOnActionTrigger = [
  { from: actions.diameters.setValue, fn: () => 'widths' },
  { from: effects.lugs.loadOptions.doneData, fn: () => 'lugs' },
  { from: effects.boltPatterns.loadOptions.doneData, fn: () => 'boltPatterns' },
  { from: effects.offsets.loadOptions.doneData, fn: () => 'offsets' },
  { from: actions.offsets.confirm, fn: () => 'zip' },
  { from: modalActions.closeWidget, fn: () => 'diameters' },
  { from: modalActions.showFilters, fn: () => 'diameters' },
];

stepsOnActionTrigger.forEach(({ from, fn }) => {
  forward({
    from,
    to: changeStep.prepend(fn),
  });
});

sample({
  clock: actions.sizes.setOptions,
  source: sizes,
  fn: store =>
    sortBy(
      uniqBy(
        store.options.map(item => ({
          ...item,
          value: item.name.split(/['X', 'x']/)[0],
        })),
        'value',
      ),
      [item => +item.value],
    ),
  target: actions.diameters.setOptions,
});

sample({
  clock: actions.diameters.setValue,
  source: { diameters, sizes },
  fn: ({ diameters, sizes }) =>
    sortBy(
      uniqBy(
        sizes.options
          .filter(
            ({ name }) =>
              name.split(/['X', 'x']/)[0] === diameters.current?.value,
          )
          .map(item => ({ ...item, value: item.name.split(/['X', 'x']/)[1] })),
        'value',
      ),
      [item => +item.value],
    ),
  target: actions.widths.setOptions,
});

sample({
  clock: actions.widths.setValue,
  source: widths,
  fn: ({ current: { id } }) => ({
    url: '/size/wheels/lugs',
    params: { size_id: id },
  }),
  target: effects.lugs.loadOptions,
});

forward({
  from: effects.lugs.loadOptions.doneData,
  to: actions.lugs.setOptions.prepend(data =>
    data.map(item => ({ ...item, value: item.name })),
  ),
});

sample({
  clock: actions.lugs.setValue,
  source: { widths, lugs },
  fn: ({ widths, lugs }) => ({
    url: '/size/wheels/bolt_patterns',
    params: { size_id: widths.current.id, lug_id: lugs.current.id },
  }),
  target: effects.boltPatterns.loadOptions,
});

forward({
  from: effects.boltPatterns.loadOptions.doneData,
  to: actions.boltPatterns.setOptions.prepend(data =>
    data.map(item => ({
      ...item,
      value: +item.name < 25 ? `${item.name}”` : `${item.name}mm`,
    })),
  ),
});

sample({
  clock: effects.offsets.loadOptions.doneData,
  fn: data => data.map(i => i.id),
  target: actions.allOffsets.setValue,
});

sample({
  clock: actions.boltPatterns.setValue,
  source: { widths, lugs, boltPatterns },
  fn: ({ widths, lugs, boltPatterns }) => ({
    url: '/size/wheels/offsets',
    params: {
      size_id: widths.current.id,
      lug_id: lugs.current.id,
      bolt_pattern_id: boltPatterns.current.id,
    },
  }),
  target: effects.offsets.loadOptions,
});

forward({
  from: effects.offsets.loadOptions.doneData,
  to: actions.offsets.setOptions.prepend(data =>
    data.map(item => ({
      ...item,
      value: +item.name > 0 ? `+${item.name}` : item.name,
    })),
  ),
});

sample({
  clock: actions.zip.confirm,
  source: zip,
  fn: ({ current: { value } }) => ({
    zip: value,
  }),
  target: effects.zip.setZip.use(api.setZip),
});

sample({
  clock: effects.zip.setZip.finally,
  target: actions.offsets.viewResults,
});

sample({
  clock: actions.offsets.viewResults,
  source: { widths, lugs, boltPatterns, offsets, allOffsets },
  fn: ({ widths, lugs, boltPatterns, offsets, allOffsets }) => {
    const params = {
      wheel_size: widths.current.id,
      lug: lugs.current.id,
      bolt_pattern: boltPatterns.current.id,
      offsets: allOffsets.current,
      search: {
        taxon_filter: {
          Offset: offsets.options
            .filter(o => Object.keys(offsets.current).includes(o.id.toString()))
            .map(o => o.name),
        },
      },
    };
    return `wheels_size?${qs.stringify(params, {
      arrayFormat: 'brackets',
    })}`;
  },
  target: createEffect().use(
    url => (window.location.href = `${window.location.origin}/${url}`),
  ),
});

forward({
  from: actions.diameters.back,
  to: modalActions.showFilters,
});

forward({
  from: [modalActions.showFilters, modalActions.closeWidget],
  to: actions.diameters.reset,
});

sample({
  clock: merge([
    actions.widths.back,
    actions.lugs.back,
    actions.boltPatterns.back,
    actions.offsets.back,
    actions.zip.back,
  ]),
  source: { currentStep },
  fn: data =>
    ({
      widths: 'diameters',
      lugs: 'widths',
      boltPatterns: 'lugs',
      offsets: 'boltPatterns',
      zip: 'offsets',
    }[data.currentStep]),
  target: changeStep,
});

sample({
  clock: actions.offsets.viewResults,
  fn: () => true,
  target: isLoading,
});

const currentStaticData = combine(
  currentStep,
  step => staticData.size.wheels.single[step],
);

const wheelSizeDataToRender = allData.map(data =>
  ['diameters', 'widths', 'lugs', 'boltPatterns']
    .map(name => ({
      name,
      onClick: () => changeStep(name),
      value: data[name].current.value,
      label: staticData.size.wheels.single[name].label,
    }))
    .filter(({ value }) => Boolean(value))
    .map(item => {
      if (['diameters', 'widths'].includes(item.name)) {
        return { ...item, value: `${item.value}”` };
      }
      return item;
    }),
);

export { actions };

const stores = {
  data: allData,
  currentStep,
  isLoading,
  progress,
  dataByCurrentStep: currentData,
  staticData: staticData.size.wheels.single,
  actionsByCurrentStep: currentActions,
  staticDataByCurrentStep: currentStaticData,
  wheelSizeDataToRender,
};

export const store = combine(stores);
