import { createEvent, createStore, combine } from 'effector';

const setIsAllowedToAddAllstate = createEvent();
const $isAllowedToAddAllstate = createStore(false).on(
  setIsAllowedToAddAllstate,
  (_, v) => v,
);

export const stores = {
  $isAllowedToAddAllstate,
};

export const store = combine(stores);

export const actions = {
  setIsAllowedToAddAllstate,
};
