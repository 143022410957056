import { createStore, createEvent, combine } from 'effector';
import { assoc } from 'rambda';

const changeShopBy = createEvent();
const changeProductType = createEvent();

const $shopBy = createStore('vehicle').on(changeShopBy, (_, v) => v);
const $productType = createStore('tires')
  .on(changeProductType, (_, v) => v)
  .on(changeShopBy, () => 'tires');

const $shopByData = combine($shopBy, shopByValue =>
  [
    { id: 'vehicle', value: 'Shop by Vehicle' },
    { id: 'size', value: 'Shop by Size' },
  ].map(item => assoc('isActive', item.id === shopByValue, item)),
);

const $productTypeData = combine(
  $shopBy,
  $productType,
  (shopByValue, productTypeValue) =>
    ({
      vehicle: [
        { id: 'tires', value: 'Tires' },
        { id: 'wheels', value: 'Wheels' },
        { id: 'both', value: 'Both (Tires and Wheels)' },
      ],
      size: [
        { id: 'tires', value: 'Tires' },
        { id: 'wheels', value: 'Wheels' },
      ],
    }[shopByValue].map(item =>
      assoc('isActive', item.id === productTypeValue, item),
    )),
);

export const actions = {
  changeShopBy,
  changeProductType,
};

export const stores = {
  $shopBy,
  $productType,
  $shopByData,
  $productTypeData,
};
