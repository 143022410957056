import { createEvent, createStore, combine } from 'effector';

import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';
import flatten from 'lodash/flatten';

const setValue = createEvent();
const clearValue = createEvent();
const initRebateIds = createEvent();
const initBestWarrantyIds = createEvent();
const initCheapIds = createEvent();
const initAllWeatherIds = createEvent();

const $value = createStore('')
  .reset(clearValue)
  .on(setValue, (_, v) => v);

const $isEmpty = $value.map(v => v === '');
const $isDeals = $value.map(v => v === 'deals');
const $isBestWarranty = $value.map(v => v === 'bestWarranty');
const $isCheap = $value.map(v => v === 'cheap');
const $isAllWeather = $value.map(v => v === 'allWeather');

const $rebateIds = createStore([]).on(initRebateIds, (_, v) => compact(v));
const $bestWarrantyIds = createStore([]).on(initBestWarrantyIds, (_, v) =>
  compact(v),
);
const $cheapIds = createStore([]).on(initCheapIds, (_, v) => compact(v));
const $allWeatherIds = createStore([]).on(initAllWeatherIds, (_, v) =>
  compact(v),
);

const $featuredFilterIds = combine(
  [$rebateIds, $bestWarrantyIds, $cheapIds, $allWeatherIds],
  arrays => flatten(arrays),
);

const $hasFeaturedFiltersProducts = $featuredFilterIds.map(
  ids => !isEmpty(ids),
);

export const stores = {
  $value,
  $isEmpty,
  $isDeals,
  $isBestWarranty,
  $isCheap,
  $isAllWeather,
  $rebateIds,
  $featuredFilterIds,
  $bestWarrantyIds,
  $cheapIds,
  $allWeatherIds,
  $hasFeaturedFiltersProducts,
};

export const store = combine(stores);

export const actions = {
  setValue,
  clearValue,
  initRebateIds,
  initBestWarrantyIds,
  initCheapIds,
  initAllWeatherIds,
};
