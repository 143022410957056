import { combine, sample, createEvent, createEffect } from 'effector';
import qs from 'qs';
import {
  stores as productsStores,
  actions as productsActions,
} from 'src/effector/products';
import {
  stores as pricerangeStores,
  actions as pricerangeActions,
} from 'src/effector/products/filters/pricerange';
import {
  stores as taxonFiltersStores,
  actions as taxonFiltersActions,
} from 'src/effector/products/filters/taxon';
import {
  stores as featuredFiltersStores,
  actions as featuredFiltersActions,
} from 'src/effector/products/filters/featured';
import mapValues from 'lodash/mapValues';
import groupBy from 'lodash/groupBy';
import flatMap from 'lodash/flatMap';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import assign from 'lodash/assign';
import omitBy from 'lodash/omitBy';
import has from 'lodash/has';
import keys from 'lodash/keys';
import pick from 'lodash/pick';
import findKey from 'lodash/findKey';
import trimEnd from 'lodash/trimEnd';
import omit from 'lodash/omit';

const FeaturedParams = {
  with_active_rebates: 'deals',
  with_cheap: 'cheap',
  with_best_warranty: 'bestWarranty',
  with_all_weather: 'allWeather',
};

const initQueryParams = createEvent();
const updateSearchParam = createEvent();
const updateSortParam = createEvent();
const updatePricerangeParam = createEvent();
const updateFeaturedFilterParam = createEvent();
const reloadCurrentPageFx = createEffect().use(() => {
  setTimeout(() => {
    window.location.reload();
  }, 10);
});

const updateQueryStringFx = createEffect().use(
  ({ paramsToAdd, paramsToRemove = {} }) => {
    const queryParams = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    const queryParamsWithParamsWithAdd = assign({}, queryParams, paramsToAdd);
    const queryParamsWithoutParamsToRemove = omitBy(
      queryParamsWithParamsWithAdd,
      (_, key) => has(paramsToRemove, key),
    );
    const updatedQueryParamsString = qs.stringify(
      queryParamsWithoutParamsToRemove,
      {
        addQueryPrefix: true,
        arrayFormat: 'brackets',
      },
    );
    window.history.pushState({}, '', updatedQueryParamsString);
  },
);

sample({
  clock: initQueryParams,
  target: [
    updateSearchParam,
    updateSortParam,
    updatePricerangeParam,
    updateFeaturedFilterParam,
  ],
});

sample({
  clock: updateSearchParam,
  fn: ({ search }) => {
    if (isEmpty(search)) {
      return [];
    }
    return flatMap(search.taxon_filter, (values, label) =>
      map(values, value => ({ label, value, checked: true })),
    );
  },
  target: taxonFiltersActions.setFromQueryParam,
});

sample({
  source: taxonFiltersStores.$checkedFiltersArray,
  fn: filters => {
    if (isEmpty(filters)) {
      return { paramsToRemove: { search: {} } };
    }
    return {
      paramsToAdd: {
        search: {
          taxon_filter: mapValues(groupBy(filters, 'label'), items =>
            items.map(item => item.value),
          ),
        },
      },
    };
  },
  target: updateQueryStringFx,
});

sample({
  clock: updateSortParam,
  fn: ({ sort }) => (sort === 'descend' ? 'desc' : 'asc'),
  target: productsActions.changeSortDirection,
});

sample({
  source: productsStores.$sortDirection,
  fn: value => ({
    paramsToAdd: {
      sort: value === 'asc' ? 'ascend' : 'descend',
    },
  }),
  target: updateQueryStringFx,
});

sample({
  clock: updatePricerangeParam,
  fn: ({ pricerange }) => {
    if (isEmpty(pricerange)) {
      return {};
    }

    const priceRangeValues = pricerange?.split(';');
    return {
      minPrice: Number(priceRangeValues[0]),
      maxPrice: Number(priceRangeValues[1]),
    };
  },
  target: pricerangeActions.setPriceRangeFx,
});

sample({
  source: {
    priceRange: pricerangeStores.$priceRange,
    isDefaultPricerange: pricerangeStores.$isDefaultPricerange,
  },
  fn: ({ priceRange, isDefaultPricerange }) => {
    if (isDefaultPricerange) {
      return {
        paramsToRemove: {
          pricerange: '',
        },
      };
    }
    return {
      paramsToAdd: {
        pricerange: `${priceRange.min.price};${priceRange.max.price}`,
      },
    };
  },
  target: updateQueryStringFx,
});

sample({
  clock: updateFeaturedFilterParam,
  fn: params => {
    const featuredParams = pick(params, keys(FeaturedParams));
    const firstKeyWithOn = findKey(featuredParams, value => value === 'on');
    return FeaturedParams[firstKeyWithOn] || '';
  },
  target: featuredFiltersActions.setValue,
});

sample({
  source: featuredFiltersStores,
  fn: featuredFilter => {
    const keyFound = findKey(
      FeaturedParams,
      value => value === featuredFilter.$value,
    );
    if (featuredFilter.$isEmpty) {
      return { paramsToRemove: FeaturedParams };
    }
    return {
      paramsToAdd: { [keyFound]: 'on' },
      paramsToRemove: omit(FeaturedParams, [keyFound]),
    };
  },
  target: updateQueryStringFx,
});

sample({
  clock: productsActions.changeSizeStaggered,
  fn: data => {
    const [size, staggered] = data.split(' ');
    return {
      paramsToAdd: {
        size: trimEnd(size, '"'),
        staggered: staggered ? 'Yes' : 'No',
      },
      paramsToRemove: { search: {} },
    };
  },
  target: [updateQueryStringFx, reloadCurrentPageFx],
});

export const stores = {};

export const store = combine(stores);

export const actions = { initQueryParams };
