import { forward, sample, createDomain } from 'effector';

import axios from 'axios';
import isEmpty from 'lodash/isEmpty';

const promoCodeDomain = createDomain('promoCode');

const createPromoCode = promoCodeDomain.createEvent();
export const createPromoCodeFx = promoCodeDomain
  .createEffect()
  .use(async params => {
    const { data } = await axios.post(
      `${window.location.origin}/api/v1/orders/promo_code`,
      params,
    );
    return data;
  });

const setFieldPromoCode = promoCodeDomain.createEvent();
const $promoCodeForm = promoCodeDomain
  .createStore({})
  .on(setFieldPromoCode, (s, { key, value }) => ({
    ...s,
    [key]: value,
  }));
const handleChange = setFieldPromoCode.prepend(e => ({
  key: e.target.name,
  value: e.target.value,
}));
const togglePromoCodeForm = promoCodeDomain.createEvent();
const closePromoCodeForm = promoCodeDomain.createEvent();
const $isOpenPromoCodeForm = promoCodeDomain
  .createStore(false)
  .on(togglePromoCodeForm, store => !store)
  .on(closePromoCodeForm, () => false);

sample({
  clock: createPromoCode,
  source: $promoCodeForm,
  fn: form => ({
    coupon_code: form.coupon_code,
  }),
  target: createPromoCodeFx,
});

forward({
  from: createPromoCodeFx.doneData,
  to: closePromoCodeForm,
});

const $promoCode = promoCodeDomain
  .createStore({ status: '' })
  .on(createPromoCodeFx.failData, (_, { response }) => ({
    status: 'error',
    message: response.data.message,
  }))
  .on(createPromoCodeFx.doneData, () => ({
    status: 'success',
  }));

const $isLoadingPromoCode = createPromoCodeFx.pending;
const $isEmptyField = $promoCodeForm.map(state => isEmpty(state.coupon_code));
const $hasPromoCodeError = $promoCode.map(state => state.status === 'error');

export const stores = {
  $promoCode,
  $promoCodeForm,
  $isLoadingPromoCode,
  $isEmptyField,
  $isOpenPromoCodeForm,
  $hasPromoCodeError,
};

export const actions = {
  createPromoCode,
  handleChange,
  togglePromoCodeForm,
};
