import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import identity from 'lodash/identity';
import map from 'lodash/map';
import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';
import intersectionBy from 'lodash/intersectionBy';
import filter from 'lodash/filter';
import uniq from 'lodash/uniq';
import isEmpty from 'lodash/isEmpty';
import { assoc } from 'rambda';
import {
  createEvent,
  createEffect,
  createStore,
  combine,
  sample,
  forward,
} from 'effector';
import {
  stores as orderStores,
  actions as orderActions,
} from 'src/effector/order';
import * as api from 'src/logic/api';

const confirmQuantityFx = createEffect().use(api.confirmQuantity);
const confirmQuantity = createEvent();

const changeQuantity = createEvent();

const $tiresToConfirmQuantity = combine(
  orderStores.$lineItemsToConfirmQuantity,
  orderStores.$tires,
  (lineItemsToConfirmQuantity, tires) => {
    const tireSizes = map(
      groupBy(tires, ({ size }) => size),
      (items, size) => ({ size, sum: sumBy(items, 'quantity') }),
    )
      .filter(({ sum }) => sum > 7)
      .map(({ size }) => size);

    const tireGroupsWithBigQuantity = tires.filter(({ size }) =>
      tireSizes.includes(size),
    );

    return intersectionBy(
      tireGroupsWithBigQuantity,
      lineItemsToConfirmQuantity,
      'id',
    );
  },
);

const $wheelsToConfirmQuantity = combine(
  orderStores.$lineItemsToConfirmQuantity,
  orderStores.$wheels,
  (lineItemsToConfirmQuantity, wheels) => {
    const wheelsWithBigQuantity = wheels.filter(({ quantity }) => quantity > 7);

    return intersectionBy(
      wheelsWithBigQuantity,
      lineItemsToConfirmQuantity,
      'id',
    );
  },
);

const $tiresWithSameModelToConfirmQuantity = combine(
  orderStores.$lineItemsToConfirmQuantity,
  orderStores.$tires,
  (lineItemsToConfirmQuantity, tires) => {
    const tiresWithSameModel = filter(
      uniq(
        map(tires, item => {
          if (filter(tires, { model_name: item.model_name }).length > 1) {
            return item;
          }

          return false;
        }),
      ),
      value => value.model_name,
    );

    return intersectionBy(tiresWithSameModel, lineItemsToConfirmQuantity, 'id');
  },
);

const $showConfirmationQuantityModal = combine(
  [
    $tiresToConfirmQuantity,
    $wheelsToConfirmQuantity,
    $tiresWithSameModelToConfirmQuantity,
  ],
  itemsToConfirmQuantity =>
    itemsToConfirmQuantity.some(items => !isEmpty(items)),
);

const $lineItemsForm = createStore({})
  .on(orderStores.$lineItemsToConfirmQuantity, (_, s) =>
    mapValues(keyBy(s, 'id'), ({ quantity }) => quantity),
  )
  .on(changeQuantity, (s, { key, value }) => assoc(key, value, s));

sample({
  clock: confirmQuantity,
  source: $lineItemsForm,
  fn: form => ({
    line_items_to_confirm: Object.entries(form).map(([id, quantity]) => ({
      id,
      quantity,
    })),
  }),
  target: confirmQuantityFx,
});

forward({
  from: confirmQuantityFx.doneData,
  to: orderActions.setState.prepend(({ order }) => order),
});

const $isLoading = confirmQuantityFx.pending.map(identity);

export const actions = {
  changeQuantity,
  confirmQuantity,
};

export const stores = {
  $showConfirmationQuantityModal,
  $isLoading,
};

export const store = combine(stores);
