import {
  createEffect,
  combine,
  createStore,
  createEvent,
  sample,
  forward,
} from 'effector';

import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import intersectionBy from 'lodash/intersectionBy';
import {
  stores as orderStores,
  actions as orderActions,
} from 'src/effector/order';
import { actions as drawerActions } from 'src/effector/drawer';
import * as api from 'src/logic/api';

const { $lineItems } = orderStores;

const $plansDesctiption = {
  allstatePlans: {
    logoUrl:
      'https://d19pbi8vkdue6m.cloudfront.net/icons/powered-by-allstate.svg',
    logoStyles: 'h-5',
    title: 'Tire Road Hazard Protection',
    includes: {
      title: 'Plan includes:',
      list: [
        'Flat Fix and Replacements',
        'Mount & Balance Included with Replacement',
        'Roadside assistance',
        'Car rental reimbursement',
        'Uber ride reimbursement',
        '$0 Deductible',
      ],
    },
  },
  extendPlans: {
    logoUrl: 'https://d19pbi8vkdue6m.cloudfront.net/icons/extend.svg',
    logoStyles: 'h-[17px]',
    title: 'Wheel Road Hazard Protection',
    includes: {
      title: 'Plan includes:',
      list: [
        'Fast wheel replacement',
        'Road hazards',
        'Easy Online Claims',
        '$0 Deductible',
      ],
    },
  },
};

const $allstateDescription = {
  logo: 'https://d19pbi8vkdue6m.cloudfront.net/powered-by-allstate.svg',
  title: 'Tire Road Hazard Protection',
  features: [
    'Free tire replacement',
    'Mount & Balance Included with Replacement',
    'Roadside assistance',
    'Car rental / Uber reimbursement',
    '$0 Deductible',
  ],
};

const setPlans = createEvent();
const selectYearPlan = createEvent();
const selectProtectionPlan = createEvent();

const submitProtectionPlans = createEvent();
const submitProtectionPlansFx = createEffect().use(api.updateCheckoutState);

const createAllstate = createEvent();
const createAllstateFx = createEffect().use(api.createAllstate);

const createExtend = createEvent();
const addExtendPlansFromFlayout = createEvent();
const closeExtendFlayout = createEvent();
const createExtendFx = createEffect().use(api.createExtend);

const $allstatePlans = createStore([]).on(setPlans, (_, data) =>
  data.allstate_plans.map(item => ({
    ...item,
    plans: item.plans.filter(({ type }) => type === 'premium'),
  })),
);

const $allstateAvailablePlans = orderStores.$order.map(
  ({ allstate_plans }) => allstate_plans,
);

const $extendAvailablePlans = orderStores.$order.map(
  ({ extend_plans }) => extend_plans,
);

const $extendFlyoutToRender = combine(
  $extendAvailablePlans,
  orderStores.$latestWheelWithoutWarranty,
  $plansDesctiption,
  (plans, latestWheelWithoutWarranty, plansDescription) => {
    if (isEmpty(plans) || isEmpty(latestWheelWithoutWarranty)) {
      return [];
    }
    return plans
      .filter(
        ({ line_item_id }) => line_item_id === latestWheelWithoutWarranty.id,
      )
      .map(item => ({
        lineItem: latestWheelWithoutWarranty,
        plans: item.plans,
        description: plansDescription.extendPlans,
      }));
  },
);

const $isEmptyExtendFlyoutToRender = $extendFlyoutToRender.map(extendToRender =>
  isEmpty(extendToRender),
);

const $shouldShowExtendFlyout = combine(
  orderStores.$latestWheelWithoutWarranty,
  $isEmptyExtendFlyoutToRender,
  (latestWheelWithoutWarranty, isEmptyExtendFlyoutToRender) =>
    !isEmpty(latestWheelWithoutWarranty) && !isEmptyExtendFlyoutToRender,
);

sample({
  clock: orderActions.createLineItemFx.done,
  source: $shouldShowExtendFlyout,
  filter: (shouldShowExtendFlyout, { params }) =>
    params.find(({ type }) => ['wheel'].includes(type)) &&
    shouldShowExtendFlyout,
  target: drawerActions.open.prepend(() => ({
    type: 'extend-flyout',
    origin: 'right',
    config: { wrapper: { class: 'hidden' }, outsideClick: () => ({}) },
  })),
});

sample({
  clock: orderActions.createLineItemFx.done,
  source: $shouldShowExtendFlyout,
  filter: (shouldShowExtendFlyout, { params }) =>
    params.find(({ type }) => ['wheel'].includes(type)) &&
    !shouldShowExtendFlyout,
  target: createEffect().use(() => {
    window.location = '/checkout';
  }),
});

const closeAllstateFlayout = createEvent();
const selectAllstatePlan = createEvent();
const addAllstatePlansFromFlayout = createEvent();
const addAllstatePlans = createEvent();
const $selectedAllstatePlans = createStore([]);

const $allstateFlayoutToRender = combine(
  $allstateAvailablePlans,
  orderStores.$tiresWithoutWarranty,
  (plans, tiresWithoutWarranty) => {
    if (isEmpty(plans) || isEmpty(tiresWithoutWarranty)) {
      return [];
    }

    const plansToRender = plans
      .filter(({ line_item_id }) =>
        tiresWithoutWarranty.some(({ id }) => id === line_item_id),
      )
      .map(item => ({
        lineItem: tiresWithoutWarranty.find(
          ({ id }) => id === item.line_item_id,
        ),
        plans: item.plans.filter(({ type }) => type === 'premium'),
      }));

    return orderBy(
      plansToRender,
      [el => new Date(el.lineItem.created_at)],
      ['desc'],
    );
  },
);

sample({
  source: $allstateFlayoutToRender,
  fn: allstateAvailablePlans =>
    allstateAvailablePlans.map(({ lineItem, plans }) => ({
      line_item_id: lineItem.id,
      plan: plans[0],
    })),
  target: $selectedAllstatePlans,
});

sample({
  clock: selectAllstatePlan,
  source: $selectedAllstatePlans,
  fn: (selectedAllstatePlans, selectedData) => {
    const { line_item_id, plan } = selectedData;

    return selectedAllstatePlans.map(item =>
      item.line_item_id === line_item_id ? { ...item, plan } : item,
    );
  },
  target: $selectedAllstatePlans,
});

sample({
  clock: addAllstatePlansFromFlayout,
  target: addAllstatePlans,
});

forward({
  from: addAllstatePlans,
  to: createAllstate.prepend(plans => ({ plans })),
});

const $extendPlans = createStore([]).on(
  setPlans,
  (_, data) => data.extend_plans,
);

const $allstatePlansWithInfo = combine(
  $allstatePlans,
  $lineItems,
  $plansDesctiption,
  (allstatePlans, lineItems, description) => {
    return allstatePlans.map(item => {
      const lineItem = lineItems.find(i => i.id === item.line_item_id);
      const { warranty_info } = lineItem;
      const selectedYearPlan = !isEmpty(warranty_info)
        ? item.plans.find(p => p.year === +warranty_info.year)
        : item.plans[0];
      return {
        ...item,
        line_item_info: lineItem,
        description: description.allstatePlans,
        selectedYearPlan,
        selected: !isEmpty(warranty_info),
      };
    });
  },
);

const $extendPlansWithInfo = combine(
  $extendPlans,
  $lineItems,
  $plansDesctiption,
  (extendPlans, lineItems, description) =>
    extendPlans.map(item => {
      const lineItem = lineItems.find(i => i.id === item.line_item_id);
      const { warranty_info } = lineItem;
      const findSelectedYearPlan = () => {
        const plan = !isEmpty(warranty_info)
          ? item.plans.find(p => p.term_length === +warranty_info.year)
          : item.plans[0];
        return { ...plan, year: plan.term_length };
      };
      return {
        ...item,
        plans: item.plans.map(p => ({ ...p, year: p.term_length })),
        line_item_info: lineItem,
        description: description.extendPlans,
        selectedYearPlan: findSelectedYearPlan(),
        selected: !isEmpty(warranty_info),
      };
    }),
);

const $protectionPlans = combine(
  $allstatePlansWithInfo,
  $extendPlansWithInfo,
  (allstatePlans, extendPlans) => [...allstatePlans, ...extendPlans],
);
const $isPresentProtectionPlans = $protectionPlans.map(
  plans => !isEmpty(plans),
);

const $selectedProtectionPlans = $protectionPlans.map(allPlans =>
  allPlans.filter(p => p.selected),
);

const $isPresentSelectedProtectionPlans = $selectedProtectionPlans.map(
  selectedPlans => !isEmpty(selectedPlans),
);

sample({
  clock: selectYearPlan,
  source: $protectionPlans,
  fn: (protectionPlans, selectData) => {
    const { lineItemId, yearPlan } = selectData;
    return protectionPlans.map(item =>
      item.line_item_id === lineItemId
        ? { ...item, selectedYearPlan: yearPlan }
        : item,
    );
  },
  target: $protectionPlans,
});

sample({
  clock: selectProtectionPlan,
  source: $protectionPlans,
  fn: (protectionPlans, selectData) => {
    const { lineItemId, isSelect } = selectData;
    return protectionPlans.map(item =>
      item.line_item_id === lineItemId ? { ...item, selected: isSelect } : item,
    );
  },
  target: $protectionPlans,
});

sample({
  clock: submitProtectionPlans,
  source: {
    protectionPlans: $protectionPlans,
    allstatePlans: $allstatePlans,
    extendPlans: $extendPlans,
  },
  fn: ({ protectionPlans, allstatePlans, extendPlans }) => {
    const preparedPlans = plans =>
      plans.map(({ selectedYearPlan, selected, line_item_id }) => ({
        line_item_id,
        plan: selected ? selectedYearPlan : {},
      }));
    const allstate = intersectionBy(
      protectionPlans,
      allstatePlans,
      'line_item_id',
    );
    const extend = intersectionBy(protectionPlans, extendPlans, 'line_item_id');

    return {
      order: {
        allstate_plans: preparedPlans(allstate),
        extend_plans: preparedPlans(extend),
      },
      state: 'addons',
    };
  },
  target: submitProtectionPlansFx,
});

const $allstateFlayoutLoader = createAllstateFx.pending;
const $isLoadingExtendFlyout = createExtendFx.pending;

forward({
  from: createAllstate,
  to: createAllstateFx,
});

sample({
  clock: addExtendPlansFromFlayout,
  target: createExtend,
});

sample({
  clock: createExtend,
  target: createExtendFx,
});

forward({
  from: [createAllstateFx.finally, createExtendFx.finally],
  to: [
    drawerActions.close,
    createEffect().use(() => (window.location = '/checkout')),
  ],
});

sample({
  clock: [closeAllstateFlayout, closeExtendFlayout],
  target: drawerActions.close,
});

export const actions = {
  selectProtectionPlan,
  submitProtectionPlans,
  submitProtectionPlansFx,
  setPlans,
  selectYearPlan,
  createAllstate,
  createExtend,
  selectAllstatePlan,
  addAllstatePlans,
  addAllstatePlansFromFlayout,
  addExtendPlansFromFlayout,
  closeAllstateFlayout,
  closeExtendFlayout,
};

export const stores = {
  $allstatePlans,
  $extendPlans,
  $allstatePlansWithInfo,
  $extendPlansWithInfo,
  $plansDesctiption,
  $allstateDescription,
  $allstateFlayoutToRender,
  $allstateFlayoutLoader,
  $protectionPlans,
  $isPresentProtectionPlans,
  $selectedProtectionPlans,
  $isPresentSelectedProtectionPlans,
  $extendFlyoutToRender,
  $isLoadingExtendFlyout,
  $extendAvailablePlans,
  $isEmptyExtendFlyoutToRender,
  $selectedAllstatePlans,
};

export const store = combine(stores);
