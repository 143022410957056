import {
  combine,
  createStore,
  createEvent,
  createEffect,
  sample,
  split,
  forward,
} from 'effector';
import { createForm } from 'effector-forms';
import * as api from 'src/logic/api';
import rules from 'src/effector/forms/rules';
import { getErrors } from 'src/effector/forms/helpers';
import isEmpty from 'lodash/isEmpty';

const reasonOptions = [
  {
    value: 'Wrong Size',
    elements: {
      requestedSize: 'requestedSize',
      partValue: 'partValue',
      itemsCancelled: 'itemsCancelled',
    },
  },
  {
    value: 'Want a different tread design',
    elements: {
      requestedTreadDesign: 'requestedTreadDesign',
      partValue: 'partValue',
      itemsCancelled: 'itemsCancelled',
    },
  },
  {
    value: 'Do not agree to the finance/lease terms',
    elements: {
      partValue: 'partValue',
      itemsCancelled: 'itemsCancelled',
      scheduleLink: 'scheduleLink',
    },
  },
  {
    value: "Can't afford it",
    elements: {
      partValue: 'partValue',
      itemsCancelled: 'itemsCancelled',
      scheduleLink: 'scheduleLink',
    },
  },
  {
    value: 'Other',
    elements: {
      reasonComment: 'reasonComment',
      partValue: 'partValue',
      itemsCancelled: 'itemsCancelled',
    },
  },
];

const form = createForm({
  fields: {
    orderNumber: {
      init: '',
      rules: [rules.required()],
    },
    fullName: {
      init: '',
      rules: [
        rules.required(),
        {
          name: 'firstName',
          validator: value => ({
            isValid: /^[a-zA-Z]+(?:\s[a-zA-Z]+)+$/.test(value),
            errorText: 'Full name must include a first name and a last name.',
          }),
        },
      ],
    },
    email: {
      init: '',
      rules: [rules.required(), rules.email()],
    },
    reason: {
      init: '',
      rules: [rules.required()],
    },
    requestedSize: {
      init: '',
    },
    requestedTreadDesign: {
      init: '',
    },
    reasonComment: {
      init: '',
      rules: [
        {
          name: 'requiredIfOther',
          validator: (reasonComment, f) =>
            f.reason === 'Other' && isEmpty(reasonComment)
              ? { isValid: false, errorText: 'This field is required.' }
              : { isValid: true },
        },
      ],
    },
    partValue: {
      init: '',
      rules: [rules.required()],
    },
    itemsCancelled: {
      init: '',
      rules: [rules.required()],
    },
    disclaimer: {
      init: false,
      rules: [
        {
          name: 'requiredChecked',
          validator: isValid => ({
            isValid,
            errorText: 'This field must be checked',
          }),
        },
      ],
    },
  },
  validateOn: ['submit'],
});

const setCancelled = createEvent();
const setSuccess = createEvent();
const setOrderNumberError = createEvent();
const submit = createEvent();

const $formErrors = getErrors(Object.keys(form.fields), form);
const submitFx = createEffect().use(api.cancelOrder);
const scrollToTop = createEffect().use(() => window.scrollTo(0, 0));

const $formState = createStore('form')
  .on(setSuccess, () => 'success')
  .on(setCancelled, () => 'cancelled');

const $orderNumberError = createStore('')
  .on(setOrderNumberError, () => {
    window.scrollTo(0, 0);
    return 'This order number or email does not exist.';
  })
  .reset([form.fields.orderNumber.$value, form.fields.email.$value]);

const $dataToSubmit = combine(
  {
    values: form.$values,
  },
  ({ values }) => {
    const result = {
      order_number: values.orderNumber,
      full_name: values.fullName,
      email: values.email,
      reason: values.reason,
      items_cancelled: values.itemsCancelled,
      requested_size: values.requestedSize,
      requested_tread_design: values.requestedTreadDesign,
      reason_comment: values.reasonComment,
      part_value: values.partValue,
    };
    return result;
  },
);

sample({
  clock: form.submit,
  source: form.$isValid,
  filter: isValid => isValid,
  target: submit,
});

sample({
  clock: submit,
  source: $dataToSubmit,
  target: submitFx,
});

split({
  source: submitFx.doneData,
  match: ({ data: { status } }) => status,
  cases: {
    success: setSuccess,
    notFound: setOrderNumberError,
    cancelled: setCancelled,
  },
});

forward({
  from: setSuccess,
  to: scrollToTop,
});

forward({
  from: setCancelled,
  to: scrollToTop,
});

// ==========| popup start |==========
const showPopup = createEvent();
const hidePopup = createEvent();
const $isPopupOpen = createStore(false)
  .on(showPopup, () => true)
  .on(hidePopup, () => false);

sample({
  source: form.fields.reason.$value,
  filter: reason =>
    reason === "Can't afford it" ||
    reason === 'Do not agree to the finance/lease terms',
  target: showPopup,
});
// ==========| popup end |==========

// ==========| needToShowElements start |==========
const $elementsToShow = createStore([]);

sample({
  source: form.fields.reason.$value,
  fn: reason => reasonOptions.find(o => o.value === reason).elements,
  target: $elementsToShow,
});
// ==========| needToShowElements end |==========

export const stores = {
  $formValues: form.$values,
  $isValidForm: form.$isValid,
  $isDirty: form.$isDirty,
  $formErrors,
  $orderNumberError,
  $isPopupOpen,
  $elementsToShow,
  reasonOptions,
  $formState,
};

export const actions = {
  formActions: form,
  hidePopup,
};

export const store = combine(stores);
