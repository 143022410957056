import {
  combine,
  createStore,
  createEvent,
  createEffect,
  forward,
  split,
  sample,
} from 'effector';
import { createForm } from 'effector-forms';
import rules from 'src/effector/forms/rules';
import { getErrors } from 'src/effector/forms/helpers';
import * as api from 'src/logic/api';
import { generateData } from 'src/effector/searchWidget/models/utils';
import axios from 'axios';

const vehicleInfoState = {
  makes: {
    actions: ['reset', 'setValue', 'setOptions', 'back'],
    effects: ['loadOptions'],
  },
  years: {
    actions: ['setValue', 'setOptions', 'reset', 'back'],
    effects: ['loadOptions'],
    resetOn: ['makes'],
  },
  models: {
    actions: ['setValue', 'setOptions', 'reset', 'back'],
    effects: ['loadOptions'],
    resetOn: ['years'],
  },
};

const {
  stores: warrantyClaimVehicleInfoStores,
  actions: warrantyClaimVehicleInfoActions,
  effects: warrantyClaimVehicleInfoEffectcs,
  helpers: { allData },
} = generateData(vehicleInfoState);

sample({
  clock: warrantyClaimVehicleInfoActions.makes.setValue,
  source: warrantyClaimVehicleInfoStores.makes,
  fn: ({ current: { id } }) => ({
    url: '/vehicle/tires/years',
    params: { make_id: id },
  }),
  target: warrantyClaimVehicleInfoEffectcs.years.loadOptions,
});

sample({
  clock: warrantyClaimVehicleInfoActions.years.setValue,
  source: warrantyClaimVehicleInfoStores.years,
  fn: ({ current: { id } }) => ({
    url: '/vehicle/tires/models',
    params: { year_id: id },
  }),
  target: warrantyClaimVehicleInfoEffectcs.models.loadOptions,
});

const moveForward = createEvent();
const init = createEvent();
const moveToCompleted = createEvent();

const submit = createEvent();
const submitFx = createEffect().use(api.submitWarrantyClaimZendeskForm);

const firstStepContinue = createEvent();
const secondStepContinue = createEvent();
const thirdStepContinue = createEvent();
const fourthStepContinue = createEvent();

const back = createEvent();

const getOrder = createEvent();
const setOrder = createEvent();
const getOrderFx = createEffect().use(api.fetchWarrantyClaimOrder);
const $order = createStore({ line_items: [], ship_address: {} }).on(
  setOrder,
  (_, o) => o,
);
const $lineItems = $order.map(({ line_items }) => line_items);

const setCurrentStep = createEvent();
const $currentStep = createStore('first')
  .on(setCurrentStep, (_, o) => o)
  .on(moveToCompleted, () => 'completed');

forward({
  from: [setCurrentStep, moveToCompleted],
  to: createEffect().use(() => window.scrollTo(0, 0)),
});

const setFirstStepContinueError = createEvent();
const clearFirstStepContinueError = createEvent();
const $firstStepContinueError = createStore(null)
  .on(setFirstStepContinueError, (_, m) => m)
  .reset(clearFirstStepContinueError);

const firstStepForm = createForm({
  fields: {
    orderNumber: {
      init: '',
      rules: [rules.required()],
    },
    email: {
      init: '',
      rules: [rules.email()],
    },
    allstate: {
      init: '',
      rules: [rules.required()],
    },
  },
});

const secondStepForm = createForm({
  fields: {
    pickedById: {
      init: {},
      rules: [
        {
          name: 'lineItemsToRender',
          validator: value => ({
            isValid: Object.values(value).some(Boolean),
            errorText: 'Please pick at least one line item',
          }),
        },
      ],
    },
    quantityById: {
      init: {},
    },
  },
});

const thirdStepForm = createForm({
  fields: {
    makes: {
      init: '',
      rules: [rules.required()],
    },
    years: {
      init: '',
      rules: [rules.required()],
    },
    models: {
      init: '',
      rules: [rules.required()],
    },
    damagedPositions: {
      init: {},
      rules: [rules.required()],
    },
    details: {
      init: '',
      rules: [rules.required()],
    },
    images: {
      init: {},
      rules: [rules.required()],
    },
  },
});

['makes', 'years', 'models'].forEach(k => {
  forward({
    from: warrantyClaimVehicleInfoActions[k].setValue,
    to: thirdStepForm.fields[k].onChange,
  });
});

const $lineItemsToRender = combine(
  {
    lineItems: $lineItems,
    secondStepForm: secondStepForm.$values,
  },
  ({ lineItems, secondStepForm: { pickedById, quantityById } }) =>
    lineItems.map(item => ({
      ...item,
      isSelected: pickedById[item.id],
      quantityChoosen: quantityById[item.id] || item.quantity,
      onChangQuantity: (id, qty) =>
        secondStepForm.fields.quantityById.onChange({
          ...quantityById,
          [id]: qty,
        }),
      onSelect: id =>
        secondStepForm.fields.pickedById.onChange({
          ...pickedById,
          [id]: !pickedById[item.id],
        }),
    })),
);

sample({
  clock: sample({
    clock: secondStepContinue,
    target: secondStepForm.validate,
  }),
  source: secondStepForm.$isValid,
  filter: isValid => isValid,
  target: setCurrentStep.prepend(() => 'third'),
});

sample({
  clock: sample({
    clock: thirdStepContinue,
    target: thirdStepForm.validate,
  }),
  source: thirdStepForm.$isValid,
  filter: allValid => allValid,
  target: setCurrentStep.prepend(() => 'fourth'),
});

sample({
  clock: sample({
    clock: firstStepContinue,
    target: firstStepForm.validate,
  }),
  source: firstStepForm.$isValid,
  filter: isValid => isValid,
  target: getOrder,
});

forward({
  from: fourthStepContinue,
  to: submit,
});

sample({
  clock: getOrder,
  source: firstStepForm.$values,
  fn: ({ orderNumber, email, allstate }) => ({
    number: orderNumber,
    email,
    allstate,
  }),
  target: getOrderFx,
});

sample({
  clock: getOrderFx.failData,
  fn: e => e.response.data.message,
  target: setFirstStepContinueError,
});

forward({
  from: getOrderFx.doneData,
  to: [
    clearFirstStepContinueError,
    setOrder.prepend(({ order }) => order),
    setCurrentStep.prepend(() => 'second'),
  ],
});

split({
  source: back,
  match: $currentStep,
  cases: {
    second: setCurrentStep.prepend(() => 'first'),
    third: setCurrentStep.prepend(() => 'second'),
    fourth: setCurrentStep.prepend(() => 'third'),
  },
});

split({
  clock: moveForward,
  source: $currentStep,
  match: $currentStep,
  cases: {
    first: firstStepContinue,
    second: secondStepContinue,
    third: thirdStepContinue,
    fourth: fourthStepContinue,
  },
});

export const preloadFx = createEffect().use(() =>
  axios.get('/data', {
    baseURL: `${window.location.origin}/api/v1/search`,
  }),
);

forward({
  from: init,
  to: preloadFx.prepend(() => ({ url: '/data' })),
});

forward({
  from: preloadFx.doneData,
  to: warrantyClaimVehicleInfoActions.makes.setOptions.prepend(
    ({ data: { makes } }) => makes,
  ),
});

const $firstStepFormErrors = getErrors(
  Object.keys(firstStepForm.fields),
  firstStepForm,
);

const $thirdStepFormErrors = getErrors(
  Object.keys(thirdStepForm.fields),
  thirdStepForm,
);

const addressInfoForm = createForm({
  fields: {
    company: {
      init: '',
    },
    address1: {
      init: '',
      rules: [rules.required()],
    },
    city: {
      init: '',
      rules: [rules.required()],
    },
    zipcode: {
      init: '',
      rules: [rules.required(), rules.minLength(5)],
    },
    state: {
      init: '',
      rules: [rules.required()],
    },
  },
});

forward({
  from: $order,
  to: addressInfoForm.setForm.prepend(({ ship_address }) => ({
    company: ship_address.company,
    address1: ship_address.address1,
    city: ship_address.city,
    zipcode: ship_address.zipcode,
    state: ship_address.state_fullname,
  })),
});

const setStates = createEvent();
const $states = createStore([]).on(setStates, (_, data) => data);

const $vehicleInfoToSubmit = combine(
  { ...warrantyClaimVehicleInfoStores },
  ({ makes, models, years }) =>
    [makes.current.value, models.current.value, years.current.value].join(', '),
);

const $dataToSubmit = combine(
  {
    order: $order,
    lineItemsToRender: $lineItemsToRender,
    firstStepFormValues: firstStepForm.$values,
    thirdStepFormValues: thirdStepForm.$values,
    addressInfo: addressInfoForm.$values,
    vehicleInfoToSubmit: $vehicleInfoToSubmit,
  },
  ({
    order,
    lineItemsToRender,
    firstStepFormValues,
    thirdStepFormValues,
    addressInfo,
    vehicleInfoToSubmit,
  }) => {
    const result = {
      address: {
        address_info: [
          addressInfo.city,
          addressInfo.state,
          addressInfo.address1,
          addressInfo.zipcode,
        ].join(', '),
        company: addressInfo.company,
        phone: order.ship_address?.phone,
      },
      line_items: lineItemsToRender
        .filter(({ isSelected }) => isSelected)
        .map(i => ({
          brand: i.brand,
          model: i.model,
          size: i.size,
          quantity: i.quantityChoosen,
        })),
      order: {
        all_state: firstStepFormValues.allstate,
        created_at: order.created_date,
        email: order.email,
        number: order.number,
      },
      vehicle: {
        damage_positions: Object.entries(thirdStepFormValues.damagedPositions)
          .filter(([, v]) => v)
          .map(([v]) => v.split('_').join(' '))
          .join(','),
        info: vehicleInfoToSubmit,
        images: Object.values(thirdStepFormValues.images),
        details: thirdStepFormValues.details,
      },
    };
    return result;
  },
);

const $addressInfoFormErrors = getErrors(
  Object.keys(addressInfoForm.fields),
  addressInfoForm,
);

const showAddressInfoModal = createEvent();
const closeAddressInfoModal = createEvent();
const $isShownAddressInfoModal = createStore(false)
  .on(showAddressInfoModal, () => true)
  .on(closeAddressInfoModal, () => false);

sample({
  clock: submit,
  source: $dataToSubmit,
  target: [submitFx, moveToCompleted],
});

export const stores = {
  $firstStepFormValues: firstStepForm.$values,
  $thirdStepFormValues: thirdStepForm.$values,
  $isValidFirstStepForm: firstStepForm.$isValid,
  $isValidThirdStepForm: thirdStepForm.$isValid,
  $firstStepFormErrors,
  $firstStepContinueError,
  $thirdStepFormErrors,
  $vehicleInfoStores: allData,
  $currentStep,
  $lineItemsToRender,
  $dataToSubmit,
  $order,
  $addressInfoFormValues: addressInfoForm.$values,
  $addressInfoFormErrors,
  $isShownAddressInfoModal,
  $states,
};

export const actions = {
  firstStepFormActions: firstStepForm,
  thirdStepFormActions: thirdStepForm,
  vehicleInfoActions: warrantyClaimVehicleInfoActions,
  addressInfoFormActions: addressInfoForm,
  showAddressInfoModal,
  closeAddressInfoModal,
  back,
  moveForward,
  init,
  setStates,
  submit,
};

export const store = combine(stores);
