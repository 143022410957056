import {
  combine,
  createStore,
  createEvent,
  createEffect,
  forward,
} from 'effector';
import { persist } from 'effector-storage/local';

export const formulaByPeriod = {
  weekly: (price, quantity) => (price * quantity * 2) / 13 / 4.33,
  'every 2 weeks': (price, quantity) => (price * quantity * 2) / 13 / 2.16,
  monthly: (price, quantity) => (price * quantity * 2) / 13,
  'cash price': price => price,
};

export const periods = [
  {
    key: 'weekly',
    title: 'Weekly',
  },
  {
    key: 'every 2 weeks',
    title: 'Every 2 Weeks',
  },
  {
    key: 'monthly',
    title: 'Monthly',
  },
  {
    key: 'cash price',
    title: 'Cash Price',
  },
];

const setPaymentPeriod = createEvent();
const setPaymentPeriodFx = createEffect();

forward({
  from: setPaymentPeriod,
  to: setPaymentPeriodFx,
});

const $selectedPaymentPeriod = createStore(periods[0]).on(
  setPaymentPeriodFx,
  (_, paymentPeriod) => paymentPeriod,
);

const $periodsToRender = createStore(periods);

export const stores = {
  $periodsToRender,
  $selectedPaymentPeriod,
};

export const actions = {
  setPaymentPeriod,
};

export const store = combine(stores);

persist({ store: $selectedPaymentPeriod, key: 'selected-payment-period' });
