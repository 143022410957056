import { createDomain, forward, combine } from 'effector';

import http from 'src/logic/http';
import {
  actions as tpmsActions,
  stores as tpmsStores,
} from 'src/effector/addons/tpms';

import { stores as orderStores } from 'src/effector/order';

import isEmpty from 'lodash/isEmpty';

const addonsDomain = createDomain('addons');

const getAddons = addonsDomain.createEvent();
const setState = addonsDomain.createEvent();
const getAddonsFx = addonsDomain.createEffect().use(async () => {
  const { data } = await http.get(
    `${window.location.origin}/api/v1/orders/addons`,
  );
  return data;
});

const $isLoadingAddons = getAddonsFx.pending;

const $addons = addonsDomain
  .createStore([])
  .on(getAddonsFx.doneData, (_, addons) => addons)
  .on(setState, (_, addons) => addons);

forward({
  from: getAddons,
  to: getAddonsFx,
});

const $inflaterLineItem = orderStores.$lineItems.map(lineItems =>
  lineItems.filter(item => item.product_type === 'inflater'),
);
const $isPresentInflaterLineItem = $inflaterLineItem.map(
  items => !isEmpty(items),
);

const $tireBagsLineItem = orderStores.$lineItems.map(lineItems =>
  lineItems.filter(item => item.product_type === 'tire_bags'),
);
const $isPresentTireBagsLineItem = $tireBagsLineItem.map(
  items => !isEmpty(items),
);

const $isPresentSelectedAccessories = combine(
  [tpmsStores.$isPresentTpmsLineItems, $isPresentInflaterLineItem],
  isPresents => isPresents.some(Boolean),
);

export const stores = {
  ...tpmsStores,
  $addons,
  $isLoadingAddons,
  $inflaterLineItem,
  $isPresentInflaterLineItem,
  $isPresentSelectedAccessories,
  $tireBagsLineItem,
  $isPresentTireBagsLineItem,
};

export const store = combine(stores);

export const actions = {
  ...tpmsActions,
  getAddons,
  setState,
};
