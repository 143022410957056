import Fuse from 'fuse.js';
import { createStore, createEvent } from 'effector';

const handleChange = createEvent();
const resetValue = createEvent();

const $fieldValue = createStore('')
  .on(handleChange, (s, e) => e.target.value)
  .reset(resetValue);

export const handleSearch = ({ list, keys, searchValue }) => {
  const searchOptions = {
    shouldSort: true,
    threshold: 0.2,
    location: 0,
    distance: 10,
    keys,
  };
  const fuse = new Fuse(list, searchOptions);
  const result = fuse.search(searchValue);
  return result.map(el => el.item);
};

export const stores = { $fieldValue };

export const actions = { handleChange, resetValue, handleSearch };
